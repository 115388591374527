import { useEffect, useState } from "react";
import LoaderComponent from "../../../loader/loader.component";
import { PosClientSettingsType, PosClientType } from "../../types";
import './pos.categories.styles.scss'
export default function PosCategoriesComponent(props: {

}) {

    const [search, setSearch] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [options, setOptions] = useState<PosClientType[]>([]);
    const [lastOptions, setLastOptions] = useState<PosClientType[]>([]);
    const randomColors = ['#FF0000', '#FF7F00', '#FFFF00', '#00FF00', '#0000FF', '#4B0082', '#8B00FF']
    const categories = [
        {
            title: 'Shirts',
            description: 'Shirts description',
            products: 2
        },
        {
            title: 'Pants',
            description: 'Pants description',
            products: 11
        },
        {
            title: 'Shoes',
            description: 'Shoes description',
            products: 5
        }, {
            title: 'Shirts',
            description: 'Shirts description',
            products: 2
        },
        {
            title: 'Pants',
            description: 'Pants description',
            products: 11
        },
        {
            title: 'Shoes',
            description: 'Shoes description',
            products: 5
        },
        {
            title: 'Pants',
            description: 'Pants description',
            products: 11
        },
        {
            title: 'Shoes',
            description: 'Shoes description',
            products: 5
        }
    ]

    return <div className="PosCategoriesComponent">
        {
            categories.map((category, index) => {

                return <div className="category" key={index}>
                    <div className="title">
                        {category.title}
                    </div>
                    <div className="count">
                        {category.products} products
                    </div>
                    <div className="background" style={{ backgroundColor: randomColors[index] }}></div>
                </div>

            })
        }
    </div>

}