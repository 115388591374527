// ignore all eslint
// 
import { SelfieSegmentation } from "@mediapipe/selfie_segmentation";

export class DishBackgroundVideo {
    stream: MediaStream;
    background: HTMLImageElement;
    canvas: HTMLCanvasElement;
    context: CanvasRenderingContext2D;
    video: HTMLVideoElement;
    selfieSegmentation: SelfieSegmentation;
    constructor({ stream, background }: { stream: MediaStream, background: HTMLImageElement }) {

        console.log('Intelitent Background')
        this.stream = stream;
        this.background = background;
        this.canvas = document.createElement('canvas');
        // width:
        // get width of stream
        this.canvas.width = this.stream.getVideoTracks()[0].getSettings().width || 600;
        // height:
        // get height of stream
        this.canvas.height = this.stream.getVideoTracks()[0].getSettings().height || 600;

        const context = this.canvas.getContext('2d');
        if (!context) {

            throw new Error('Canvas context not found');

        }
        this.context = context;
        this.video = document.createElement('video');
        this.video.width = 1024;
        // height '4:3'
        this.video.height = 768;
        this.video.srcObject = stream;
        this.video.autoplay = true;

        this.selfieSegmentation = new SelfieSegmentation({
            locateFile: (file) =>
                `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`
        });

        this.selfieSegmentation.setOptions({
            modelSelection: 1,

        });

        this.selfieSegmentation.onResults((results) => {

            this.onResults(results);

        });

    }

    onResults(results: any) {

        this.context.drawImage(
            results.segmentationMask,
            0,
            0,
            this.canvas.width,
            this.canvas.height
        );
        // set background

        // Only overwrite existing pixels.
        this.context.globalCompositeOperation = "source-out";
        if (this.background.width) {

            // calculate scale
            const scaleWidth = this.canvas.width / this.background.width;
            const scaleHeight = this.canvas.height / this.background.height;
            const scale = scaleWidth > scaleHeight ? scaleWidth : scaleHeight;
            // draw background

            this.context.drawImage(
                this.background,
                0,
                0,
                this.background.width * scale,
                this.background.height * scale
            );

        }
        // Only overwrite missing pixels.
        this.context.globalCompositeOperation = "destination-atop";

        this.context.drawImage(
            results.image,
            0,
            0,
            this.canvas.width,
            this.canvas.height
        );

        this.context.restore();

    }

    start() {

        setTimeout(async () => {

            if (this.video.width) {

                const rr = await this.selfieSegmentation.send({ image: this.video });

            }
            this.start()

        }, 100);

    }

    async getStream() {

        const streamResult = this.canvas.captureStream(60);
        return streamResult;

    }

}

