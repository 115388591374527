// General
import Help from './brickter/help.json';
import Menu from './brickter/menu.json';

// Dish System
import Patients from './brickter/dish/patients.json';
import Meets from './brickter/dish/meets.json';
import Dish from './brickter/dish/dish.json';
import Calendar from './brickter/dish/calendar.json';
import Invitations from './brickter/dish/invitations.json';

// Admin
import Groups from './brickter/admin/groups.json';
import Relations from './brickter/admin/relations.json';
import Users from './brickter/admin/users.json';
import Anlytics from './brickter/admin/analytics.json';

// Account
import Account from './brickter/account/account.json';

// Access
import Login from './brickter/access/login.json';
import Register from './brickter/access/register.json';
import RegisterSuccess from './brickter/access/registerSuccess.json';
import BrickterCompilations from './brickter/constructor/compilation';
import BrickterApplication from './brickter/constructor/application';

export default function BrickterConstructor() {

    return {
        "type": "frontend",
        "name": "professional",
        "styles": { "flex": 1 },
        "screens": [

            {
                "type": "screen",
                title: "Brickter Manager",
                favicon: "/assets/brickter/images/logotypes/favicon.png",
                "components": [

                    {

                        type: 'screen',
                        components: [

                            Dish,
                            {
                                "type": "screen",
                                "name": "calendar",
                                styles: {
                                    zIndex: 10,
                                    display: 'flex',
                                    flex: 1,
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0, backgroundColor: '#eee',
                                    flexDirection: 'row',
                                },
                                "components": [

                                    Menu,

                                    {
                                        "type": "screen",
                                        styles: {
                                            display: 'flex',
                                            flex: 1,
                                            overflowY: 'scroll',
                                            padding: 20
                                        },
                                        "components": [

                                            {
                                                type: 'screen',
                                                styles: {
                                                    display: 'flex',
                                                    flex: 1,
                                                    flexDirection: 'column',

                                                },
                                                components: [
                                                    BrickterCompilations(),
                                                    BrickterApplication(),
                                                    Calendar,
                                                    Meets,
                                                    Patients,
                                                    Anlytics,
                                                    Help,
                                                    Groups,
                                                    Account,
                                                    Users,
                                                    Invitations,
                                                    Relations,
                                                ]
                                            }]
                                    }
                                ]
                            }],
                        false: [
                            Login,
                            Register,
                            RegisterSuccess,
                            {
                                "path": [
                                    "/manager/*",
                                    "/admin/*"
                                ],
                                type: "screen",
                                components: [
                                    {

                                        "type": "redirect",
                                        "path": "/login",
                                    },
                                ]
                            },

                        ]
                    }
                ]

            }
        ]
    }

}