import { useEffect, useState } from "react";
import LoaderComponent from "../../../loader/loader.component";
import { PosClientSettingsType, PosClientType } from "../../types";
import './pos.status.styles.scss'
export default function PosStatusComponent(props: {

}) {

    return <div className="PosStatusComponent">

    </div>

}