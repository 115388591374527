
import './constructor.styles.scss'
import { ConstructorApplication } from './constructor.types'
import InterfaceConstructor from './interface/interface.constructor'
import ManagerConstructor from './manager/manager.constructor'
import ModelConstructor from './model/model.constructor'
export default function ConstructorVersionOne() {

    const applications: ConstructorApplication[] = [{
        code: 'ShopModel',
        type: 'model',
        scheme: {

            tables: {
                user: {
                    columns: {
                        id: {
                            type: 'uuid'
                        },
                        name: {
                            type: 'text'
                        },

                        email: {
                            type: 'text'
                        },
                        password: {
                            type: 'text'
                        },

                    }
                },
                role: {

                    columns: {
                        id: {
                            type: 'uuid'
                        },
                        name: {
                            type: 'text'
                        }
                    }
                },
                shop: {
                    columns: {
                        id: {
                            type: 'uuid'
                        },
                        name: {
                            type: 'text'
                        },
                        ownerId: {
                            type: 'relation',
                            relation: {
                                table: 'user',
                                column: 'id'
                            }
                        },
                        address: {
                            type: 'text'
                        },
                        phone: {
                            type: 'text'
                        }
                    }
                },
                permission: {
                    columns: {
                        id: {
                            type: 'uuid'
                        },
                        name: {
                            type: 'text'
                        },
                        shopId: {
                            type: 'relation',
                            relation: {
                                table: 'user',
                                column: 'id'
                            }
                        },
                        roleId: {
                            type: 'relation',
                            relation: {
                                table: 'role',
                                column: 'id'
                            }
                        },
                        userId: {
                            type: 'relation',
                            relation: {
                                table: 'user',
                                column: 'id'
                            }
                        }
                    }
                },
                product: {

                    columns: {
                        id: {
                            type: 'uuid'
                        },
                        name: {
                            type: 'text'
                        },
                        price: {
                            type: 'number'
                        },
                        description: {
                            type: 'text'
                        },
                        categoryId: {
                            type: 'relation',
                            relation: {
                                table: 'category',
                                column: 'id'
                            }
                        },
                        shopId: {
                            type: 'relation',
                            relation: {
                                table: 'shop',
                                column: 'id'
                            }
                        }
                    }
                },
                category: {

                    columns: {
                        id: {
                            type: 'uuid'
                        },
                        name: {
                            type: 'text'
                        },
                        shopId: {
                            type: 'relation',
                            relation: {
                                table: 'shop',
                                column: 'id'
                            }
                        }
                    }
                }
            }

        }
    }, {
        code: 'ShopManager',
        type: 'manager',
        scheme: {
            components: [
                {
                    documentation: {
                        title: 'Login'
                    },
                    type: 'screen',
                    components: [
                        {
                            type: 'splash'
                        },
                        {
                            type: 'form'
                        },
                    ]
                },
                {
                    documentation: {
                        title: 'Register'
                    },
                    type: 'screen',
                    components: [
                        {
                            type: 'splash'
                        },
                        {
                            type: 'form'
                        },
                    ]
                },
                {
                    documentation: {
                        title: 'Recovery'
                    },
                    type: 'screen',
                    components: [
                        {
                            type: 'splash'
                        },
                        {
                            type: 'form'
                        },
                    ]
                },
                {
                    documentation: {
                        title: 'Manager'
                    },
                    type: 'screen',
                    components: [

                        {
                            type: 'widget',
                            components: [
                                {
                                    type: 'button',
                                    text: 'Pacientes'
                                },
                                {
                                    type: 'button',
                                    text: 'Citas'
                                },
                                {
                                    type: 'button',
                                    text: 'Centros'
                                }
                            ]
                        },
                        {
                            type: 'screen',
                            direction: 'column',
                            components: [
                                {
                                    documentation: {
                                        title: 'Pacientes'
                                    },
                                    type: 'screen',
                                    direction: 'column',
                                    components: [
                                        {
                                            type: 'header'
                                        },
                                        {
                                            type: 'table'
                                        }
                                    ]
                                },
                                {
                                    documentation: {
                                        title: 'Citas'
                                    },
                                    type: 'screen',
                                    direction: 'column',
                                    components: [
                                        {
                                            type: 'header'
                                        },
                                        {
                                            type: 'table'
                                        }
                                    ]
                                },
                                {
                                    documentation: {
                                        title: 'Centros'
                                    },
                                    type: 'screen',
                                    direction: 'column',
                                    components: [
                                        {
                                            type: 'header'
                                        },
                                        {
                                            type: 'table'
                                        }
                                    ]
                                },
                                {
                                    documentation: {
                                        title: 'Usuarios'
                                    },
                                    type: 'screen',
                                    direction: 'column',
                                    components: [
                                        {
                                            type: 'header'
                                        },
                                        {
                                            type: 'table'
                                        }
                                    ]
                                },
                                {
                                    documentation: {
                                        title: 'Patologías'
                                    },
                                    type: 'screen',
                                    direction: 'column',
                                    components: [
                                        {
                                            type: 'header'
                                        },
                                        {
                                            type: 'table'
                                        }
                                    ]
                                },
                                {
                                    documentation: {
                                        title: 'Zonas Anatómicas'
                                    },
                                    type: 'screen',
                                    direction: 'column',
                                    components: [
                                        {
                                            type: 'header'
                                        },
                                        {
                                            type: 'table'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]

        }
    }, {
        code: 'ShopInterface',
        type: 'interface',
        scheme: {
            middleares: {
                user: {
                    validations: {
                        header: {
                            authorization: [
                                {
                                    type: 'required',
                                }
                            ]
                        }

                    },
                    action: {
                        type: 'jwt.decode',
                        action: {
                            type: 'select',
                            table: 'user',
                            where: {
                                id: {
                                    type: 'bearer',
                                    value: 'id'
                                }
                            },
                            columns: ['id'],
                        }
                    }
                }
            },
            methods: {
                register: {
                    documentation: {
                        title: 'Register',
                    },
                    validations: {
                        body: {
                            name: [
                                {
                                    type: 'required',
                                }
                            ],
                            email: [
                                {
                                    type: 'required',
                                }
                            ],
                            password: [
                                {
                                    type: 'required',
                                }
                            ],
                        }
                    },
                    action: {
                        type: 'encrypt',
                        value: {
                            type: 'body',
                            value: 'password'
                        },
                        algorithm: 'sha256',

                        action: {
                            type: 'insert',
                            table: 'user',
                            columns: ['name', 'email', 'password']

                        }
                    }
                },
                login: {
                    documentation: {
                        title: 'Register',
                    },
                    validations: {
                        query: {
                            email: [
                                {
                                    type: 'required',
                                }
                            ],
                        },
                        body: {
                            email: [
                                {
                                    type: 'required',
                                }
                            ],
                            password: [
                                {
                                    type: 'required',
                                }
                            ],
                        }
                    },
                    action: {
                        type: 'encrypt',
                        value: {
                            type: 'body',
                            value: 'password'
                        },
                        algorithm: 'sha256',
                        action: {
                            type: 'select',
                            table: 'user',
                            where: {
                                email: {
                                    type: 'query',
                                    value: 'email'
                                },
                                password: {
                                    type: 'body',
                                    value: 'password'
                                }
                            },
                            columns: ['id', 'name', 'email'],
                            action: {
                                type: 'jwt.encode'
                            }
                        }
                    }
                },
                createProduct: {
                    documentation: {
                        title: 'Crear Producto',
                    },
                    validations: {
                        body: {
                            name: [
                                {
                                    type: 'required',
                                }
                            ],
                            shopId: [
                                {
                                    type: 'required',
                                }
                            ],
                            price: [
                                {
                                    type: 'required',
                                }
                            ],
                        }
                    }
                }
            },

        }
    }]

    return <div className='ConstructorOne'>
        {
            applications.map((application: ConstructorApplication, applicationIndex: number) => {

                // type model or type manager
                if (application.type === 'model') {

                    return <ModelConstructor application={application} key={applicationIndex} />

                }
                else if (application.type === 'manager') {

                    return <ManagerConstructor application={application} key={applicationIndex} />

                }
                else if (application.type === 'interface') {

                    return <InterfaceConstructor application={application} key={applicationIndex} />

                }

            })
        }
    </div >

}