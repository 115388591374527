import { getProperty } from 'dot-prop'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { CalendarSettings } from '../../..'
import RequestAction from '../../../../../../actions/request/request.action'
import { Replace } from '../../../../../../utilities/replace/replace.utility'
import { GetCollisionEvents } from '../../../utilities/collision.utility'
import CalendarEventCube from '../../event/event.component'
import LayoutCalendarTimelineView from '../../timeline/timelineView'
import CalendarWeekTimeline from '../../week/timeline/timeline.week'
function CalendarWeekBody(props: {

    onEdit: (item: any) => void,
    onDelete: (item: any) => void,
    onNew: (start: Date, end: Date, column: any) => void,
    store: any, daySelected: Date, events: any[], settings: CalendarSettings
}) {

    const layoutRef = useRef<HTMLDivElement>(null)
    const [widthCalendarDay, setWidthCalendarDay] = useState<number>()
    const [loading, setLoading] = useState<boolean>(true)
    const [columns, setColumns] = useState<any>([])
    const [columnsAndData, setColumnsAndData] = useState<any>([])
    useEffect(() => {

        if (layoutRef.current && layoutRef.current.offsetWidth) {

            setWidthCalendarDay(layoutRef.current.offsetWidth)

        }
        // resize window
        window.addEventListener('resize', () => {

            if (layoutRef.current && layoutRef.current.offsetWidth) {

                setWidthCalendarDay(layoutRef.current.offsetWidth)

            }

        }
        )
        return () => {

            window.removeEventListener('resize', () => {

                if (layoutRef.current && layoutRef.current.offsetWidth) {

                    setWidthCalendarDay(layoutRef.current.offsetWidth)

                }

            })

        }

    }, [layoutRef.current])
    const hourHeight = 100;

    useEffect(() => {

        // if have columns endpoint

        getColumns()

    }, [props.daySelected])
    useEffect(() => {

        const columnsAndEventsTemp: any = []
        columns.forEach((column: any) => {

            const columnToSave = { ...column }
            columnToSave.events = []
            const filterEvents: any = []
            if (props.settings?.day?.columns) {

                console.log('miramos si tiene eventps?x')

                const columnIdAuthor = Replace({ item: column }, props.settings?.day?.columns?.idSelector)
                console.log(props.events.length)
                props.events.forEach((event: any) => {

                    console.log('miramos si rrr eventps?x')

                    const eventIdAuthor = Replace({ item: event }, props.settings?.event?.idSelector)
                    if (columnIdAuthor === eventIdAuthor) {

                        console.log('======= tiene!')
                        filterEvents.push(event)

                    }

                })

            }

            const eventsByDay = GetCollisionEvents(filterEvents, props.settings)
            columnToSave.events = eventsByDay;
            columnsAndEventsTemp.push(columnToSave)

        })
        setColumnsAndData(columnsAndEventsTemp)

    }
        , [props.events, columns])
    const getColumns = () => {

        console.log('obtenemos las columasn con:', props.events.length)
        if (props.settings?.day?.columns?.path) {

            setLoading(true)

            const currentUserToken = getProperty(props.store, 'user.access_token');
            const path = Replace({ store: props.store }, props.settings?.day?.columns?.path)
            console.log('obtebnemos user:', path)
            setColumns([])
            RequestAction({
                endpoint: path,
                method: 'get',
                headers: {
                    'authorization': 'Bearer ' + currentUserToken
                }
            }).then((response) => {

                setLoading(false)
                const columns = (response.data.items)

                setColumns(columns)

            }).catch(() => {

                setLoading(false)

            })

        }

    }
    console.log('columns:', columnsAndData)
    const dayMonth = moment(props.daySelected).date()

    return <div className="CalendarWeekBody" ref={layoutRef}>

        <div className='scroll' style={{ maxWidth: (250 * 7) + 60 + 60 }}>

            <div className="CalendarWeekHeader" style={{ minWidth: 250 * columnsAndData.length + 60 + 60 }}>
                {
                    columnsAndData && columnsAndData.length > 0 && columnsAndData?.map((column: any, index: number) => {

                        if (props.settings?.day?.columns?.titleSelector) {

                            const name = Replace({ item: column }, props.settings?.day?.columns?.titleSelector)
                            const color = Replace({ item: column }, props.settings?.day?.columns?.colorSelector)
                            return <div className="column" key={index} style={{ width: `250px` }}>

                                <div className="head">
                                    <div className='color' style={{ backgroundColor: color }}></div>
                                    <b style={{ fontWeight: 'bold', color: '#fff' }}>{name}</b><span><em>{column?.events?.[dayMonth]?.length || 0}</em> EVENTOS</span>
                                </div>

                            </div>

                        }
                        return null;

                    })
                }
            </div >
            <CalendarWeekTimeline />
            {<LayoutCalendarTimelineView />}

            {
                columnsAndData.length > 0 && columnsAndData?.map((column: any, index: number) => {

                    // get day of month:
                    return <div className={`day day-${index}`} key={index} style={{ width: `250px`, height: 24 * 100 }}>

                        {
                            [...Array(24)].map((_, indexHour) => (
                                <div className="hour" key={indexHour} style={{ top: 100 * indexHour, height: 100 }} onClick={
                                    () => {

                                        const start = moment(props.daySelected).set({ hour: indexHour, minute: 0, second: 0, millisecond: 0 }).toDate()
                                        const end = moment(props.daySelected).set({ hour: indexHour + 1, minute: 0, second: 0, millisecond: 0 }).toDate()
                                        props.onNew(
                                            start, end, column
                                        )

                                    }
                                } >
                                    <i className='las la-plus'></i>
                                    <b>{indexHour + ':00'
                                    }
                                        <span>{
                                            props.daySelected.getFullYear() + '-' + (props.daySelected.getMonth() + 1) + '-' + props.daySelected.getDay()}</span></b>
                                </div>
                            ))
                        }
                        {
                            column?.events?.[dayMonth]?.map((event: any, index: number) => {

                                console.log('----render event:', event)
                                return <CalendarEventCube
                                    onEdit={props.onEdit}
                                    onDelete={props.onDelete}
                                    settings={props.settings} event={event} hourHeight={hourHeight} key={index} layoutWidth={widthCalendarDay} />

                            })
                        }

                    </div>

                })
            }
            <div className='day' style={{ position: 'absolute', zIndex: -1, left: 0, right: 0, bottom: 0, top: 50, width: `100%` }}>
                {
                    [...Array(24)].map((_, indexHour) => (
                        <div className="hour" key={indexHour} style={{ top: 100 * indexHour, height: 100 }}>

                        </div>
                    ))
                }
            </div>
            <CalendarWeekTimeline />

        </div>
    </div >

}

const mapStateToProps = (state: any, ownProps: any) => (state)

export default connect(mapStateToProps,)(CalendarWeekBody)
