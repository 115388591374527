
import { useLocation, useParams } from 'react-router-dom';
import { Replace } from '../../../../../utilities/replace/replace.utility';
import './stripe.styles.scss'
interface StripeProperties {
    client?: string,
    name: string,
    setData: (s: any) => void

}
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export default function StripeComponent(properties: StripeProperties) {

    return <a target={'_blank'} href='https://buy.stripe.com/test_4gw5ms6Hn4MkbOocMM?client_reference_id=alicunde&prefilled_email=s.munuera@alicunde.com'>
        stripe open
    </a>

};
