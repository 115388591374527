import './connected.styles.scss'
interface ConnectedProperties {
    label: string,
    usersCount: number
    status: boolean
}

export default function ConnectedComponent(properties: ConnectedProperties) {

    return <div className="ConnectedComponent">
        <div className='title'>

            <div className={`circle ${properties.status ? 'true' : 'false'}`}></div>  {
                properties.status ? 'Conectado' : 'Desconectado'
            } <span>{properties.usersCount ? properties.usersCount - 1 : 0} contactos</span>
        </div>
        <div className='user'>
            <i className="las la-user"></i>  {properties.label}
        </div>

    </div>

}