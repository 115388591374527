
import './index.scss';
import { useEffect, useState } from 'react';

import { setStore } from '../../../store/actions'
import { connect } from 'react-redux';
import { gapi } from 'gapi-script';
import GoogleLogin from '@leecheuk/react-google-login';
import { getProperty } from 'dot-prop';
import { Replace } from '../../../utilities/replace/replace.utility';
import RequestAction from '../../../actions/request/request.action';
import { useParams } from 'react-router-dom';
import LoaderComponent from '../loader/loader.component';

const mapDispatchToProps = {

    setStore
}
const mapStateToProps = (state: any, ownProps: any) => (state)

interface DispatchProps {
    setStore: (s: any) => void
    store: any,
    endpoint: string,
    inputs: {
        value: string,
        name: string
    }[]
    item?: any,
    styles?: any,
}
type Props = DispatchProps

// eslint-disable-next-line max-lines-per-function
function CalenportComponent(props: Props) {

    const [eventsToSave, setEventsToSave] = useState<any[]>([])
    const [login, setLogin] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [eventSendIndex, setEventSendIndex] = useState<number>(0);
    const [googleAccount, setGoogleAccount] = useState<any>();
    const CLIENT_ID = '395637770922-ae9msa7ded7psbjfa89cq7q6nosqhgll.apps.googleusercontent.com';
    // Secret: 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
    const API_KEY = 'AIzaSyBqAh8URAKmu6kaTaTDMCM-Om3Y9fPRcbI';
    const params = useParams();

    // Discovery doc URL for APIs used by the quickstart
    const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest';

    // Authorization scopes required by the API; multiple scopes can be
    // Included, separated by spaces.
    const SCOPES = 'https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.readonly';
    const loadGoogleAPI = () => {

        const script = document.createElement("script");
        script.src = "https://apis.google.com/js/client.js";

        script.onload = () => {

            function start() {

                gapi.load('client', () => {

                    gapi.client.load('calendar', 'v3', () => {

                        gapi.client.init({
                            clientId: CLIENT_ID,
                            scope: SCOPES,

                        });

                    });

                });
                gapi.cli

            }

            gapi.load('client:auth2', start);

        };

        document.body.appendChild(script);

    }

    useEffect(() => {

        loadGoogleAPI()

    }, []);

    const responseGoogle = (response: any) => {

        setLoading(true)
        setLogin(true)

        let request = gapi.client.calendar.events.list({
            'calendarId': 'primary', /* Can be 'primary' or a given calendarid */
            'timeMin': (new Date()).toISOString(),
            'showDeleted': false,
            'singleEvents': true,
            'maxResults': 500,
            'orderBy': 'startTime'
        });

        request.execute(function (resp: any) {

            let events = resp.items;

            if (events.length > 0) {

                const eventsList = []
                for (let i = 0; i < events.length; i++) {

                    let event = events[i];
                    let eventing: any = {};
                    eventing.id = event.id;
                    eventing.start = event?.start?.dateTime;
                    eventing.finish = event?.end?.dateTime;
                    eventing.name = event?.summary;
                    eventing.description = event?.description;
                    eventsList.push(eventing)

                }
                setEventsToSave(eventsList)
                setLoading(false)

            }

        });

    }
    const sendEvents = (eventIndex: number) => {

        if (eventIndex >= eventsToSave.length) return false;
        // Post data:
        const event = eventsToSave[eventIndex];

        const currentUserToken = getProperty(props.store, 'user.access_token');
        const endpoint = Replace({ params, item: event }, props.endpoint)

        let data: any = {}
        props.inputs.forEach((input: any) => {

            let content = Replace({ store: props.store, params, item: event }, input.value);
            data[input.name] = content

        })
        RequestAction({
            endpoint: endpoint,
            method: 'POST',
            data: data,
            headers: {
                'authorization': 'Bearer ' + currentUserToken
            }
        }).then((res: any) => {

            setEventSendIndex(eventIndex + 1)
            setTimeout(() => {

                sendEvents(eventIndex + 1)

            }, 100);

        }).catch((response: any) => {

            setEventSendIndex(eventIndex + 1)
            setTimeout(() => {

                sendEvents(eventIndex + 1)

            }, 100);

        })

    }
    const responseGoogleError = (response: any) => {

        alert('Se ha producido un error al iniciar sesión con Google.')

    }
    return (

        <div className='CalenportComponent' style={props?.styles}>

            <div className='events'>
                {
                    login && <div className='counter'>
                        <b>{eventsToSave.length}</b> eventos
                    </div>}
                {
                    loading && <LoaderComponent status={loading} />
                }
                {
                    !login && <div className='login'><GoogleLogin
                        clientId={CLIENT_ID}
                        onSuccess={responseGoogle}
                        onFailure={responseGoogleError}
                        buttonText={'Iniciar sesión con Google'}
                        scope={SCOPES}

                    /></div>
                }
                {
                    eventsToSave.map((event: any, index: number) => {

                        return (
                            <div className='event' key={index}>
                                <b>{index + 1}</b>  {event.name}
                            </div>
                        )

                    })
                }
            </div>
            {
                eventsToSave.length > 0 && <>

                    <div className='bar'>
                        <b>{eventSendIndex}</b> guardados de <b>{eventsToSave.length}</b>
                        <div style={{ width: 100 / eventsToSave.length * eventSendIndex + '%', }} />
                    </div>
                    <div className='submit' >
                        <button onClick={() => {

                            sendEvents(0)

                        }}>
                            importar
                            <i className='las la-arrow-right'></i>
                        </button>
                    </div></>}
        </div>

    );

}
export default connect(mapStateToProps, mapDispatchToProps)(CalenportComponent)
