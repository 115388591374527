import { useEffect, useState } from 'react';
import { ConnectDeviceOptions } from '../../../../dish.types';
import { DeviceFilterType } from '../../../../functions/devices.instance';
import BackgroundDishComponent from './components/background/background.dish.component';

interface ConfigurationSettings {
    connectDevice: (devices: { videoId: string } | { audioId: string } | { videoId: string, audioId: string }, options: ConnectDeviceOptions) => void;
    localScreams?: MediaStream[];

    getDevices: (filter?: DeviceFilterType) => Promise<MediaDeviceInfo[] | undefined>
}
interface HTMLInputEvent extends Event {
    target: HTMLInputElement & EventTarget;
}
export default function DishDevicesVideoSelector({ connectDevice, localScreams, getDevices }: ConfigurationSettings) {

    const [devices, setDevices] = useState<MediaDeviceInfo[]>([])
    const [videoSelected, setVideoSelected] = useState<string>()
    const [backgroundImage, setBackgroundImage] = useState<string | undefined>(undefined)
    // record:
    const [recordToggle, setRecordToggle] = useState<boolean>(false)
    const [recordSelected, setRecordSelected] = useState<boolean>(false)
    // ptz
    const [ptzToggle, setPtzToggle] = useState<boolean>(false)
    const [ptzSelected, setPtzSelected] = useState<boolean>(false)
    // echo
    const [echoToggle, setEchoToggle] = useState<boolean>(false)
    const [echoSelected, setEchoSelected] = useState<boolean>(false)

    const [noiseToggle, setNoiseToggle] = useState<boolean>(false)
    const [noiseSelected, setNoiseSelected] = useState<boolean>(false)

    const [label, setLabel] = useState<string>()
    const [status, setStatus] = useState<boolean>(false)

    const openSelector = async () => {

        if (!status) {

            setStatus(true)
            const response = await getDevices({
                video: true
            });
            console.log(response)
            response && setDevices(response)

        }
        else {

            setStatus(false)

        }

    }

    const apps = [
        {
            name: 'Visual Code',
        }
    ]
    const option = (device: MediaDeviceInfo, index: number) => {

        console.log('device', device)

        return <div className='choose' key={index} onClick={() => {

            setVideoSelected(device.deviceId)
            setLabel(device.label)
            setStatus(false)

        }}>
            <b>{
                device.label
            }</b>

        </div>

    }

    return <>
        <div className='selector'>
            <div className='placeholder' onClick={() => {

                openSelector()

            }}>
                <i className='las la-video'></i>
                <span>{label ? label : 'Selecciona un canal video'}</span>
                <i className='las la-angle-down'></i>
            </div>
            {
                status && <div className='chooses'>
                    {
                        devices.map((device, index) => option(device, index))
                    }
                </div>
            }

        </div>
        <div className='selector'>
            <div className='placeholder' onClick={() => {

                setRecordToggle(!recordToggle)

            }}>
                <i className='las la-compact-disc'></i>
                Grabación {recordSelected ? 'activada' : 'desactivada'}
                <i className='las la-angle-down'></i>
            </div>
            {
                recordToggle && <div className='chooses'>
                    <div className='choose' onClick={() => {

                        setRecordToggle(!recordToggle)
                        setRecordSelected(true)

                    }}>
                        <b>Activar</b>
                    </div>
                    <div className='choose' onClick={() => {

                        setRecordToggle(!recordToggle)
                        setRecordSelected(false)

                    }}>
                        <b>Desactivar</b>
                    </div>
                </div>
            }
        </div>
        <div className='selector'>
            <div className='placeholder' onClick={() => {

                setPtzToggle(!ptzToggle)

            }}>
                <i className='las la-compact-disc'></i>
                PTZ {ptzSelected ? 'activado' : 'desactivado'}
                <i className='las la-angle-down'></i>
            </div>
            {
                ptzToggle && <div className='chooses'>
                    <div className='choose' onClick={() => {

                        setPtzToggle(!ptzToggle)
                        setPtzSelected(true)

                    }}>
                        <b>Activar</b>
                    </div>
                    <div className='choose' onClick={() => {

                        setPtzToggle(!ptzToggle)
                        setPtzSelected(false)

                    }}>
                        <b>Desactivar</b>
                    </div>
                </div>
            }
        </div>

        <BackgroundDishComponent setBackgroundSelected={setBackgroundImage} />

        {videoSelected && <div className='submit' onClick={() => {

            videoSelected && connectDevice({
                videoId: videoSelected
            }, {
                background: backgroundImage,
                record: recordSelected,
                ptz: ptzSelected
            })

        }}>
            Añadir <i className='las la-arrow-right' />
        </div>}

    </>

}