import HeaderConstructorComponent from '../components/header/header.constructor.component'
import LayoutConstructorComponent from '../components/layout/layout.constructor.component'
import ComponentManager from './components/component/screen.component.screen'
import './manager.styles.scss'
import { ConstructorManagerComponent, ConstructorManagerComponentTypes, ConstructorManagerType } from './manager.type'
export default function ManagerConstructor({ application }: { application: ConstructorManagerType }) {

    return <LayoutConstructorComponent>
        <HeaderConstructorComponent title={application.code} />
        <div className='ManagerConstructor'>

            {
                application.scheme.components.map((component: ConstructorManagerComponentTypes, index: number) => {

                    return <ComponentManager component={component} key={index} />

                })
            }
        </div>
    </LayoutConstructorComponent>

}