
import Moment from 'react-moment'
import { ChatMessageType } from '../../chat.component'
import './message.styles.scss'
export default function MessageComponent({ message }: { message: ChatMessageType }) {

    console.log('me:', message)
    return <div className={`MessageComponent ${message.type}`} >
        <div className='details'>
            <div className='author'>{message.name}</div>
        </div>
        <div className='message'>{message.message}</div>
        <div className='date'><Moment fromNow >{message.date}</Moment></div>
    </div>

}