
// Function login props redux connect
import LayoutScreenComponent from '../components/layout/screen'
import { useState } from 'react';
import Stylize from '../stylize';
import SchemeConstructor from './../../scheme/scheme.constructor';
import { Helmet } from "react-helmet";
import './screens.styles.scss'

function LayoutScreensComponent() {

    const [scheme] = useState<any>(SchemeConstructor());
    console.log('Scheme:', scheme)
    if (!scheme?.screens) return <>Sin Scheme</>
    return <Stylize className="Screens" style={{
        position: 'fixed', display: {
            mobile: 'block',
            default: 'flex'
        },
        overflowY: {
            mobile: 'scroll',
        },
        top: 0, right: 0, left: 0,
    }}>
        {
            scheme && scheme?.screens?.map((screen: any, screenIndex: number) => {

                return <LayoutScreenComponent key={screenIndex} styles={screen?.styles} title={screen?.title} favicon={screen?.favicon}
                    path={screen?.path} name={screen?.name} components={screen?.components} />

            })
        }

    </Stylize>

}
export default LayoutScreensComponent
