import Moment from 'react-moment'
import { Socket } from 'socket.io-client'
import { DishLogType } from '../../../dish.types'
import './chat.styles.scss'

interface ChatProperties {
    setSection: () => void
    logs?: DishLogType[]

}
export default function DishLogComponent(settings: ChatProperties) {

    // first second of first log
    const firstLog = settings?.logs?.[0]?.date

    return <div className="Bar DishLogComponent">
        <div className='closer' onClick={() => {

            settings.setSection()

        }}>
            <i className="las la-times"></i>
        </div>
        <div className='title'>
            Eventos de Comunicaciones<br /><b>{settings?.logs?.length} logs</b>
        </div>
        <ul className='logs'>
            {
                firstLog && settings?.logs && settings?.logs.map((log: DishLogType, index: any) => {

                    // seconds of log
                    const logDate = log.date.getTime() - firstLog?.getTime()

                    return <li key={index} className={log.status ? 'ok' : 'ko'}>
                        <span>{log.log}</span>
                        <b>{logDate} s</b>
                    </li>

                })
            }
        </ul>
    </div>

}