import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import ChatRoom from "./components/chat.meet.component";
import './meet.styles.scss'
const MainScreen = () => {

  const socket = io("https://socket.comitas.alicunde.dev");

  const [chatRoom, setChatRoom] = useState("");
  const [me, setMe] = useState("");
  useEffect(() => {

    socket.on("me", (id) => {

      setMe(id);
      console.log("socket.on(me) ran");

    });

  }, []);
  return chatRoom !== "" ? (<div className="MeetComponent">
    <ChatRoom chatRoom={chatRoom} me={me} socket={socket} />
  </div>
  ) : (
    <div className="MeetComponent">
      <div>
        <h1>Simple Video Chat</h1>
        <div>
          <div onClick={() => setChatRoom("host")}>
            Create a Room
          </div>
          <div onClick={() => setChatRoom("guest")}>Join a Room</div>
        </div>
      </div>
    </div>
  );

};

export default MainScreen;
