
// Function login props redux connect
import './index.scss';
import { environment } from '../../../../../../environments/environment'
import VariableComponent from '../../../../development/variable/variable.component';
import LayoutButtonComponent, { ButtonComponentProps } from '../../../button'

interface DispatchProps {

    icon?: string,
    text: string,
}

export default function InformationComponent(props: DispatchProps) {

    return (
        <div className='LayoutFormInformationComponent'>
            <i className='las la-info-circle' />
            {props.text && <div className='text'>{props.text}</div>}
        </div>

    );

}

