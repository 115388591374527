import CalendarWeekHeader from './header/header.week'
import CalendarWeekTimeline from './timeline/timeline.week'
import CalendarWeekBody from './body/body.week'
import './week.styles.scss'
import { useEffect, useRef, useState } from 'react'
import { CalendarSettings } from '../..'
export default function CalendarWeek(props: {
    onNew: (start: Date, end: Date) => void,
    events: any[], daySelected: Date, onDelete: (item: any) => void, onEdit: (item: any) => void, settings: CalendarSettings, widthCalendar: number
}) {

    const [height, setHeight] = useState<number>()
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {

        if (ref.current && ref.current.offsetHeight) {

            setHeight(ref.current.offsetHeight)

        }

    }, [ref.current])
    return <div className="CalendarWeek" ref={ref}
        // event height layout
        style={{ maxHeight: height || 'auto', height: height || 'auto', width: props.widthCalendar }}
    >

        {height && <CalendarWeekBody
            onDelete={props.onDelete}
            events={props.events}
            onNew={props.onNew}
            height={height} daySelected={props.daySelected} onEdit={props.onEdit} settings={props.settings} />}
    </div>

}