
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useElementSize } from 'use-element-size'

import './year.component.scss';
export default function LayoutCalendarYearView(props: {

    onNew: (dateSelected: Date) => void, date: Date
}) {

    const DAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const DAYS_LEAP = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const [MONTHS, setMONTHS] = useState<string[]>([]);
    const [daysOfWeek, setDaysOfWeek] = useState<string[]>([]);
    // Get months spanish moment
    useEffect(() => {

        moment.locale('es-es');
        // Get months 
        const months = moment.months();
        setMONTHS(months);

        // Get days of week
        const days = moment.weekdaysMin();
        setDaysOfWeek(days);

    }, [])

    const today = new Date();
    const [date, setDate] = useState(today);
    const [day, setDay] = useState(date.getDate());
    const [month, setMonth] = useState(date.getMonth());
    const [year, setYear] = useState(date.getFullYear());
    const [startDay, setStartDay] = useState(getStartDayOfMonth(date));

    function isLeapYear(year: number) {

        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

    }
    const days = isLeapYear(date.getFullYear()) ? DAYS_LEAP : DAYS;

    useEffect(() => {

        setDay(props.date.getDate());
        setMonth(props.date.getMonth());
        setYear(props.date.getFullYear());
        setStartDay(getStartDayOfMonth(props.date));

    }, [props.date]);

    function getStartDayOfMonth(date: Date) {

        return new Date(date.getFullYear(), date.getMonth(), 1).getDay();

    }
    const [width, setWidth] = useState<number>(0);

    const ref = useElementSize((size, prevSize, elem) => {

        if (size) {

            const width = size.width / 7;

            setWidth(width)

        }

    })

    useEffect(() => {

        // Get element with className CalendarLine

        // Window scroll smoth
        setTimeout(() => {

            const element = document.getElementsByClassName('today')[0];
            if (element) {

                element.scrollIntoView?.({ block: "start", behavior: "smooth", });

            }

        }, 500);

    }, [])
    return (
        <div className={`LayoutCalendarYearComponent`}>
            <div className='frame'>
                {/* render months */}
                <div className='months'>
                    {MONTHS.map((month, monthIndex) => (
                        <div
                            ref={ref}
                            className={`month`}
                            key={monthIndex}
                            onClick={() => setMonth(monthIndex)}
                        >
                            <div className='name'>{month}</div>
                            <div className='days'>
                                {daysOfWeek.map((day, index) => (
                                    <div
                                        className={`day th`}
                                        key={index}
                                    >
                                        {day}
                                    </div>))}

                                {Array(days[monthIndex] + (startDay))
                                    .fill(null)
                                    .map((item, index) => {

                                        const daySelected = index - (startDay - 1);

                                        return (
                                            <div
                                                className={`day ${daySelected > 0 ? 'active' : 'empty'} 
                                    ${daySelected === day ? 'selected' : ''} 
                                    ${monthIndex === today.getMonth() && daySelected === today.getDate() ? 'today' : ''}`}
                                                key={index}
                                                onClick={() => {

                                                    const dateSelected = new Date(year, monthIndex, daySelected);
                                                    props.onNew(dateSelected)

                                                }}
                                                style={{ width: width, maxWidth: width, minWidth: width, height: width, maxHeight: width, minHeight: width }}
                                            >
                                                {daySelected > 0 && <div className='number'>{daySelected}</div>}

                                            </div>
                                        );

                                    })}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

}
