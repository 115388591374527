import './chart.style.scss';
import {
    Chart as ChartJS,
    ChartOptions,
    registerables
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import EndpointComponent from '../../development/endpoint/endpoint.component';
import { environment } from '../../../../environment';
import { useEffect, useState } from 'react';
import { getProperty } from 'dot-prop';
import { connect } from 'react-redux';
import RequestAction from '../../../actions/request/request.action';
import { Replace } from '../../../utilities/replace/replace.utility';
import { useParams } from 'react-router-dom';
ChartJS.register(
    ...registerables
);

interface ChartProperties {
    store: any,
    type: 'chart',
    title: string,
    subtitle: string,
    endpoint: string,
    options?: ChartOptions
}
function ChartComponent(props: ChartProperties) {

    const [data, setData] = useState<any>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const paramsUlr = useParams()

    const getData = (page: number) => {

        setLoading(true)
        setData({})
        if (!props.endpoint) return false;
        const currentUserToken = getProperty(props.store, 'user.access_token');
        console.log('ara:', paramsUlr)
        const endpoint = Replace({ params: paramsUlr }, props.endpoint)

        RequestAction({
            endpoint: endpoint,
            method: 'get',
            headers: {
                'authorization': 'Bearer ' + currentUserToken
            }
        }).then((response) => {

            setTimeout(() => {

                setLoading(false)
                console.log('data chart:', response.data)
                response.data && setData(response.data)

            }, 300);

        }).catch((error) => {

            setLoading(false)

        })
        return true;

    }

    useEffect(() => {

        getData(0);

    }, [])
    console.log('chart options:', props.options)
    return <div className='LayoutChartComponent'>
        <div className='title'>{props.title}</div>
        <div className='subtitle'>{props.subtitle}</div>

        <div className='chart'>
            {!loading && data && <Chart type='bar' data={data} options={props.options} />}
        </div>
    </div>;

}

const mapStateToProps = (state: any, ownProps: any) => (state)

export default connect(mapStateToProps,)(ChartComponent)
