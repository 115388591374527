import { useState } from "react";
import LegalDishComponent from "../../../legal/legal.dish.component";
import './settings.dish.styles.scss'
export function DishVideoSettings({ audioOutputDeviceLabel, stream, setShowSettings, setAudioOutputDevice, audioOutputDevice, flip, flipVideo, getAudioOutputDevices }: {
    stream: MediaStream, setShowSettings: () => void, flip: boolean,
    getAudioOutputDevices?: () => Promise<MediaDeviceInfo[] | undefined>
    setAudioOutputDevice: (deviceId: string, label: string) => void
    audioOutputDevice?: string,
    flipVideo?: () => void,
    audioOutputDeviceLabel?: string
}) {

    const [audioOutputStatus, setAudioOutputStatus] = useState<boolean>(false)
    const [audioOutputDevices, setAudioOutputDevices] = useState<MediaDeviceInfo[]>([])
    const videoSettings = stream.getVideoTracks().length > 0 ? stream.getVideoTracks()[0].getSettings() : undefined;
    const audioSettings = stream.getAudioTracks().length > 0 ? stream.getAudioTracks()[0].getSettings() : undefined;
    const streamSettings: MediaStreamTrack = stream.getVideoTracks().length > 0 ? stream.getVideoTracks()[0] : stream.getAudioTracks()[0];
    const option = (name: string, icon: string, value: boolean | undefined | string | number, description?: string,) => {

        if (typeof value === 'string') {

            // only 10 letters
            if (value.length > 15) {

                value = value.substring(0, 15) + '...'

            }

        }
        return <div className="option">
            <i className={icon}></i>
            <span>{name} {description !== undefined && <b>{description} </b>}</span>
            <b>
                {
                    typeof value === 'boolean' && value ? <i className='las la-check'></i> : typeof value === 'boolean' && !value ? <i className='las la-times'></i> : value
                }
            </b>
        </div>

    }
    return <>
        <div className="SettingsDishComponent" />

        <div className="settings">

            <div className='title'>
                Configuración <b>del canal</b>
                <div className='closer' onClick={() => {

                    setShowSettings()

                }}>
                    <i className="las la-times"></i>
                </div>
            </div>
            <div className="layout">
                {getAudioOutputDevices && <>
                    <div className='divisor'>Estado de Stream</div>
                    <div className="box">
                        <div className='infor' >
                            <i className='las la-volume-up'></i>
                            Puedes seleccionar el canal de salida de audio de tu dispositivo, para que puedas escuchar la llamada en el dispositivo que desees.
                        </div>
                        <div className='selector'>
                            <div className='placeholder' onClick={async () => {

                                setAudioOutputStatus(!audioOutputStatus)
                                const outputDevices = await getAudioOutputDevices()
                                setAudioOutputDevices(outputDevices || [])

                            }}>
                                <i className='las la-compact-disc'></i>
                                <span>{audioOutputDeviceLabel ? audioOutputDeviceLabel : 'Cambiar canal de audio'}</span>
                                <i className='las la-angle-down'></i>
                            </div>
                            {
                                audioOutputStatus && <div className='chooses'>
                                    {
                                        audioOutputDevices.map((device, index) => {

                                            return <div className='choose' key={index} onClick={() => {

                                                setAudioOutputDevice(device.deviceId, device.label)
                                                setAudioOutputStatus(false)

                                            }}>
                                                {device.label}
                                            </div>

                                        })
                                    }
                                </div>
                            }
                        </div></div>
                </>}
                <div className='divisor'>Estado de Stream</div>
                <div className="box">
                    {
                        streamSettings && <>
                            {
                                option('Estado del Stream', 'las la-plug', streamSettings?.enabled)
                            }

                            {
                                option('Disponibilidad de Uso', 'las la-signal', streamSettings?.readyState)
                            }
                            {
                                option('Tipo', 'las la-cog', streamSettings?.kind)
                            }
                            {
                                option('Nombre', 'las la-signature', streamSettings?.label)
                            }
                        </>
                    }
                </div>

                {
                    videoSettings && <>
                        <div className='divisor'>Estado del Video</div>
                        <div className="box">

                            {
                                videoSettings?.frameRate && option('Frecuencia de fotogramas', 'las la-clock', Math.round(videoSettings?.frameRate))
                            }

                            {
                                option('Aspect Ratio', 'las la-crop', videoSettings?.aspectRatio?.toFixed(2))
                            }
                            {
                                option('Tamaño', 'las la-crop-alt', `${videoSettings?.height} x ${videoSettings?.width}`)
                            }
                            <div onClick={() => {

                                flipVideo?.()

                            }}>
                                {
                                    option('Efecto Espejo', 'las la-arrows-alt-h', flip)
                                }
                            </div>
                        </div>
                    </>
                }

                {
                    streamSettings && <>
                        <div className='divisor'>Estado de Audio</div>
                        <div className="box">

                            {
                                option('Volumen', 'las la-microphone', !streamSettings?.muted)
                            }
                            {
                                option('Supresión de Ruido', 'las la-drum', audioSettings?.noiseSuppression)
                            }
                            {
                                option('Frecuencia', 'las la-clock', audioSettings?.sampleRate || 'AUTO')
                            }
                            {
                                option('Muestra de Audio', 'las la-history', audioSettings?.sampleSize)
                            }

                            {
                                option('Cancelación de Eco', 'las la-assistive-listening-systems', audioSettings?.echoCancellation)
                            }
                            {
                                // @ts-ignore
                                option('Latencia de Audio', 'las la-tachometer-alt', audioSettings?.latency)
                            }
                            {
                                option('Volumen Automático', 'las la-magic', audioSettings?.autoGainControl,)
                            }
                        </div>
                    </>
                }
                <LegalDishComponent />
            </div>
        </div>
    </>

}