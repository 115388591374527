import { useState } from 'react';
import LoaderComponent from '../../../loader/loader.component';
import './calendar.header.styles.scss'
import Moment from 'moment';
interface CalendarHeaderProperties {
    title?: string,
    mode: string,
    setMode: (mode: string) => void,
    setDate: (date: Date) => void,
    date: Date,
    modeDayAvailable: boolean,
    loading: boolean,
    count: number
}
export function CalendarHeaderComponent({ modeDayAvailable, setDate, loading, date, mode, count, setMode }: CalendarHeaderProperties) {

    const MONTHS = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
    ];
    const optionValues = [
        ...modeDayAvailable ? [{ value: 'day', label: 'Día' }] : [],
        { value: 'week', label: 'Semana' },
        { value: 'month', label: 'Mes' },
        { value: 'year', label: 'Año' },
    ]

    const month = (date.getMonth());
    const year = (date.getFullYear());
    const day = (date.getDate());
    const [viewDisplay, setViewDisplay] = useState<boolean>(false);
    return <div className="CalendarHeaderComponent">
        <div className="header">

            <div className="title">

                {loading && <div className='loader'> <LoaderComponent status={loading} /></div>}
                <div className="current" style={{ opacity: loading ? 0 : 1 }}>
                    {
                        mode === "year" ? <div className="principal year">{year}</div> : mode === "month" ? <><div className="month">{MONTHS[month]}</div><div className="year">{year}</div></> :
                            mode === "week" ? <><div className="week">{
                                // render moment month name
                                Moment(date).format('MMMM')

                            }

                                <b>{year}</b>
                            </div></> :
                                mode === "day" ? <><div className="month">{MONTHS[month]}</div> <div className="day">{day}</div><div className="year">{year}</div></> : null

                    }

                </div>

            </div>
            <div className="views">
                <div className='selection' onClick={() => {

                    setViewDisplay(!viewDisplay)

                }}>

                    {
                        optionValues.map((item: any, index) => {

                            return item.value === mode && item.label

                        })
                    }
                    <i className={viewDisplay ? 'las la-angle-up' : 'las la-angle-down'} /></div>
                {viewDisplay && <div className='options'>
                    {
                        optionValues.map((item: any, index) => {

                            return <div
                                className={`item ${item.value === mode && "active"}`}
                                onClick={() => {

                                    setDate(new Date());
                                    setMode(item.value);
                                    setViewDisplay(false);

                                }}
                                key={index}
                            >
                                {item.label}
                            </div>

                        })
                    }

                </div>}
            </div>
            <div className="controls">
                <div
                    className="button text"
                    onClick={() => {

                        // momment today
                        const today = new Date();
                        // if mode is week
                        if (mode === "week") {

                            const firstDay = Moment(today).startOf('week').toDate()
                            setDate(firstDay)

                        }

                        else {

                            setDate(new Date())

                        }

                    }}
                >
                    HOY
                </div>

                <div
                    className="button"
                    onClick={() => {

                        if (mode === "day") {

                            setDate(new Date(year, month, day - 1));

                        } else if (mode === "week") {

                            setDate(new Date(year, month, day - 7));

                        }
                        else if (mode === "month") {

                            setDate(new Date(year, month - 1, day));

                        }
                        else if (mode === "year") {

                            setDate(new Date(year - 1, month, day));

                        }

                    }}
                >
                    <i className="las la-arrow-left" />
                </div>
                <div
                    className="button"
                    onClick={() => {

                        if (mode === "day") {

                            setDate(new Date(year, month, day + 1));

                        } else if (mode === "week") {

                            setDate(new Date(year, month, day + 7));

                        }
                        else if (mode === "month") {

                            setDate(new Date(year, month + 1, day));

                        }
                        else if (mode === "year") {

                            setDate(new Date(year + 1, month, day));

                        }

                    }}
                >
                    <i className="las la-arrow-right" />
                </div>
            </div>
        </div>
    </div>

}