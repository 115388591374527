import { useEffect, useState } from 'react';
import { DishPermissions } from '../../../dish.types';
import DetectRTC from '../../../functions/computer.detect';
import LegalDishComponent from '../../legal/legal.dish.component';
import { ReactInternetSpeedMeter } from '../internet';
import './initialization.component.scss';

interface ConfigurationSettings {
  setSection: () => void;
  permissions?: DishPermissions
}

export default function ConfigurationComponent({ setSection, }: ConfigurationSettings) {

  const [permissions, setPermissions] = useState<typeof DetectRTC>()
  const [ips, setIps] = useState<string>()
  useEffect(() => {

    const permissionVideo = DetectRTC;
    DetectRTC.load(function () {

      setPermissions(DetectRTC)
      DetectRTC.DetectLocalIPAddress((ip: string) => {

        console.log(ip)
        ip && setIps(ip)

      })

    });

  }, [])

  const option = (name: string, icon: string, value: boolean | undefined | string, counter?: number,) => {

    return <div className='option'>
      <i className={icon}></i>
      <span>{name} {counter !== undefined && <b>{counter} dispositivos</b>}</span>
      <b>
        {
          typeof value === 'boolean' && value ? <i className='las la-check'></i> : typeof value === 'boolean' && !value ? <i className='las la-times'></i> : value
        }
      </b>
    </div>

  }
  return <div className="bar">

    <div className='title'>
      Configuración de la llamada <b>23 configuraciones</b>
      <div className='closer' onClick={() => {

        setSection()

      }}>
        <i className="las la-times"></i>
      </div>
    </div>
    <div className='layout'>
      <div className='divisor'>Permisos</div>
      <div className='box'>
        {option('Video', 'las la-video', permissions?.hasWebcam, permissions?.videoInputDevices?.length || 0)}
        {option('Audio', 'las la-microphone', permissions?.hasMicrophone, permissions?.audioInputDevices?.length || 0)}

        <div className='option'>
          <i className='las la-desktop'></i>
          <span>Compartir Pantalla</span>
          <b>
            {permissions?.isScreenCapturingSupported ? <i className='las la-check' /> : <i className='las la-times' />}
          </b>
        </div>
      </div>
      <div className='divisor'>Emisión de Video</div>
      <div className='box'>
        {option('Cámara', 'las la-camera', permissions?.hasWebcam)}
        {option('Aspect Ratio', 'las la-crop', permissions?.displayAspectRatio)}
        {option('Resolución', 'las la-expand', permissions?.displayResolution)}
      </div>
      <div className='divisor'>Dispositivo</div>
      <div className='box'>
        {option('Dispositivo Móvil', 'las la-mobile', permissions?.isMobileDevice)}
        {option('Navegador', 'las la-window-minimize', permissions?.browser?.name)}
        {option('Incognito', 'las la-user-secret', permissions?.browser?.isPrivateBrowsing)}
        {option('WebRTC Versión', 'las la-satellite-dish', permissions?.version)}
        {option('WebRTC Supported', 'las la-plug', permissions?.isWebRTCSupported)}
        {option('Os Version', 'las la-memory', permissions?.osVersion)}
      </div>
      <div className='divisor'>Conectividad</div>
      <div className='box'>
        <div className='option'>
          <i className='las la-wifi'></i>
          <span>Conectividad</span>
          <b>
            <i className='las la-check' />
          </b>
        </div>
        {option('IP', 'las la-video', ips)}

        {option('Socket Soportado', 'las la-satellite', permissions?.isWebSocketsSupported)}
        {option('Socket Bloqueado', 'las la-ban', permissions?.isWebSocketsBlocked)}

        <div className='option'>
          <i className='las la-exchange-alt'></i>
          <span>Velocidad</span>
          <b>
            <ReactInternetSpeedMeter
              txtSubHeading="Internet is too slow"
              outputType="alert"
              customClassName={null}
              txtMainHeading="Opps..."
              pingInterval={40000} // Milliseconds 
              thresholdUnit='megabyte' // "byte" , "kilobyte", "megabyte" 
              threshold={100}
              imageUrl="https://alicunde.es/data/es/ferrovial/1.jpg"
              downloadSize="1153433"  // Bytes
              callbackFunctionOnNetworkDown={(speed: any) => {

                console.log(`Internet speed is down ${speed}`);

              }}
              callbackFunctionOnNetworkTest={(speed: any) => console.log('Baja')}
            />
          </b>
        </div>
      </div>
      <LegalDishComponent />
    </div>
  </div >

}