import { useState } from 'react'
import { Socket } from 'socket.io-client'
import './form.styles.scss'
export default function FormComponent({ socket, room, sendMessage }: { socket?: Socket, room?: string, sendMessage: (message: string) => void }) {

    const [message, setMessage] = useState('')
    return <div className="FormDishComponent">
        <textarea className='textarea'
            placeholder='Escribe un mensaje a todos'
            autoCorrect='off'
            onChange={
                (event: any) => {

                    setMessage(event.target.value)

                }
            }
            // return key
            onKeyDown={
                (event: any) => {

                    // send message in return
                    if (event.key === 'Enter') {

                        if (message.length > 0) {

                            event.preventDefault()
                            event.stopPropagation()
                            sendMessage(message)

                            setMessage('')

                        }

                    }

                }
            }
            value={message}
        />
        <div className='bottom'>
            <div className='button' onClick={() => {

                if (message.length > 0) {

                    sendMessage(message)
                    setMessage('')

                }

            }}> <i className='las la-arrow-right'></i></div>
        </div>     </div >

}