import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';

export const ReactInternetSpeedMeter = ({
  txtMainHeading = 'Opps...',
  outputType = 'alert',
  customClassName,
  pingInterval = 30000,
  thresholdUnit = 'megabyte',
  threshold = 7,
  txtSubHeading = 'Diconnected from internet',
  imageUrl,
  downloadSize, // Bytes
  callbackFunctionOnNetworkDown = (speed) =>
    console.log('No callback on Network Down'),
  callbackFunctionOnNetworkTest = (speed) =>
    console.log('No callback On Network test'),
  callbackFunctionOnError = () => console.log('error in downloading image'),
}) => {

  const [isNetworkDown, setisNetworkDown] = useState(false);
  const [speedCurrent, setSpeedCurrent] = useState(0);
  let intervalFun;

  window.addEventListener('offline', () => setisNetworkDown(true));
  window.addEventListener('online', () => setisNetworkDown(false));

  const startCalculating = () => {

    return window.setTimeout(MeasureConnectionSpeed, pingInterval);

  };

  useEffect(() => {

    intervalFun = startCalculating();
    return () => window.clearInterval(intervalFun);

  }, []);

  const MeasureConnectionSpeed = () => {

    let startTime, endTime;
    let download = new Image();
    startTime = new Date().getTime();

    let cacheBuster = '?nnn=' + startTime;
    download.src = imageUrl + cacheBuster;

    download.onload = function (d) {

      endTime = new Date().getTime();
      showResults(startTime, endTime);

    };

    download.onerror = function (err, msg) {

      callbackFunctionOnError(err);
      window.clearInterval(intervalFun);

    };
    startCalculating();

  };

  const showResults = (startTime, endTime) => {

    const duration = (endTime - startTime) / 1000;

    const bitsLoaded = downloadSize * 8;
    const speedBps = (bitsLoaded / duration).toFixed(2);
    const speedKbps = (speedBps / 1024).toFixed(2);
    const speedMbps = (speedKbps / 1024).toFixed(2);

    setNetworStatus(speedBps, speedKbps, speedMbps);

  };

  const setNetworStatus = (speedBps, speedKbps, speedMbps) => {

    if (speedMbps < threshold) setisNetworkDown(false);
    else setisNetworkDown(true);

    setSpeedCurrent(speedMbps);

  };

  if (isNetworkDown) {

    return (
      <>
        <b>0</b>mb/s
      </>
    );

  }
  return (
    <>
      <b>{speedCurrent}</b> mb/s
    </>
  );

};
