
import { useEffect, useState } from 'react';
import './timeline.component.scss';
export default function LayoutCalendarTimelineView() {

    // Render hours
    const [date,] = useState(new Date());
    const currentTop = date.getHours() * 60 + date.getMinutes();
    useEffect(() => {

        // Get element with className CalendarLine
        const element = document.getElementsByClassName('CalendarLine')[0];
        if (element) {

            // Window scroll smoth
            setTimeout(() => {

                element.scrollIntoView?.({ block: "center", behavior: "smooth", });

            }, 200);

        }

    }, [])
    return <>

        <div className='CalendarLine x' style={{
            top: `${((100 * 24) / (24 * 60)) * currentTop}px`
        }}>`${((100 * 24) / (24 * 60)) * currentTop}` - {currentTop}
            <div className='cursor'>
                <div className='hour'>
                    {
                        date.getHours()
                    }:
                    {
                        ('0' + date.getMinutes()).substr(-2)
                    }
                </div>
            </div>
            <div className='bar'></div>
        </div>
    </>

}