import Menu from './becalling/menu.json';
import Patients from './becalling/patients.json';
import Meets from './becalling/meets.json';
import Help from './becalling/help.json';
import Groups from './becalling/groups.json';
import Account from './becalling/account.json';
import Dish from './becalling/dish.json';
import Login from './becalling/login.json';
import Register from './becalling/register.json';
import RegisterSuccess from './becalling/registerSuccess.json';
import Invitations from './becalling/invitations.json';
import Relations from './becalling/relations.json';
import Users from './becalling/users.json';
export default function BecallingConstructor() {

    return {
        "type": "frontend",
        "name": "professional",
        "path": "login",
        "styles": { "flex": 1 },
        "screens": [
            Login,
            Register,
            RegisterSuccess,
            Dish,
            {
                "type": "screen",
                "name": "calendar",
                "path": ["manager/*", "admin/*"],

                "styles": {
                    "zIndex": 10,
                    "display": "flex",
                    "flex": 1,
                    "flexDirection": "row",
                    "backgroundColor": "#eee",
                    "position": "relative"
                },
                "components": [

                    Menu,

                    {
                        "type": "screen",
                        "name": "unauthorized",
                        "styles": {
                            "display": "flex",
                            "flex": 1,
                            "height": "100vh",
                            "overflowY": "scroll",

                        },
                        "components": [
                            {
                                "type": "space"
                            },
                            Meets,
                            Patients,
                            Help,
                            Groups,
                            Account,
                            Users,
                            Invitations,
                            Relations,
                            {
                                "type": "space"
                            }
                        ]
                    },
                    {
                        "type": "space"
                    }
                ]
            }
        ]
    }

}