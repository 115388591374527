import { Socket } from 'socket.io-client';
import { DishLogType } from '../../../dish.types';
import './controls.styles.scss';

interface ControlsProperties {
  socketStatus?: string,
  section?: string,
  setSection: (section: string | undefined) => void
  finishCall: () => void
  stopAudio: () => void
  audio: boolean
  video: boolean
  media: boolean
  messagesCount: number
  getStreenMedia: () => void
  logs?: DishLogType[],
}

export default function ControlsDish(settings: ControlsProperties) {

  // count logs with status false
  const logsCount = settings.logs?.filter((log: DishLogType) => !log.status).length || 0

  const renderLogotype = () => {

    return <div className="logo" onClick={settings.finishCall}>
      <img src="/assets/comitas/images/logotypes/icon.svg" />
      <div className={`circle ${settings.socketStatus === 'connected' ? 'true' : 'false'}`}></div>
    </div>

  }

  const renderButton = (icon: string, onClick: () => void, className = "button", count?: number) => {

    return <div className={className} onClick={onClick} ><i className={icon} />{count ? <sup
      className={icon === 'las la-terminal' ? 'red' : 'green'}
    >{count}</sup> : null}</div>

  }

  return <div className="ControlsComponent">
    {renderLogotype()}
    <div className="buttons">
      {renderButton('las la-phone', () => settings.finishCall(), 'button exit')}

      {settings.socketStatus === 'connected' && renderButton('las la-plus', () => settings.setSection(settings.section === 'devices' ? undefined : 'devices'))}
      {settings.socketStatus === 'connected' && renderButton('las la-comments', () => settings.setSection(settings.section === 'chat' ? undefined : 'chat'), undefined, settings.messagesCount)}
      {settings.socketStatus === 'connected' && renderButton('las la-th-large', () => settings.setSection(settings.section === 'layouts' ? undefined : 'layouts'))}

      {settings.socketStatus === 'connected' && renderButton('las la-cog', () => settings.setSection(settings.section === 'configuration' ? undefined : 'configuration'))}
      {
        settings.socketStatus === 'connected' && renderButton('las la-expand', () => {

          // toggle fullscreen web
          if (!document.fullscreenElement) {

            document.documentElement.requestFullscreen();

          } else {

            if (document.exitFullscreen) {

              document.exitFullscreen();

            }

          }

        })
      }

      {/* {renderButton('las la-terminal', () => settings.setSection('log'), undefined, logsCount)} */}
    </div>
  </div>;

}