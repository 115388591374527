
import moment from 'moment';
import './month.calendar.component.scss'
import { useEffect, useState } from 'react';
import DayMonthCalendar from './day/day.month.calendar';
import { CalendarSettings } from '../..';
export default function LayoutCalendarMonthView(props: {
    settings: CalendarSettings
    setDateSelected: (date: Date) => void
    widthCalendar: number,
    events: any[],
    onNew: (start: Date, end: Date) => void,
    onEdit: (item: any) => void,
    date: Date, items: { id: string, type: string, started_at: string, name: string, subject: string }[]
}) {

    const [days, setDays] = useState<Date[]>([]);
    useEffect(() => {

        // week start in monday (0), sunday(-1)
        const firstDayOfWeek = 0

        // create array of days of render month:
        const cubes: Date[] = [];

        // get first day of month of props.date
        const firstDayOfMonth = moment(props.date).startOf('month').toDate();
        // get first cube of calendar (first day of week is monday)
        const firstCube = moment(firstDayOfMonth).startOf('week').add(firstDayOfWeek, 'days').toDate();
        // get days of month
        const daysOfMonth = moment(props.date).daysInMonth();
        // days before first day of month
        const daysBefore = moment(firstDayOfMonth).diff(firstCube, 'days');
        // days after last day of month
        const daysAfter = 42 - daysOfMonth - daysBefore;

        // add days before
        const total = daysBefore + daysOfMonth + daysAfter;
        // add days of month to array
        for (let i = 0; i < total; i++) {

            cubes.push(moment(firstCube).add(i, 'days').toDate());

        }
        setDays(cubes);

    }, [props.date])
    console.log('cubes', days)
    return (
        <div className='LayoutCalendarMonthComponent'>

            <div className='frame'>

                <div className='content'>

                    <div className='names'>
                        {days.map((daySelected, index) => {

                            if (index > 6) return null;

                            const nameDay = moment(daySelected).format('ddd');
                            return <div className='name' key={index}>
                                <strong>{nameDay}</strong>
                            </div>

                        })}
                    </div>
                    <div className='body'>

                        {days

                            .map((day, index) => {

                                return <DayMonthCalendar
                                    settings={props.settings}
                                    date={day}
                                    onNew={props.onNew}
                                    onEdit={props.onEdit}
                                    setDateSelected={props.setDateSelected}
                                    key={index}
                                    disabled={
                                        moment(day).month() !== moment(props.date).month()
                                    }
                                    events={props.events}
                                    widthCalendar={props.widthCalendar} />

                            })}
                    </div>
                </div>
            </div></div >
    );

}

function Coloring(percenta: number) {

    const GTR_MAX_VALUE = 255;
    const GTR_FORMULA = 2 / 100 * GTR_MAX_VALUE;

    const intToHex = function (number: number) {

        const hex = number.toString(16);
        return hex.length === 1 ? '0' + hex : hex;

    }
    let percentage = 100 - percenta
    percentage = Math.min(Math.max(0, percenta), 100);
    const green = percentage <= 50 ? GTR_MAX_VALUE : GTR_MAX_VALUE - (percentage - 50) * GTR_FORMULA;
    const red = percentage >= 50 ? GTR_MAX_VALUE : GTR_MAX_VALUE - (50 - percentage) * GTR_FORMULA;
    const rgb = new Uint8Array([red, green, 0]);

    return `#${intToHex(rgb[0])}${intToHex(rgb[1])}00`;

}