import HeaderConstructorComponent from '../components/header/header.constructor.component'
import LayoutConstructorComponent from '../components/layout/layout.constructor.component'
import { ConstructorApplication } from '../constructor.types'
import TableModel from './components/table/table.model'
import './model.styles.scss'
import { ConstructorModelType } from './model.type'
export default function ModelConstructor({ application }: { application: ConstructorModelType }) {

    return <LayoutConstructorComponent>
        <HeaderConstructorComponent title={application.code} />
        <div className='ModelConstructor'>

            {
                Object.keys(application.scheme.tables).map((table: string, tableIndex: number) => {

                    return <TableModel table={table} columns={application.scheme.tables[table].columns}
                        key={tableIndex} />

                })
            }
        </div>
    </LayoutConstructorComponent>

}