import { useEffect, useState } from 'react';
import './header.styles.scss'
export default function CalendarWeekHeader(props: { daySelected: Date, events: any }) {

    const [days, setDays] = useState<Date[]>([])

    useEffect(() => {

        // Day of week  props.date
        // Get first day of week:
        const daysOfWeek = [];

        const firstDayWeek = (props.daySelected);
        for (let i = 0; i < 7; i++) {

            // Create new day + 24 hours
            const day = new Date(firstDayWeek);
            day.setHours(day.getHours() + 24 * i);
            daysOfWeek.push(day);

        }
        console.log('dias semana:', daysOfWeek)
        setDays(daysOfWeek);

    }, [props.daySelected]);

    return <div className="CalendarWeekHeader"
        style={{
            width: 250 * 7 + 60 + 60
        }}
    >
        {props.daySelected.getDate()}
        {
            days.map((day, index) => {

                // format yyyy-mm-dd
                const date = day.toISOString().split('T')[0];
                // get day of month
                const dayOfMonth = day.getDate();
                return <div className="column withoutColor" key={index}>

                    <div className="head">
                        <b>{
                            day.toLocaleDateString('es-ES', { weekday: 'short' }).toUpperCase()
                        }.</b>
                        {dayOfMonth}
                        <span><em>{props?.events?.[dayOfMonth]?.length || 0}</em> EVENTOS</span>
                    </div>

                </div>

            })
        }
    </div >

}