import { useEffect, useState } from 'react';
import { connect } from 'socket.io-client';
import { connect as reduxConnect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './cheeky.styles.scss';
import ConnectedComponent from './components/connected/connected.component';
import ContactComponent from './components/contact/contact.component';
import useSound from 'use-sound';
import AnswerComponent from './components/answer/answer.component';
import RequestAction from '../../../actions/request/request.action';
const boopSfx = require('./call.mp3');

const mapStateToProps = (state: any, ownProps: any) => (state)

interface DispatchProps {
    setStore: (s: any) => void
    color?: string,
    store: any,

}
type Props = DispatchProps

export function CheekyComponent(props: Props) {

    const [play, { stop }] = useSound(boopSfx);

    const navigate = useNavigate();
    const [socket, setSocket] = useState<any>(null);
    const [call, setCall] = useState<string | undefined>();
    const [callName, setCallName] = useState<string | undefined>();
    const [callEmail, setCallEmail] = useState<string | undefined>();
    const [availables, setAvailables] = useState<any>({});
    const server = 'https://socket.comitas.alicunde.dev';

    // 

    const createSocket = () => {

        try {

            const socket = connect(server, {
                secure: false,
                path: '/socket/socket.io',
            }).on('connect', () => {

                console.log('connect')
                setSocket(socket);
                socket.emit('available', props.store?.user?.user?.id, props.store?.user?.user?.email, `${props.store?.user?.user?.name} ${props?.store?.user?.user?.surnames}`);
                socket.on('user-available', (fromId, message) => {

                    console.log('user-available', fromId, message)
                    // Clone:
                    setAvailables(message);

                });
                socket.on('user-left', (fromId, message) => {

                })
                socket.on('call-to-you', (fromId, message, name, email) => {

                    console.log('call-to-you', fromId, message, name)
                    // create audio tag and play
                    const audio = new Audio(boopSfx);
                    audio.play();
                    // @ts-ignore
                    window?.electron?.shell?.beep?.()
                    setTimeout(() => {

                        const audio = new Audio(boopSfx);
                        audio.play();

                    }, 1000);

                    setCall(message);
                    setCallName(name);
                    setCallEmail(email);

                });

            }).on('disconnect', () => {

                console.log('disconnect')

            }).on('reconnect', () => {

                console.log('reconnect')

            }).on('reconnect_attempt', () => {

                console.log('reconnect_attempt')

            }).on('reconnecting', () => {

                console.log('reconnecting')

            }).on('reconnect_error', () => {

                console.log('reconnect_error')

            }).on('reconnect_failed', () => {

                console.log('reconnect_failed')

            }).on('error', () => {

                console.log('error')

            });

            return socket

        } catch (error) {

        }

    };

    useEffect(() => {

        const socket = createSocket();

        const interval = setInterval(() => {

            createSocket();

        }, 5000);

        return () => {

            socket && socket.close();
            //clearInterval(interval);

        };

    }, [props.store?.user?.user?.id]);
    return <div className="CheckyComponent" style={{ backgroundColor: props?.color }}>
        {
            availables && Object.keys(availables).map((key: any, index: number) => {

                if (availables[key].email !== props.store.user.user.email) {

                    return <ContactComponent
                        key={index}
                        name={availables[key].name}
                        call={
                            () => {

                                console.log(props.store)
                                const callRoom = 'hello'
                                RequestAction({
                                    endpoint: '/api/meet/fast',
                                    method: 'post',
                                    data: {
                                        userId: availables[key].id,
                                    },
                                    headers: {
                                        'authorization': 'Bearer ' + props.store.user.access_token
                                    },
                                }).then((response) => {

                                    const meetId = response.data.items[0].id
                                    socket.emit('call-user', key, meetId, props.store.user.user.email, `${props.store.user.user.name} ${props.store.user.user.surnames}`);
                                    navigate(`/meet/${meetId}`);

                                }).catch((error) => {

                                })

                                // Navigate(`/meet/${callRoom}`);

                            }
                        }
                    />

                }
                return null

            })
        }

        {
            <ConnectedComponent status={socket} label={`${props.store.user.user.name} ${props.store.user.user.surnames}`} usersCount={Object.keys(availables).length} />

        }

        {
            callName && callEmail && call && <AnswerComponent
                callName={callName}
                callEmail={callEmail}
                call={call} cancelCall={
                    () => {

                        setCall(undefined);

                    }
                } openCall={
                    () => {

                        navigate(`/meet/${call}`);
                        setCall(undefined);

                    }
                } />
        }
    </div>

}
export default reduxConnect(mapStateToProps,)(CheekyComponent)
