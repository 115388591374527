import { ConstructorModelColumnType } from '../../model.type'
import './table.styles.scss'
export default function TableModel({ table, columns }: {
    table: string, columns: {
        [key: string]: ConstructorModelColumnType
    }
}) {

    return <div className='TableModel'>
        <div className='name'>{table}</div>
        <div className='columns'>
            {
                Object.keys(columns).map((column: string, columnIndex: number) => {

                    return <div className='column' key={columnIndex}>
                        <div className='name'>{column}</div>
                        <div className='type'>{columns[column].type}</div>
                    </div>

                })
            }
        </div>
    </div>

}