import { useEffect, useRef } from 'react'
import './chat.styles.scss'
import FormComponent from './components/form/form.component'
import MessageComponent from './components/message/message.component'
export type ChatMessageType = {
    room: string
    message: string
    name: string
    date: number,
    socketId: string
    type: 'me' | 'other'
}
interface ChatProperties {
    setSection: () => void
    messages?: ChatMessageType[]
    room?: string
    sendMessage: (message: string) => void

}
export default function ChatComponent(settings: ChatProperties) {

    // ref messages
    const messagesRef = useRef<HTMLDivElement>(null)
    // scroll to bottom
    const scrollToBottom = () => {

        if (messagesRef.current) {

            // scroll to bottom smooth
            messagesRef.current.scrollTo({
                top: messagesRef.current.scrollHeight,
                behavior: 'smooth'
            })

        }

    }
    // scroll to bottom
    useEffect(() => {

        scrollToBottom()

    }, [settings.messages])
    return <div className="bar ChatComponent">

        <div className='title'>
            Mensajes de la llamada<b>{settings?.messages?.length} mensajes</b>
            <div className='closer' onClick={() => {

                settings.setSection()

            }}>
                <i className="las la-times"></i>
            </div>
        </div>
        <div className='messages'
            ref={messagesRef}
        >
            {
                settings?.messages && settings?.messages.map((message: any, index: any) => {

                    return <MessageComponent key={index} message={message} />

                })
            }
        </div>
        <FormComponent sendMessage={settings?.sendMessage} room={settings.room} />
    </div>

}