import { getProperty } from 'dot-prop';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ExecuteActions from '../../../actions';
import RequestAction from '../../../actions/request/request.action';
import { ActionsType } from '../../../actions/types';
import { setStore } from '../../../store/actions';
import { Replace } from '../../../utilities/replace/replace.utility';
import LoaderComponent from '../loader/loader.component';
import PosClientsComponent from './components/clients/pos.clients.component';
import { PosHeaderComponent } from './components/header/pos.header.component';
import PosProductsListComponent from './components/productsList/pos.products.component';
import PosProductsComponent from './components/products/pos.products.component';
import './index.scss';
import { PosClientSettingsType, PosClientType, PosProducsSettingsType, PosProductType } from './types';
import PosCategoriesComponent from './components/categories/pos.categories.component';
import PosStatusComponent from './components/status/pos.status.component';

const mapDispatchToProps = {

    setStore
}

interface PosProperties {
    store: any,
    setStore: (s: any) => void,
    actions: ActionsType[],
    endpoint: string,
    items: {
        endpoint: string,
        method: string
    },
    payment: {
        methods: {
            icon: string,
            value: string,
            label: string
        }[]
    },
    products: {
        [key: string]: PosProducsSettingsType
    },
    clients: PosClientSettingsType
    create: {
        endpoint: string,
        method: string
    }
}
interface productList {
    [key: string]: PosProductType[]
}
function LayoutPosComponent(props: PosProperties) {

    return (<div className='LayoutPosComponent'>
        <PosCategoriesComponent />
        <PosStatusComponent />
    </div>);

}

const mapStateToProps = (state: any, ownProps: any) => (state)

export default connect(mapStateToProps, mapDispatchToProps)(LayoutPosComponent)
