/* eslint-disable max-lines */

// Function login props redux connect
import './index.scss';
import InputComponent from './components/text';
import TitleComponent from './components/title';
import { useEffect, useState } from 'react';
import SubmitComponent from './components/submit';
import LayoutFormTextareaComponent from './components/textarea';
import LayoutFormSelectComponent from './components/select/select.component';
import LayoutFormSignatureComponent from './components/signature';
import LayoutFormCheckboxComponent from './components/checkbox';
import { environment } from './../../../../environments/environment'

import { setStore } from '../../../store/actions'
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import RequestAction from '../../../actions/request/request.action';
import EndpointComponent from '../../development/endpoint/endpoint.component';
import ExecuteActions from '../../../actions';
import { getProperty } from 'dot-prop';
import LayoutFormNumberComponent from './components/number';
import ObjectComponent from '../../development/object/object.component';
import LayoutFormErrorComponent from '../error';
import LayoutFormDatetimeComponent from './components/datetime';
import LoaderComponent from '../loader/loader.component';
import FormGoogleStorage from './components/google-storage';
import MapBoxComponent from './components/mapBox';
import ColorComponent from './components/color';
import FormPosComponent from './components/pos';

import { Replace } from '../../../utilities/replace/replace.utility';
import { ButtonComponentProps } from '../button';
import LayoutFormEditorComponent from './components/editor';
import InformationComponent from './components/information';
import LayoutFormDownloadComponent from './components/download';
import AccordionComponent from './components/accordion/accordion.component';
import StripeComponent from './components/stripe/stripe.component';

const mapDispatchToProps = {

    setStore
}
const mapStateToProps = (state: any, ownProps: any) => (state)
type DependentType = {
    name: string,
    value: string,
}
interface InputType {
    title?: string,
    icon?: string,
    description?: string,
    id?: string,
    client?: string,
    endpoint: string, idSearch?: string,
    submit?: boolean,
    bubble?: string,
    bubbles?: {
        content: string,
        background?: string
    }[],
    requireSearch?: boolean,
    mode?: string,
    label?: string,
    path?: string,
    compress?: {
        width?: number,
        height?: number,
        quality?: number
    },
    disabled?: boolean,
    selectFirst?: boolean,
    decimals?: number,
    bucket?: string,
    options?: { id: string, label: string }[],
    value?: string,
    defaultValue?: string,
    text?: string,
    buttons?: ButtonComponentProps[],
    type: 'stripe' | 'download' | 'mapBox' | 'information' | 'color' | 'editor' | 'pos' | 'divisor' | 'signature' | 'google-storage' | 'text' | 'textarea' | 'select' | 'datetime' | 'date' |
    'password' | 'email' | 'number' | 'radio' | 'submit' | 'reset' | 'file' | 'hidden' | 'image' | 'button',
    name: string
    placeholder?: string,
    dependents?: DependentType[],
}
interface CheckboxType {
    title?: string,
    description?: string,
    icon?: string,
    name: string,
    value?: string,
    label: string, defaultValue?: string,
    type: 'checkbox',
    buttons?: ButtonComponentProps[],
    dependents?: DependentType[],

}
interface LineType {
    type: 'line',

    inputs: InputsType[],
    dependents?: DependentType[],
}

interface MultipleType {
    type: 'multiple',
    name: string,
    tab: string,
    title?: string,
    description?: string,
    inputs: InputsType[],
    buttons?: ButtonComponentProps[],
    dependents?: DependentType[],

}
type InputsType = InputType | CheckboxType | LineType | MultipleType
interface ActionRedirect {
    type: 'redirect',
    path: string
}
interface DispatchProps {
    setStore: (s: any) => void
    header?: {
        title?: string,
        description?: string,
        icon?: string,
    },
    recovery?: {
        endpoint: string,
        method: string,
    }
    item?: any,
    success?: boolean
    successReset?: boolean
    endpoint: string,
    method: 'post' | 'put',
    store: any,
    inputs?: InputsType[],
    styles?: any,
    actions?: ActionRedirect[],
    onSubmit?: (data: any) => void
}
type Props = DispatchProps

// eslint-disable-next-line max-lines-per-function
function LayoutFormComponent(props: Props) {

    const [formKey, setFormKey] = useState(0)
    const [ready, setReady] = useState<boolean>(false);
    const [data, setData] = useState<any>({});
    const [change, setChange] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);

    const [errors, setErrors] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);
    const navigation = useNavigate()
    const paramsUlr = useParams()
    const { search } = useLocation();

    const setDataInput = async (name: string, value: string) => {

        setData((data: any) => {

            return { ...data, [name]: value }

        });

    }

    useEffect(() => {

        if (data && data.change && data.change === 'yes' && props.inputs) {

            // Check input have submit param
            const inputs = props.inputs.filter((input: any) => input.submit)
            if (inputs.length > 0) {

                submitForm()

            }

        }

    }, [data])
    useEffect(() => {

        if (props.inputs) {

            const queryparams: any = {}
            if (search) {

                if (search.split('?')) {

                    const queryparamsx = search.split('?')[1];

                    const params = queryparamsx.split('&');

                    let pair = null

                    params.forEach(function (d) {

                        pair = d.split('=');
                        const proper = pair[0]
                        queryparams[proper] = pair[1]

                    });

                }

            }

            const defaultData: any = data
            props.inputs.forEach((input: any) => {

                if (typeof input.defaultValue === 'number') {

                    defaultData[input.name] = input.defaultValue

                }
                else if (input.defaultValue) {

                    console.log('form params:', paramsUlr)
                    const val = Replace({ store: props.store, query: queryparams, params: paramsUlr }, input.defaultValue);
                    if (val) defaultData[input.name] = val

                }

            }
            )
            setData(defaultData)
            setReady(true)

        }

    }, [])
    useEffect(() => {

        if (props.item && props.inputs) {

            setData(props.item);
            const defaultData: any = {}
            props.inputs.forEach((input: any) => {

                if (input.type === 'line') {

                    input.inputs.forEach((inputChild: any) => {

                        if (props.item[inputChild.name]) {

                            defaultData[inputChild.name] = props.item[inputChild.name]

                        }

                    })

                }
                else if (props.item[input.name] !== undefined) {

                    defaultData[input.name] = props.item[input.name]

                }

            }
            )
            // Clone deep:
            const cloneData = JSON.parse(JSON.stringify(defaultData))
            setData(cloneData)

        }

    }, [props.item]);
    const params = useParams();

    const submitForm = () => {

        if (props.endpoint) {

            // Post data:
            setLoading(true)
            setErrors([])
            const currentUserToken = getProperty(props.store, 'user.access_token');

            const endpoint = Replace({ params, item: props.item }, props.endpoint)

            RequestAction({
                endpoint: endpoint,
                method: props.method || 'POST',
                data: data,
                headers: {
                    'authorization': 'Bearer ' + currentUserToken
                }
            }).then((res: any) => {

                if (res.status === 200 || res.status === 201) {

                    setTimeout(() => {

                        setLoading(false)
                        if (props.success) {

                            setShowSuccess(true)

                            setTimeout(() => {

                                setShowSuccess(false)
                                if (props.successReset) {

                                    setFormKey(formKey + 1)
                                    setData({})
                                    setChange(false)

                                }

                            }, 1000);

                        }
                        if (props.actions && res.data?.items) {

                            ExecuteActions({
                                data: res.data.items[0],
                                params: paramsUlr,
                                item: res.data.items[0],
                                commands: {
                                    navigation: navigation,
                                    store: props.setStore
                                }, actions: props.actions
                            })

                        }

                    }, 500);

                }
                else {

                    alert('Se ha producido un error')

                }

            }).catch((response: any) => {

                setTimeout(() => {

                    setLoading(false)
                    if (response.response?.data?.errors) {

                        setErrors(response.response.data.errors)

                    }

                }, 300);

            })

        }
        else if (props.actions) {

            ExecuteActions({
                params: paramsUlr,
                item: data,
                commands: {
                    navigation: navigation,
                    store: props.setStore
                },
                actions: props.actions
            })

        }

    }
    const renderErrors = (errors: any) => {

        if (Array.isArray(errors)) {

            return <div className='FormComponentInputError'>
                <i className="las la-exclamation-circle"></i> {JSON.stringify(errors)}
            </div>

        }
        return <>
            {
                Object.keys(errors).map((key: any) => {

                    // Check input have same key:

                    const showError = props.inputs?.find((input: any) => {

                        if (input.name) {

                            if (input.name === key) {

                                if (input.type !== 'hidden') {

                                    return true;

                                }

                            }

                        }
                        else if (input.type === 'line') {

                            const r = input.inputs.find((inputChild: any) => inputChild.name === key && inputChild.name);
                            if (r) {

                                return true;

                            }

                        }
                        return null

                    })
                    if (!showError) {

                        return errors[key].map((error: any) => {

                            return <div className='FormComponentInputError'>
                                <i className="las la-exclamation-circle"></i> {error}
                            </div>

                        })

                    }

                    return null

                })
            }
        </>

    }
    let errorScroll = 'first';
    const renderInputs = (inputs: InputsType[], line = false, multipleUpdate?: any, multipleDataValue?: any) => {

        const dataForm = multipleDataValue || data;

        const setDataDinamic = (name: string, value: any) => {

            if (multipleUpdate) {

                multipleUpdate(name, value)

            }
            else {

                setDataInput(name, value)

            }

        }

        if (!inputs) return null;
        return inputs?.map((input, index) => {

            if (input.dependents) {

                console.log('Tiene dependets', input.dependents)
                // validate if dataForm have same value of all posibilities
                const validate = input.dependents.find((dependent: any) => {

                    if (dependent.value === dataForm[dependent.name]) {

                        return true;

                    }

                })
                if (!validate) return null

            }
            if (input.type === 'line' && errors) {

            }
            if (input.type !== 'multiple' && input.type !== 'line' && errors) {

                if (input?.name) {

                    if (input?.name && errors?.[input?.name]) {

                        if (errorScroll === 'first') {

                            errorScroll = 'active';

                        }

                        else {

                            errorScroll = 'no';

                        }

                    }

                }

            }

            return <div className={`FormComponentInput ${input.type === 'hidden' && 'FormComponentInputHidden'} ${input.type !== 'multiple' && input.type !== 'line' && input.icon && 'haveIcon'}`} key={index}>
                {input.type !== 'line' && input.type !== 'divisor' && input.title && <TitleComponent
                    name={input?.name}
                    title={input.title} description={input.description} buttons={input.buttons} />}

                {
                    input.type === 'line' &&

                    <div className='line'>
                        {
                            renderInputs(input.inputs, true)
                        }
                    </div>

                }
                {
                    input.type === 'multiple' &&

                    <div className='multiple'>
                        {
                            data[input.name] && Array.isArray(data[input.name]) && data[input.name].map((item: any, index: number) => {

                                return <div className='multipleItem' key={index}>
                                    <AccordionComponent title={Replace({ item: item }, input.tab) || ''} onDelete={
                                        () => {

                                            const cloneData = JSON.parse(JSON.stringify(data))
                                            cloneData[input.name].splice(index, 1)
                                            setData(cloneData)
                                            setChange(true)

                                        }
                                    }>
                                        {
                                            renderInputs(input.inputs, false, (name: string, value: any) => {

                                                const ValueClone = JSON.parse(JSON.stringify(dataForm[input.name]))
                                                ValueClone[index][name] = value;
                                                setDataDinamic(input.name, ValueClone)

                                            }, (dataForm[input.name] && dataForm[input.name][index]) || {})
                                        }
                                    </AccordionComponent>

                                </div>

                            })
                        }
                        <div className='more' onClick={() => {

                            if (dataForm[input.name] && Array.isArray(data[input.name])) {

                                const ValueClone = dataForm[input.name] ? JSON.parse(JSON.stringify(dataForm[input.name])) : []
                                ValueClone.push({})
                                setDataDinamic(input.name, ValueClone)

                            }
                            else {

                                const ValueClone = []
                                ValueClone.push({})
                                setDataDinamic(input.name, ValueClone)

                            }

                        }}>
                            <i className='las la-plus' />
                        </div>
                    </div>

                }
                {
                    input.type === 'divisor' && <div className='divisor'>
                        <div className='title'>{input.title}</div>
                        <div className='description'>{input.description}</div>
                    </div>
                }
                {
                    input.type === 'information' && input.text && <InformationComponent text={input.text} icon={input.icon} />
                }
                {
                    (input.type === 'editor') &&

                    <LayoutFormEditorComponent
                        key={index}
                        name={input.name}
                        defaultValue={dataForm[input.name] || input.defaultValue}
                        placeholder={input?.placeholder || ''}
                        setData={(value: string) => {

                            setDataDinamic(input.name, value)

                        }}

                    />

                }
                {
                    (input.type === 'text' || input.type === 'hidden' || input.type === 'password') &&

                    <InputComponent
                        key={index}
                        icon={input.icon}
                        type={input.type}
                        disabled={input.disabled}

                        name={input.name}
                        defaultValue={dataForm[input.name] || input.defaultValue}
                        placeholder={input?.placeholder || ''}
                        setData={(value: string) => {

                            setDataDinamic(input.name, value)

                        }}
                        onSubmit={() => {

                            submitForm()

                        }}
                    />

                }
                {
                    (input.type === 'color') &&

                    <ColorComponent
                        key={index}
                        icon={input.icon}
                        type={input.type}
                        disabled={input.disabled}

                        name={input.name}
                        defaultValue={dataForm[input.name]}
                        placeholder={input?.placeholder || ''}
                        setData={(value: string) => {

                            setDataDinamic(input.name, value)

                        }}
                        onSubmit={() => {

                            submitForm()

                        }}
                    />

                }
                {
                    (input.type === 'mapBox') &&

                    <MapBoxComponent
                        key={index}
                        icon={input.icon}
                        type={input.type}
                        name={input.name}
                        defaultValue={dataForm[input.name]}
                        placeholder={input?.placeholder || ''}
                        setData={(value: any) => {

                            console.log('mapBox', value)
                            setDataDinamic(input.name, value)

                        }}
                    />

                }
                {
                    (input.type === 'pos') &&

                    <FormPosComponent
                        key={index}
                        icon={input.icon}
                        type={input.type}
                        endpoint={input.endpoint}
                        name={input.name}
                        defaultValue={dataForm[input.name]}
                        placeholder={input?.placeholder || ''}
                        setData={(value: string) => {

                            setDataDinamic(input.name, value)

                        }}
                    />

                }
                {
                    (input.type === 'google-storage') &&

                    <FormGoogleStorage
                        key={index}
                        icon={input.icon}
                        bucket={input.bucket}
                        type={input.type}
                        endpoint={input.endpoint}
                        compress={input.compress}
                        mode={input.mode}
                        name={input.name}
                        defaultValue={dataForm[input.name]}
                        placeholder={input?.placeholder || ''}
                        setData={(value: string) => {

                            setDataDinamic(input.name, value)

                        }}
                    />

                }
                {
                    (input.type === 'textarea') &&

                    <LayoutFormTextareaComponent
                        key={index}
                        name={input.name}
                        disabled={input.disabled}
                        defaultValue={dataForm[input.name]}
                        placeholder={input?.placeholder || ''}
                        setData={(value: string) => {

                            setDataDinamic(input.name, value)

                        }}
                    />

                }
                {
                    (input.type === 'checkbox') &&

                    <LayoutFormCheckboxComponent
                        key={index}
                        type="checkbox"
                        name={input.name} defaultValue={dataForm[input.name]}

                        label={input.label}
                        setData={(value: string) => {

                            setDataDinamic(input.name, value)

                        }}
                    />

                }
                {
                    (input.type === 'download') &&

                    <LayoutFormDownloadComponent
                        key={index}
                        type="download"
                        value={dataForm[input.name]}

                        label={input.label}

                    />

                }
                {
                    (input.type === 'number') &&

                    <LayoutFormNumberComponent
                        key={index}
                        type="number"
                        decimals={input.decimals}
                        name={input.name}
                        icon={input.icon}
                        defaultValue={dataForm[input.name]}
                        placeholder={input?.placeholder || ''}
                        setData={(value: string) => {

                            setDataDinamic(input.name, value)

                        }}

                        onSubmit={() => {

                            submitForm()

                        }}
                    />

                }
                {
                    (input.type === 'datetime' || input.type === 'date') &&

                    <LayoutFormDatetimeComponent
                        key={index}
                        type={input.type}
                        name={input.name}
                        icon={input.icon}
                        defaultValue={dataForm[input.name] || input.defaultValue}
                        placeholder={input?.placeholder || ''}
                        setData={(value: string) => {

                            setDataDinamic(input.name, value)

                        }}
                    />

                }
                {
                    (input.type === 'stripe') &&

                    <StripeComponent
                        key={index}
                        client={input.client}
                        name={input.name}
                        setData={(value: string) => {

                            setDataDinamic(input.name, value)

                        }}
                    />

                }
                {
                    (input.type === 'signature') &&

                    <LayoutFormSignatureComponent
                        key={index}
                        name={input.name}
                        defaultValue={dataForm[input.name]}
                        placeholder={input?.placeholder || ''}
                        setData={(value: string) => {

                            setDataDinamic(input.name, value)

                        }}
                    />

                }
                {
                    (input.type === 'select') &&

                    <LayoutFormSelectComponent
                        key={index}
                        name={input.name} defaultValue={dataForm[input.name]}
                        path={input.path}
                        disabled={input.disabled}
                        item={props.item}
                        options={input.options}
                        bubble={input.bubble}
                        bubbles={input.bubbles}
                        selectFirst={input.selectFirst}
                        icon={input.icon}
                        requireSearch={input.requireSearch}
                        idSearch={input.idSearch}
                        id={input.id}
                        label={input.label}
                        placeholder={input?.placeholder || ''}
                        setData={(value: string) => {

                            setDataDinamic(input.name, value)
                            input.submit && setDataDinamic('change', 'yes')

                        }}
                    />

                }
                {
                    input.type !== 'line' && errors && input?.name && <LayoutFormErrorComponent errors={errors} scrollError={errorScroll === 'active'} parent={line ? 2 : 1} name={input.name} />
                }
                {
                    (input.type === 'submit') &&

                    <SubmitComponent
                        key={index}
                        buttons={input.buttons}
                        value={input.value}
                        onSubmit={() => {

                            submitForm()

                        }}
                    />
                }

            </div>

        }
        )

    }
    const renderData = (data: any) => {

        return <ObjectComponent data={data} />

    }
    errorScroll = 'first'

    return (

        <div className='FormComponent' style={props?.styles} key={formKey}>

            {
                showSuccess && <div className='success' >
                    <i className='las la-check'></i> Guardado con éxito
                </div>
            }
            {
                loading && <div className='loading-box'>
                    <LoaderComponent status={loading} />
                </div>
            }
            {
                ready && <>

                    {
                        // Render data:
                        environment.debug && renderData(data)
                    }
                    {
                        props?.header && <div className='header'>
                            {
                                props.header?.icon && <div className={`icon ${props.header?.icon}`} />
                            }
                            <div className='text'>
                                <div className='title'>{props.header?.title}</div>
                                {
                                    props.header?.description && <div className='description'>{props.header?.description}</div>
                                }
                            </div>
                        </div>
                    }

                    {
                        errors && Object.keys(errors).length > 0 && <div className='FormComponentInputError'>
                            <i className="las la-exclamation-circle"></i> Se {Object.keys(errors).length > 1 ? 'han' : 'ha'} producido <b>{Object.keys(errors).length}</b> {Object.keys(errors).length > 1 ? 'errores' : 'error'}
                        </div>
                    }
                    {
                        errors && Object.keys(errors).length > 0 && renderErrors(errors)
                    }
                    {
                        environment.debug && props.recovery && <EndpointComponent action="Recovery" endpoint={props.recovery.endpoint} method={props.recovery.method} />
                    }
                    {
                        environment.debug && <EndpointComponent action="Submit" endpoint={props.endpoint} method={props.method} />
                    }
                    {props.inputs && renderInputs(props.inputs)}
                </>}
        </div>

    );

}
export default connect(mapStateToProps, mapDispatchToProps)(LayoutFormComponent)
