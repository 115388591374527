/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
// General
import Login from './inkonsky/login.json';
import Recovery from './inkonsky/recovery.json';
import Register from './inkonsky/register.json';
// Professional
import ProfessionalMenu from './inkonsky/professional/menu.json';
import ProfessionalMenuPhone from './inkonsky/professional/menuPhone.json';
import ProfessionalHelp from './inkonsky/professional/help.json';
import ProfessionalPost from './inkonsky/professional/social/post.json';
import ProfessionalCalendar from './inkonsky/professional/schedule/calendar.json';
import ProfessionalClient from './inkonsky/professional/schedule/client.json';
import ProfessionalMeet from './inkonsky/professional/schedule/meet.json';
import ProfessionalAcceptance from './inkonsky/professional/schedule/acceptance.json';
import ProfessionalProject from './inkonsky/professional/schedule/project.json';
import ProfessionalContract from './inkonsky/professional/schedule/contract.json';
import ProfessionalSold from './inkonsky/professional/warehouse/sold.json';
import ProfessionalTickets from './inkonsky/professional/warehouse/tickets.json';
import ProfessionalProduct from './inkonsky/professional/warehouse/product.json';
import ProfessionalMeetSold from './inkonsky/professional/warehouse/meet_sold.json';
import ProfessionalBrand from './inkonsky/professional/warehouse/brand.json';
import ProfessionalCategory from './inkonsky/professional/warehouse/category.json';
import ProfessionalDistributor from './inkonsky/professional/warehouse/distributor.json';
import ProfessionalStock from './inkonsky/professional/warehouse/stock.json';
import ProfessionalDelivery from './inkonsky/professional/warehouse/delivery.json';
import ProfessionalAccount from './inkonsky/professional/account/account.json';
import ProfessionalRoom from './inkonsky/professional/account/room.json';
import ProfessionalInvitation from './inkonsky/professional/account/invitation.json';
import ProfessionalSponsor from './inkonsky/professional/account/sponsor.json';
import ProfessionalPermission from './inkonsky/professional/account/permission.json';
import ProfessionalStore from './inkonsky/professional/account/store.json';

// Client
import ClientMenu from './inkonsky/client/menu.json';
import ClientMenuPhone from './inkonsky/client/menuPhone.json';
import ClientAccount from './inkonsky/client/account.json';
import ClientMeet from './inkonsky/client/meet.json';

// Admin
import AdminStyle from './inkonsky/admin/style.json';
import AdminRanking from './inkonsky/admin/ranking.json';
import AdminRanked from './inkonsky/admin/ranked.json';

export default function InkonskyConstructor() {

  return {
    type: 'frontend',
    name: 'professional',
    path: 'login',
    title: 'Ink on Sky',

    styles: {
      flex: 1,

      overflowY: {
        mobile: 'scroll',
      },
    },
    screens: [
      Login,
      Recovery,
      Register,
      {
        type: 'screen',
        title: 'Ink on Sky',
        favicon: "/assets/inkonsky/images/logotypes/favicon.png",
        components: [
          {
            "type": "middleware",
            "conditions": [
              {
                "mode": "is",
                "type": "store",
                "key": "user.user.type",
                "value": "professional"
              }
            ],
            components: [
              {
                path: ['manager/*', 'admin/*'],
                type: 'retrieve',
                endpoint: '/api/artist/session',
                method: 'get',
                actions: {
                  failed: [
                    {
                      type: 'redirect',
                      path: '/',
                    },
                  ],
                },
                components: [
                  {
                    type: 'screen',
                    name: 'calendar',
                    path: ['manager/*', 'admin/*'],

                    styles: {
                      zIndex: 10,
                      display: 'flex',
                      flex: 1,
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0, backgroundColor: '#eee',
                      flexDirection: 'row',
                    },
                    components: [
                      ProfessionalMenu,
                      ProfessionalMenuPhone,
                      {
                        type: 'screen',
                        name: 'unauthorized',
                        styles: {
                          display: 'flex',
                          flex: 1,
                          overflowY: 'scroll',
                          padding: 20
                        },
                        components: [

                          {
                            type: 'screen',
                            styles: {
                              display: 'flex',
                              flex: 1,
                              flexDirection: 'column',

                            },
                            components: [
                              {
                                type: 'screen',
                                name: 'unauthorized',

                                components: [

                                  {
                                    path: '/manager/menu',
                                    type: 'button',
                                    icon: 'las la-arrow-left',
                                    text: 'Volver',
                                    styles: {
                                      display: {
                                        mobile: 'block',
                                        default: 'none',
                                      },
                                    },
                                  },
                                  ProfessionalCalendar,
                                  ProfessionalClient,
                                  ProfessionalProduct,
                                  ProfessionalProject,
                                  ProfessionalRoom,
                                  ProfessionalPermission,
                                  ProfessionalAcceptance,
                                  ProfessionalSold,
                                  ProfessionalStock,
                                  ProfessionalCategory,
                                  ProfessionalContract,
                                  ProfessionalSponsor,
                                  ProfessionalDistributor,
                                  ProfessionalDelivery,
                                  ProfessionalHelp,
                                  ProfessionalMeet,
                                  ProfessionalMeetSold,
                                  ProfessionalStore,
                                  ProfessionalAccount,
                                  ProfessionalInvitation,
                                  ProfessionalPost,
                                  AdminStyle,
                                  AdminRanking,
                                  AdminRanked,
                                  ProfessionalTickets,
                                  ProfessionalBrand,

                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            "path": "/appointment",
            "type": "middleware",
            "conditions": [
              {
                "mode": "nonexist",
                "type": "store",
                "key": "user.user.type",
              }
            ],
            components: [

              {
                "path": "appointment/:store_id",
                "type": "screen",
                "components": [
                  {
                    "type": "splash",
                    "mask": "rgba(0, 0, 0,0.4)",
                    "video": "inkonsky/videos/splash.mp4",
                    "styles": {
                      "filter": "grayscale(1)"
                    }
                  }

                ]
              },
              {
                "path": "appointment/:store_id",
                "type": "screen",
                "mode": "Modal",
                "styles": {
                  "width": "50%",
                  "maxWidth": "1000px",
                  "left": "0",
                },
                "components": [
                  {
                    "title": "Ink on Sky",
                    "type": "logotype",
                    "styles": {
                      "width": "230px",
                      "margin": "20px",
                      "padding": "23px 30px 0"
                    },
                    "image": "inkonsky/images/logo.svg"
                  },
                  {
                    "title": "Solicitar Cita",
                    "description": "Completa la solicitud y el tatuador se pondrá en contacto contigo.",
                    "type": "bar",
                    "close": {
                      "path": "/"
                    }
                  },
                  {
                    "type": "box",
                    "components": [
                      {
                        "type": "form",
                        "actions": [
                          {
                            "type": "store",
                            "action": "save",
                            "path": "user"
                          },
                          {
                            "type": "redirect",
                            "path": "/manager/account"
                          }
                        ],
                        "endpoint": "/api/project/unregistered",
                        "method": "post",
                        "inputs": [
                          {
                            "type": "hidden",
                            "defaultValue": "{params.store_id}",
                            "name": "store_id"
                          },
                          {
                            "title": "Tatuador",
                            "type": "select",
                            "path": "/api/search?store_id={params.store_id}",
                            "icon": "las la-tint",
                            "name": "artist_id",
                            "label": "{item.name} {item.surname}",
                            "id": "{item.id}",
                            "placeholder": ""
                          },
                          {
                            "type": "line",
                            "inputs": [
                              {
                                "title": "¿Entre qué fechas te gustaría tatuarte?",
                                "icon": "las la-calendar",
                                "type": "date",
                                "name": "started_at",
                                "defaultValue": "now",
                                "placeholder": ""
                              },
                              {
                                "title": " .",
                                "icon": "las la-calendar",
                                "type": "date",
                                "name": "finished_at",
                                "defaultValue": "now+1h",
                                "placeholder": ""
                              }
                            ]
                          },
                          {
                            "title": "Detalles",
                            "type": "divisor"
                          },
                          {
                            "title": "¿De qué tamaño aproximado quieres el tatuaje?",
                            "name": "size",
                            "icon": "la la-ruler-horizontal",
                            "type": "select",
                            "label": "{item.name}",
                            "options": [
                              {
                                "id": 1,
                                "name": "Pequeño"
                              },
                              {
                                "id": 2,
                                "name": "Mediano"
                              },
                              {
                                "id": 3,
                                "name": "Grande"
                              },
                              {
                                "id": 4,
                                "name": "Muy Grande"
                              }
                            ]
                          },
                          {
                            "title": "¿Cual es el asunto del tatuaje?",
                            "name": "title",
                            "type": "text"
                          },
                          {
                            "title": "¿Puedes hacer una breve descripción del tatuaje?",
                            "icon": "la la-address-card",
                            "name": "description",
                            "type": "textarea"
                          },
                          {
                            "type": "checkbox",
                            "title": "¿Requieres que el tatuador diseñe por completo el tatuaje?",
                            "name": "design_require",
                            "label": "El cliente solicita un diseño personalizado"
                          },
                          {
                            "type": "checkbox",
                            "title": "¿Quieres que tenga colores?",
                            "name": "color_require",
                            "label": "El cliente ha solicitado hacer uso de colores"
                          },
                          {
                            "title": "¿En qué lugar del cuerpo?",
                            "name": "zone",
                            "icon": "la la-ruler-horizontal",
                            "type": "select",
                            "label": "{item.name}",
                            "options": [
                              {
                                "id": "head",
                                "name": "Cabeza"
                              },
                              {
                                "id": "neck",
                                "name": "Cuello"
                              },
                              {
                                "id": "shoulder",
                                "name": "Hombro"
                              },
                              {
                                "id": "shoulder-right",
                                "name": "Hombro derecho"
                              },
                              {
                                "id": "shoulder-left",
                                "name": "Hombro izquierdo"
                              },
                              {
                                "id": "thorax",
                                "name": "Tórax"
                              },
                              {
                                "id": "back",
                                "name": "Espalda"
                              },
                              {
                                "id": "arm",
                                "name": "Brazo"
                              },
                              {
                                "id": "arm-right",
                                "name": "Brazo derecho"
                              },
                              {
                                "id": "arm-left",
                                "name": "Brazo izquierdo"
                              },
                              {
                                "id": "elbow",
                                "name": "Codo"
                              },
                              {
                                "id": "elbow-right",
                                "name": "Codo derecho"
                              },
                              {
                                "id": "elbow-left",
                                "name": "Codo izquierdo"
                              },
                              {
                                "id": "forearm",
                                "name": "Antebrazo"
                              },
                              {
                                "id": "forearm-right",
                                "name": "Antebrazo derecho"
                              },
                              {
                                "id": "forearm-left",
                                "name": "Antebrazo izquierdo"
                              },
                              {
                                "id": "wrist",
                                "name": "Muñeca"
                              },
                              {
                                "id": "wrist-right",
                                "name": "Muñeca derecha"
                              },
                              {
                                "id": "wrist-left",
                                "name": "Muñeca izquierda"
                              },
                              {
                                "id": "hand",
                                "name": "Mano"
                              },
                              {
                                "id": "hand-right",
                                "name": "Mano derecha"
                              },
                              {
                                "id": "hand-left",
                                "name": "Mano izquierda"
                              },
                              {
                                "id": "abdomen",
                                "name": "Abdomen"
                              },
                              {
                                "id": "gluteus",
                                "name": "Gluteo"
                              },
                              {
                                "id": "gluteus-right",
                                "name": "Gluteo derecho"
                              },
                              {
                                "id": "gluteus-left",
                                "name": "Gluteo izquierdo"
                              },
                              {
                                "id": "hip",
                                "name": "Cadera"
                              },
                              {
                                "id": "pelvic",
                                "name": "Pélvica"
                              },
                              {
                                "id": "thigh",
                                "name": "Muslo"
                              },
                              {
                                "id": "thigh-right",
                                "name": "Muslo derecho"
                              },
                              {
                                "id": "thigh-left",
                                "name": "Muslo izquierdo"
                              },
                              {
                                "id": "knee",
                                "name": "Rodilla"
                              },
                              {
                                "id": "knee-right",
                                "name": "Rodilla derecha"
                              },
                              {
                                "id": "knee-left",
                                "name": "Rodilla izquierda"
                              },
                              {
                                "id": "left",
                                "name": "Pierna"
                              },
                              {
                                "id": "left-right",
                                "name": "Pierna derecha"
                              },
                              {
                                "id": "left-left",
                                "name": "Pierna izquierda"
                              },
                              {
                                "id": "ankle",
                                "name": "Tobillo"
                              },
                              {
                                "id": "ankle-right",
                                "name": "Tobillo derecho"
                              },
                              {
                                "id": "ankle-left",
                                "name": "Tobillo izquierdo"
                              },
                              {
                                "id": "foot",
                                "name": "Pie"
                              },
                              {
                                "id": "foot-right",
                                "name": "Pie derecho"
                              },
                              {
                                "id": "foot-left",
                                "name": "Pie izquierdo"
                              }
                            ]
                          },
                          {
                            "title": "¿Qué tono de piel tienes?",
                            "description": "Variación del color de la piel según la escala de Fitzpatrik. La única intención de esta información es para facilitar al tatuador la selección de la paleta de colores del tatuaje. Este software no exige completar este campo y es responsabilidad del usuario validar que la regulación de su país lo permita.",
                            "name": "skin",
                            "icon": "la la-swatchbook",
                            "type": "select",
                            "label": "{item.name}",
                            "bubble": "{item.detail}",
                            "options": [
                              {
                                "id": "s1",
                                "name": "Piel muy clara",
                                "detail": "Blanca, pálida"
                              },
                              {
                                "id": "s2",
                                "name": "Piel clara",
                                "detail": "Carne, marfil, vainilla"
                              },
                              {
                                "id": "s3",
                                "name": "Piel clara intermedia",
                                "detail": "Beige, crema, durazno"
                              },
                              {
                                "id": "s4",
                                "name": "Piel clara oscura (oliva)",
                                "detail": "Oliva, bronce, trigueña, cobriza"
                              },
                              {
                                "id": "s5",
                                "name": "Piel oscura",
                                "detail": "Marrón, canela"
                              },
                              {
                                "id": "s6",
                                "name": "Piel muy oscura",
                                "detail": "Negra, ébano"
                              }
                            ]
                          },

                          {
                            "title": "Tus datos personales",
                            "type": "divisor"
                          },
                          {
                            "type": "line",
                            "inputs": [
                              {
                                "title": "Nombre",
                                "type": "text",
                                "name": "name",
                                "placeholder": ""
                              },
                              {
                                "title": "Apellido",
                                "type": "text",
                                "name": "surname",
                                "placeholder": ""
                              }
                            ]
                          },
                          {
                            "title": "Username",
                            "icon": "las la-at",
                            "type": "text",
                            "name": "username",
                            "placeholder": ""
                          },
                          {
                            "title": "Email",
                            "icon": "las la-envelope",
                            "type": "text",
                            "name": "email",
                            "placeholder": ""
                          },
                          {
                            "title": "Contraseña",
                            "icon": "las la-key",
                            "type": "password",
                            "name": "signature",
                            "placeholder": ""
                          },

                          {
                            "type": "checkbox",
                            "title": "Condiciones",
                            "name": "legal",
                            "label": "Acepto las condiciones",
                            "buttons": [
                              {
                                "text": "¿Dónde puedo leerlas?",
                                "type": "link",
                                "actions": [
                                  {
                                    "type": "redirect",
                                    "path": "https://inkonsky.com/legal?language=es"
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "type": "submit",
                            "value": "Crear",
                            "name": "submit"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'screen',
            path: ['manager/*', 'admin/*'],

            components: [
              {
                "type": "middleware",
                "conditions": [
                  {
                    "mode": "nonexist",
                    "type": "store",
                    "key": "user.user.type",
                  }
                ],
                actions: [
                  {
                    type: 'redirect',
                    path: '/',
                  },
                ],
              }
            ]
          },
          {
            "type": "middleware",
            "conditions": [
              {
                "mode": "is",
                "type": "store",
                "key": "user.user.type",
                "value": "client"
              }
            ],
            components: [
              {
                path: ['*'],
                type: 'retrieve',
                endpoint: '/api/artist/session',
                method: 'get',
                actions: {
                  failed: [
                    {
                      type: 'redirect',
                      path: '/',
                    },
                  ],
                },
                components: [
                  {
                    type: 'screen',
                    name: 'calendar',
                    path: ['manager/*', 'admin/*'],

                    styles: {
                      zIndex: 10,
                      display: 'flex',
                      flex: 1,
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      flexDirection: 'row',
                    },
                    components: [
                      ClientMenu,
                      ClientMenuPhone,

                      {
                        type: 'screen',
                        name: 'unauthorized',
                        styles: {
                          display: 'flex',
                          flex: 1,
                          overflowY: 'scroll',
                        },
                        components: [
                          {
                            styles: {
                              display: {
                                mobile: 'block',
                                default: 'none',
                              },
                            },
                            type: 'space',
                          },
                          {
                            type: 'screen',
                            styles: {
                              display: 'flex',
                              flex: 1,

                              flexDirection: 'row',
                            },
                            components: [
                              {
                                type: 'screen',
                                name: 'unauthorized',

                                components: [
                                  {
                                    type: 'space',
                                  },
                                  {
                                    path: '/manager/menu',
                                    type: 'button',
                                    icon: 'las la-arrow-left',
                                    text: 'Volver',
                                    styles: {
                                      display: {
                                        mobile: 'block',
                                        default: 'none',
                                      },
                                    },
                                  },
                                  ClientMeet,
                                  ClientAccount,
                                  {
                                    type: 'space',
                                  },
                                ],
                              },
                              {
                                type: 'space',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

}
