import ControlsDish from "./controls/controls"
import ConfigurationComponent from "./configuration/configuration.component"
import './sections.styles.scss'
import { Socket } from "socket.io-client"
import ChatComponent, { ChatMessageType } from "./chat/chat.component"
import { ConnectDeviceOptions, DishLogType, DishPermissions } from "../../dish.types"
import { useState } from "react"
import DishSectionDevicesComponent from "./configuration/devices/devices.component"
import DishLogComponent from "./log/log.component"
import { DeviceFilterType } from "../../functions/devices.instance"
import LayoutsDishComponent from "./layouts/devices.component"
interface SectionSettings {
    socketStatus?: string,
    finishCall: () => void,
    permissions?: DishPermissions,
    localScreams?: MediaStream[],
    messages?: ChatMessageType[],
    room?: string,
    logs?: DishLogType[],
    setAudioOutputDevice: (deviceId: string) => void,
    setLayout: (layout: string) => void,
    sendMessage: (message: string) => void
    audioOutputDevice?: string,
    getStreenMedia: () => void
    getAudioOutputDevices: () => Promise<MediaDeviceInfo[] | undefined>
    getDevices: (filter?: DeviceFilterType) => Promise<MediaDeviceInfo[] | undefined>
    connectDevice: (devices: { videoId: string } | { audioId: string } | { videoId: string, audioId: string }, options: ConnectDeviceOptions) => Promise<MediaStream | undefined>
}
export default function SectionComponent(settings: SectionSettings) {

    const [section, setSection] = useState<string | undefined>('devices')
    return <>
        <ControlsDish
            socketStatus={settings.socketStatus}
            setSection={setSection}
            section={section}
            finishCall={settings.finishCall}
            messagesCount={settings.messages?.length || 0}
            getStreenMedia={settings.getStreenMedia}
            logs={settings.logs}
            stopAudio={function (): void {

                throw new Error('Function not implemented.');

            }}
            audio={false} video={false} media={false} />
        {section === 'configuration' && <ConfigurationComponent permissions={settings.permissions} setSection={() => setSection('')} />}

        {

            section === 'devices' && <DishSectionDevicesComponent
                audioOutputDevice={settings.audioOutputDevice}
                setAudioOutputDevice={settings.setAudioOutputDevice}
                getStreenMedia={settings.getStreenMedia}
                getAudioOutputDevices={settings.getAudioOutputDevices}
                setSection={() => setSection('')} localScreams={settings.localScreams} getDevices={settings.getDevices} connectDevice={settings.connectDevice} />

        }
        {

            section === 'layouts' && <LayoutsDishComponent
                setLayout={(layout: string) => {

                    settings.setLayout(layout)
                    setSection(undefined)

                }}
                setSection={() => setSection('')} />

        }
        {
            section === 'log' && <DishLogComponent logs={settings.logs} setSection={() => setSection('')} />
        }
        {section === 'chat' && <ChatComponent room={settings.room} setSection={() => setSection('')} messages={settings.messages} sendMessage={(message: string) => {

            settings.sendMessage(message)

        }} />}
    </>

}