import './index.scss';

// Function login props redux connect
import { setRoute, setStore } from '../../../store/actions'
import { connect } from 'react-redux';
import Stylize from '../../../stylize';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ExecuteActions from '../../../actions';
import TooltipComponent from '../tooltip/tooltip.component';

const mapDispatchToProps = {
    setStore,
    setRoute
}
const mapStateToProps = (state: any, ownProps: any) => (state)

export interface ButtonComponentProps {
    setRoute: (s: any) => void
    setStore: (s: any) => void
    store: any,
    text?: string,
    icon?: string,
    children?: JSX.Element | JSX.Element[],
    bubble?: string,
    route?: string,
    item?: any,
    virgin?: boolean,
    title?: string,
    path?: string,
    tooltip?: string,
    actions: any,
    styles?: any,
    development?: {
        status?: 'complete'
    }
    onPress?: () => void
}
type Props = ButtonComponentProps

function LayoutButtonComponent(props: Props) {

    const navigation = useNavigate()
    const location = useLocation();
    const locationParams = useParams();

    // Is active path in location start
    const isActive = props?.path && location?.pathname?.startsWith(props?.path);
    return (

        <Stylize className='LayoutButtonComponent' style={props.styles}>

            <div className={`button ${isActive && 'active'}`} data-tip={props.tooltip} onClick={(e) => {

                e.preventDefault();
                e.stopPropagation();
                props.path && navigation(props.path);
                ExecuteActions({
                    item: props.item,
                    store: props.store,
                    params: locationParams,
                    commands: {
                        store: props.setStore,
                        navigation: navigation
                    }, actions: props.actions
                })

            }}>

                {props.icon && <div className={`icon ${props.icon}`} />}
                {props.text}
                {props.bubble && <div className='bubble'>{props.bubble}</div>}
                {props.development && <div className={`development ${props.development.status}`}></div>}
                {props.children}
            </div>
            <TooltipComponent />
        </Stylize>

    );

}
export default connect(mapStateToProps, mapDispatchToProps)(LayoutButtonComponent)
