export const environment = {
  production: true,
  debug: false,
  brickter: {
    name: 'inkonsky',
  },
  api: {
    host: 'https://api.inkonsky.dev',
  },
};
