import { CalendarSettings } from "../..";
import { Replace } from "../../../../../utilities/replace/replace.utility";
import Moment from "moment";
import './event.styles.scss'
import ReactMarkdown from "../../../markdown";
export default function CalendarEventCube(props: { onDelete: (item: any) => void, onEdit: (item: any) => void, layoutWidth?: number, hourHeight: number, settings: CalendarSettings, event: any }) {

    let layoutWidth = 230;
    if (props.layoutWidth) layoutWidth = props.layoutWidth
    console.log('evento:', props.event)
    // get minutes between start and finish
    const collisionLeft = () => {

        let collision = props.event.collision;
        let collisionStart = props.event.collision;
        let collisionReverse = props.event.collision;
        const stepCollision = 3;
        if (collision === 3) {

            collisionStart = 0;
            collisionReverse = 2;

        }
        if (collision === 4) {

            collisionStart = 1;
            collisionReverse = 1;

        }
        if (collision === 5) {

            collisionStart = 2;
            collisionReverse = 0;

        }
        if (collision === 6) {

            collisionStart = 3;
            collisionReverse = 3;

        }
        if (collision === 7) {

            collisionStart = 0;
            collisionReverse = 3;

        }
        return {
            left: 10 + 50 * collisionStart,
            width: layoutWidth - (50 * collisionReverse)
        }

    }

    const { left, width } = collisionLeft();
    // format yyyy-mm-dd
    const dateOfEvent = props.event.start.toISOString();
    const startRender = Moment(props.event.start).format('H:mm');
    const endRender = Moment(props.event.end).format('H:mm');

    return <div
        onClick={() => {

            props.onEdit(props.event)

        }}
        className='CalendarEventCube'
        style={{

            width: width,
            minHeight: props.event.height,
            maxHeight: props.event.height,

            top: props.event.top,
            background: props.event.color,
            left: left
        }}>

        <div className="subject" style={{
            background: props.event.color,
        }}>
            <b>{
                props.event.subject
            }</b>
            <div className="clock" style={{
                background: props.event.color,
            }}>
                {
                    startRender + ' - ' + endRender
                }
            </div>
        </div>

        <div className="description">
            {props.event?.description && props.event?.description !== null && <ReactMarkdown>
                {
                    props.event?.description
                }
            </ReactMarkdown>}
        </div>
        <div className="bubble" style={{
            background: props.event.color,
        }}>
            {
                props.event?.bubble
            }
            <span>
                Tiene {props.event.count} eventos este dia
            </span>
        </div>
        <div className="controls">
            <i className="las la-pen" onClick={(e) => {

                e.stopPropagation();
                props.onEdit(props.event)

            }}></i>
            <i className="las la-trash" onClick={(e) => {

                e.stopPropagation();
                props.onDelete(props.event)

            }}></i>
        </div>

    </div>

}