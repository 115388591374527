import { environment } from "../../../../environments/environment";
import { getProperty } from "dot-prop";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import RequestAction from "../../../actions/request/request.action";
import EndpointComponent from "../../development/endpoint/endpoint.component";
import LoaderComponent from "../loader/loader.component";
import LayoutScreenComponent from "../screen";
import { Replace } from "../../../utilities/replace/replace.utility";
import ExecuteActions from "../../../actions";
import { setStore } from "../../../store/actions";
const mapDispatchToProps = {
    setStore,
}
interface RetrieveProperties {
    store: any,
    false?: any,
    endpoint: string,
    method: string,
    components: any,
    actions: any,
    setStore: (s: any) => void,
}
function RetrieveComponent(props: RetrieveProperties) {

    // Get param route dom v6
    const location = useParams();
    const navigation = useNavigate()

    const [item, setItem] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {

        if (props?.store?.user) {

            setLoading(true);

            const endpoint = Replace({ params: location }, props.endpoint)

            const currentUserToken = getProperty(props.store, 'user.access_token');

            RequestAction({
                endpoint: endpoint,
                method: props.method, headers: {
                    'authorization': 'Bearer ' + currentUserToken
                }
            }).then((response) => {

                setTimeout(() => {

                    setItem(response.data.items[0])
                    setLoading(false);

                }, 1000);

            }).catch((error) => {

                if (props.actions && props.actions.failed) {

                    ExecuteActions({
                        commands: {
                            store: props.setStore,
                            navigation: navigation
                        }, actions: props.actions.failed
                    })

                }

            })

        }
        else {

            setItem(undefined)
            setLoading(false);

        }

    }, [props?.store?.user])
    return (
        <>
            {
                environment.debug && <EndpointComponent action="Retrieve" endpoint={props.endpoint} method={props.method} />
            }

            <LoaderComponent status={loading} />
            {
                !loading && item && <LayoutScreenComponent item={item} components={props?.components} />

            }
            {
                !loading && !item && props?.false && <LayoutScreenComponent components={props?.false} />

            }
        </>
    )

}

const mapStateToProps = (state: any, ownProps: any) => (state)

export default connect(mapStateToProps, mapDispatchToProps)(RetrieveComponent)
