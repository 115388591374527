import Moment from 'moment'
import { CalendarSettings } from '../../..'
import { GetCollisionEvents } from '../../../utilities/collision.utility'
import CalendarEventCube from '../../event/event.component'
import LayoutCalendarTimelineView from '../../timeline/timelineView'
import CalendarWeekHeader from '../header/header.week'
import CalendarWeekTimeline from '../timeline/timeline.week'
import './body.styles.scss'
export default function CalendarWeekBody(props: {

    onDelete: (item: any) => void,
    onNew: (start: Date, end: Date) => void,
    daySelected: Date, events: any[], settings: CalendarSettings, height: number, onEdit: (item: any) => void,
}) {

    const hourHeight = 100;
    const eventsByDay = GetCollisionEvents(props.events, props.settings)

    const days = 7
    return <div className="CalendarWeekBody">
        <div className='scroll' style={{ maxWidth: (250 * 7) + 60 + 60 }}>

            <CalendarWeekHeader daySelected={props.daySelected} events={eventsByDay} />
            {props.height && <CalendarWeekTimeline />}
            {props.height && <LayoutCalendarTimelineView />}
            {
                [...Array(days)].map((_, indexDay) => {

                    const day = Moment(props.daySelected).add(indexDay, 'days').toDate()
                    // get number of month
                    const dayNumber = day.getDate()
                    // get day:
                    return <div className="day" key={indexDay} style={{
                        height: 24 * 100
                    }}>
                        {
                            [...Array(24)].map((_, indexHour) => {

                                return (
                                    <div className="hour" key={indexHour} style={{ height: 100, top: indexHour * hourHeight }}
                                        onClick={
                                            () => {

                                                props.onNew(
                                                    new Date(day.getFullYear(), day.getMonth(), dayNumber, indexHour, 0, 0),
                                                    new Date(day.getFullYear(), day.getMonth(), dayNumber, indexHour + 1, 0, 0)
                                                )

                                            }
                                        }
                                    >
                                        <i className='las la-plus'></i>
                                        <b>{indexHour + ':00'
                                        }
                                            <span>{
                                                day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + dayNumber}</span></b>
                                    </div>)

                            }
                            )
                        }
                        <div className='day-events'>
                            {
                                eventsByDay?.[dayNumber]?.map((event: any, index: number) => {

                                    return <CalendarEventCube
                                        onDelete={props.onDelete}
                                        onEdit={props.onEdit}
                                        settings={props.settings} event={event} hourHeight={hourHeight} key={index} />

                                })
                            }
                        </div>
                    </div>

                })
            }
            {props.height && <CalendarWeekTimeline />}

        </div>
    </div >

}