
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
// import ParticipantsModal from "../components/ParticipantsModal";

import Peer from "simple-peer";

const ChatRoom = ({ chatRoom, me, socket }: any) => {

    const [stream, setStream] = useState<any>(null);
    const [receivingCall, setReceivingCall] = useState(false);
    const [caller, setCaller] = useState("");
    const [callerSignal, setCallerSignal] = useState<any>();
    const [callAccepted, setCallAccepted] = useState(false);
    const [callEnded, setCallEnded] = useState(false);
    const [name, setName] = useState("");
    const [idToCall, setIdToCall] = useState("");
    const [loading, setLoading] = useState(false);

    const [chat, setChat] = useState(true);
    const [modal, setModal] = useState(true);
    const [mute, setMute] = useState(false);
    const [stopVideo, setStopVideo] = useState(false);

    const myVideo = useRef<any>();
    const userVideo = useRef<any>();
    const connectionRef = useRef<any>();

    useEffect(() => {

        console.log(222)
        navigator.mediaDevices
            .getUserMedia({ video: true, audio: true })
            .then((currentStream: any) => {

                setStream(currentStream);
                console.log('es:', myVideo)
                myVideo.current.srcObject = currentStream;

            });
        console.log(123, socket)
        socket.on("calluser", ({ from, name, signal }: any) => {

            alert('llamada')

            setReceivingCall(true);
            setCaller(from);
            setName(name);
            setCallerSignal(signal);

        });

    }, [socket]);

    const callUser = (id: string) => {

        alert(21)

        setLoading(true);
        const peer = new Peer({
            initiator: true,
            trickle: false,
            stream: stream,
        });

        peer.on("signal", (data) => {

            socket.emit("calluser", {
                userToCall: id,
                signalData: data,
                from: me,
                name: name,
            });

        });

        peer.on("stream", (stream: any) => {

            userVideo.current.srcObject = stream;

        });

        socket.on("callaccepted", ({ signal }: any) => {

            setCallAccepted(true);
            setModal(false);
            peer.signal(signal);

        });

        connectionRef.current = peer;

    };

    const answerCall = () => {

        setCallAccepted(true);
        setModal(false);
        const peer = new Peer({
            initiator: false,
            trickle: false,
            stream: stream,
        });

        peer.on("signal", (data) => {

            socket.emit("answercall", { signal: data, to: caller });

        });

        peer.on("stream", (stream) => {

            userVideo.current.srcObject = stream;

        });

        peer.signal(callerSignal);
        connectionRef.current = peer;

    };

    const leaveCall = () => {

        setCallEnded(true);
        connectionRef.current.destroy();
        window.location.reload();

    };

    const handleClick = () => {

        setModal(false);
        setIdToCall("");
        setName("");

    };

    const muteUnmute = () => {

        const enabled = stream.getAudioTracks()[0].enabled;
        if (enabled) {

            stream.getAudioTracks()[0].enabled = false;
            setMute(true);

        } else {

            stream.getAudioTracks()[0].enabled = true;
            setMute(false);

        }

    };

    const playStopVideo = () => {

        const enabled = stream.getVideoTracks()[0].enabled;
        if (enabled) {

            stream.getVideoTracks()[0].enabled = false;
            setStopVideo(true);

        } else {

            stream.getVideoTracks()[0].enabled = true;
            setStopVideo(false);

        }

    };
    return (
        <div>
            <div>
                <div>
                    <video playsInline ref={myVideo} muted autoPlay />

                    <video playsInline ref={userVideo} autoPlay />

                </div>
                {chatRoom === "host" ? (
                    <div >
                        <div>
                            <div>
                                <span>Participants(1)</span>
                                <div onClick={() => setModal(false)} />
                            </div>
                            <div>xx
                                {receivingCall && !callAccepted && (
                                    <div>
                                        <span>{name}</span>
                                        <div onClick={answerCall}>Accept</div>
                                    </div>
                                )}
                            </div>
                            <div>
                                <span>Room ID: {me}</span>

                            </div>
                        </div>
                    </div>
                ) : (
                    <div >
                        <div>
                            {loading ? (
                                'cargando'
                            ) : (
                                <>
                                    <h2>Join Room</h2>
                                    <div>
                                        <input
                                            placeholder="Room ID"
                                            value={idToCall}
                                            onChange={(e: any) => setIdToCall(e.target.value)}
                                        />
                                        <input
                                            placeholder="Name"
                                            value={name}
                                            onChange={(e: any) => setName(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <div onClick={handleClick}>
                                            Cancel
                                        </div>
                                        <div

                                            onClick={() => callUser(idToCall)}
                                        >
                                            {!(idToCall && name) ? ' no unir' : 'unir'}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                )}
                <div>
                    <div>
                        <div onClick={muteUnmute}>
                            {mute ? (
                                <span style={{ color: "#d40303" }}>Unmute</span>
                            ) : (
                                <span>Mute</span>
                            )}
                        </div>
                        <div onClick={playStopVideo}>
                            {stopVideo ? (
                                'video of'
                            ) : (
                                'video on'
                            )}
                            {stopVideo ? (
                                <span style={{ color: "#d40303" }}>Play Video</span>
                            ) : (
                                <span>Stop Video</span>
                            )}
                        </div>
                    </div>
                    <div>

                        <div onClick={() => setModal(!modal)}>

                            <span>Participants</span>
                        </div>
                        <div onClick={() => setChat(!chat)}>
                            <span>Chat</span>
                        </div>
                    </div>
                    <div
                        onClick={idToCall ? leaveCall : () => window.location.reload()}
                    >
                        <span>{idToCall ? "Leave Meeting" : "End Meeting"}</span>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default ChatRoom;
