import React, { useEffect, useRef } from "react"
import { connect } from "react-redux"
import {
    BrowserRouter,
    Routes,
    Route,
    useLocation,
    useNavigate,
    useParams,
    Navigate,
} from "react-router-dom";
import Stylize from "../../../stylize"
import CalenportComponent from "../calenport"
import LayoutBarComponent from "../bar"
import LayoutBreadcrumbsComponent from "../breadcrumb"
import LayoutButtonComponent from "../button"
import LayoutCalendarComponent from "../calendar"
import ChartComponent from "../chart/chart.component"
import DishComponent from "../dish/dish.component";
import MeetComponent from "../meet/meet.component";

import LayoutDivisorComponent from "../divisor"
import LayoutFormComponent from "../form"
import LayoutGalleryComponent from "../gallery"
import LayoutHeaderComponent from "../header"
import LayoutLogotypeComponent from "../logotype"
import LayoutMapComponent from "../map"
import LayoutPosComponent from "../pos"
import LayoutSplashComponent from "../splash"
import LayoutArticleComponent from "../article"
import LayoutTableComponent from "../table"
import LayoutTextComponent from "../text"
import MiddlewareComponent from "../middleware"
import ImporterComponent from "../calenport"
import LayoutVersionComponent from "../version"
import './index.scss'
import LayoutWidgetComponent from "../widget";
import RetrieveComponent from "../retrieve";
import IconComponent from "../icon";
import { Replace } from "../../../utilities/replace/replace.utility";
import LayoutBoxComponent from "../box";
import { Helmet } from "react-helmet";
import CheekyComponent from "../cheeky/cheeky.component";
import LayoutBackgroundComponent from "../background";
import LayoutControlComponent from "../control";
import ConstructorComponent from "../contrustor/constructor.component";
import TicketComponent from "../ticket/ticket.component";
const mapStateToProps = (state: any, ownProps: any) => (state)

type DispatchProps = {
    title?: string,
    favicon?: string,
    route?: string,
    path?: string | string[],
    mode?: 'Modal',
    name: string,
    item: any,
    direct?: boolean,
    styles?: any,
    components: any[]
    outside?: {
        path: string
    }
}
type Props = DispatchProps

function LayoutScreenRender(props: Props) {

    let mode: string = "";
    if (props?.mode) {

        // uppercase first letter
        mode = props?.mode.charAt(0).toUpperCase() + props?.mode.slice(1);

    }
    const wrapperRef = useRef(null);
    const navigate = useNavigate()
    // Get params path
    const location = useParams();
    const outsideActivated = (props.outside && (mode === 'Modal')) ? true : false;
    useOutsideAlerter(outsideActivated,
        wrapperRef,
        (event: any) => {

            if (mode === 'Modal') {

                if (props?.outside) {

                    event.preventDefault();
                    event.stopPropagation();
                    const path = Replace({ params: location, }, props?.outside?.path)
                    navigate(path)

                }

            }

        });

    const renderComponent = (component: any, index: number) => {

        return <React.Fragment key={index} >
            {component.type === 'button' && <LayoutButtonComponent {...component} />}
            {component.type === 'text' && <LayoutTextComponent item={props.item}  {...component} />}
            {component.type === 'icon' && <IconComponent item={props.item}  {...component} />}
            {component.type === 'divisor' && <LayoutDivisorComponent {...component} />}
            {component.type === 'screen' && <LayoutScreenComponent name={component?.name} path={component?.path} outside={component?.outside} components={component?.components}
                styles={component?.styles} mode={component?.mode} title={component?.title} favicon={component?.favicon} />}
            {component.type === 'header' && <LayoutHeaderComponent {...component} />}
            {component.type === 'calendar' && <LayoutCalendarComponent {...component} />}
            {component.type === 'logotype' && <LayoutLogotypeComponent {...component} />}
            {component.type === 'calenport' && <CalenportComponent {...component} />}
            {component.type === 'version' && <LayoutVersionComponent {...component} />}
            {component.type === 'table' && <LayoutTableComponent {...component} />}
            {component.type === 'background' && <LayoutBackgroundComponent {...component} />}
            {component.type === 'breadcrumbs' && <LayoutBreadcrumbsComponent {...component} />}
            {component.type === 'form' && <LayoutFormComponent {...component} item={props.item} />}
            {component.type === 'splash' && <LayoutSplashComponent {...component} />}
            {component.type === 'article' && <LayoutArticleComponent {...component} />}
            {component.type === 'bar' && <LayoutBarComponent {...component} />}
            {component.type === 'map' && <LayoutMapComponent {...component} />}
            {component.type === 'pos' && <LayoutPosComponent {...component} />}
            {component.type === 'gallery' && <LayoutGalleryComponent {...component} />}
            {component.type === 'chart' && <ChartComponent {...component} />}
            {component.type === 'constructor' && <ConstructorComponent {...component} />}
            {component.type === 'dish' && <DishComponent {...component} />}
            {component.type === 'meet' && <MeetComponent {...component} />}
            {component.type === 'checky' && <CheekyComponent {...component} />}
            {component.type === 'widget' && <LayoutWidgetComponent {...component} item={props.item} />}
            {component.type === 'control' && <LayoutControlComponent {...component} item={props.item} />}
            {component.type === 'box' && <LayoutBoxComponent {...component} item={props.item} />}
            {component.type === 'middleware' && <MiddlewareComponent {...component} />}
            {component.type === 'retrieve' && <RetrieveComponent {...component} />}
            {component.type === 'ticket' && <TicketComponent {...component} item={props.item} />}

            {component.type === 'separation' && <Stylize className="Separation" style={component?.styles} />}
            {component.type === 'space' && <Stylize className="Space" style={component?.styles} />}
            {component.type === 'redirect' && <Navigate to="/" replace />}
        </React.Fragment>

    }
    const renderComponents = (components: any) => {

        return components?.map((component: any, componentIndex: number) => {

            return renderComponent(component, componentIndex)

        })

    }
    const renderScene = () => {

        let mode: string = "";
        if (mode) {

            // uppercase first letter
            mode = mode.charAt(0).toUpperCase() + mode.slice(1);

        }
        return <Stylize className={
            `LayoutScreenComponent ${mode ? `LayoutScreen${mode}Component` : ''
            } `} style={props?.styles}>
            {
                renderHelmet()

            }
            {renderComponents(props?.components)}
        </Stylize>

    }

    const renderHelmet = () => {

        return <>
            {
                props.title && <Helmet>
                    <title>{props.title}</title>
                </Helmet>
            }
            {
                // Favicon
                props.favicon && <Helmet>
                    <link rel="icon" type="image/png" href={props.favicon} />

                </Helmet>
            }
        </>

    }
    let content = <div>sin contenido</div>;

    if (props?.direct) content = renderComponents(props?.components)
    else content = props.path ?
        <Routes>

            {
                typeof props.path === 'string' ?
                    <Route path={props.path} element={
                        <Stylize refOutside={wrapperRef} className={
                            `LayoutScreenComponent ${mode ? `LayoutScreen${mode}Component` : ''
                            } `} style={props?.styles}>
                            {
                                renderHelmet()

                            }
                            {renderComponents(props?.components)}
                        </Stylize>
                    } /> : props.path.length > 0 ? props.path.map((path, index) => {

                        return <Route
                            key={index}
                            path={path}
                            element={

                                <Stylize refOutside={wrapperRef} className={
                                    `LayoutScreenComponent ${mode ? `LayoutScreen${mode}Component` : ''
                                    } `} style={props?.styles}>
                                    {
                                        renderHelmet()

                                    }
                                    {renderComponents(props?.components)}
                                </Stylize>
                            } />

                    }) : null

            }
        </Routes > : renderScene()

    return content;

}
const LayoutScreenComponent = connect(mapStateToProps,)(LayoutScreenRender)
export default LayoutScreenComponent

function useOutsideAlerter(activate: boolean, ref: any, callBack: any) {

    useEffect(() => {

        function handleClickOutside(event: any) {

            if (ref.current && !ref.current.contains(event.target)) {

                callBack(event)

            }

        }
        if (activate) {

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);

        }

        return () => {

            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);

        };

    }, [activate, ref, callBack]);

}