import { getProperty } from 'dot-prop';
import { ActionsType } from './types';

interface Props {
  data?: any;
  item?: any;
  store?: any,
  commands: any;
  params?: any;
  actions: ActionsType[];
}
export default function ExecuteActions(props: Props) {

  // Get redux dispatch
  if (props.actions) {

    props.actions.forEach(async (action) => {

      if (action.type === 'reload') {

        window.location.reload();

      }
      if (action.type === 'redirect' || action.type === 'open') {

        let path = action.path;
        // If finish /
        if (path[path.length - 1] !== '/') {

          path += '';

        }

        // // check if have {id}
        // If (props.params) {
        //     // get keys and replace
        //     Object.keys(props.params).forEach(key => {
        //         Path = path.replace(`{param.${key}}`, props.params[key]);
        //     })
        // }
        // If (props.data) {

        //     Object.keys(props.data).forEach(key => {
        //         Path = path.replace(`{data.${key}}`, props.data[key]);
        //     })
        // }
        // If (props.item) {
        //     // replace properties of object:
        //     // explode all {string} in path
        const keys = path.match(/{[a-zA-Z0-9_.]+}/g);
        if (keys) {

          keys.forEach((key) => {

            const key_ = key.replace(/[{}]/g, '');
            if (key_) {

              const value = getProperty(
                { ...props, param: props.params, store: props.store },
                key_
              );

              if (value) {

                path = path.replace(key, value);

              }

            }

          });

        }

        // Add ? if dont have
        if (path.indexOf('?') === -1 && action.cache) {

          path += '?';

        }
        const randomStringSmall =
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15);
        if (action.cache) path = `${path}cache=${randomStringSmall}`;

        // Have http
        if (path.indexOf('http') === 0) {

          // Open in new tab
          if (action.type === 'open') {

            // open
            window.open(path, "_self")

          }
          else window.open(path, '_blank');

        }
        else props.commands.navigation(path);

      } else if (action.type === 'store') {

        if (action.action === 'delete') {

          const data: any = {};
          data[action.path] = { remove: true };
          await props.commands.store(data);

        } else {

          const data: any = {};
          data[action.path] = props.item;
          await props.commands.store(data);

        }

      }
      return true;

    });

  }

}
