import moment from 'moment';
import { CalendarSettings } from '../../..';
import { Replace } from '../../../../../../utilities/replace/replace.utility';
import { getEventDate } from '../../../utilities/event.properties.utility';
import './day.month.calendar.scss';
export default function DayMonthCalendar(props: {
    settings: CalendarSettings
    events: any[];
    setDateSelected: (date: Date) => void;
    disabled: boolean;
    date: Date, widthCalendar: number, key: number,
    onNew: (start: Date, end: Date) => void,
    onEdit: (item: any) => void,
}) {

    // check is today:
    const dateOfCube = moment(props.date);  // 2009-11-10

    const today = new Date();
    // is today
    const isToday = dateOfCube.isSame(today, 'day');

    // is same month
    const isSameMonth = dateOfCube.isSame(today, 'month');
    const width = (props.widthCalendar / 7);

    // get events with date:
    const eventsSelecteds: any = []
    props.events.map((event: any) => {

        const dateOfEvent = new Date(Replace({ item: event }, props.settings.event.startedDateSelector));

        // check if is same day
        const isSameDay = dateOfCube.isSame(dateOfEvent, 'day');
        if (isSameDay) {

            eventsSelecteds.push({
                start: dateOfEvent,
                subject: Replace({ item: event }, props.settings.event.subjectSelector),
                bubble: Replace({ item: event }, props.settings.event.bubbleSelector),
                color: props.settings.event.colorSelector && Replace({ item: event }, props.settings.event.colorSelector),
                id: event.id,
            });

        }

    });
    return <div className={`DayMonthCalendar ${isToday && 'today'} ${isSameMonth && 'sameMonth'} ${props.disabled && 'disabled'}`} key={props.key} style={{
        width: width,
        height: width
    }}>
        <div className='number' onClick={() => {

            props.setDateSelected(props.date);

        }}>{
                dateOfCube.format('D')
            }

        </div>
        <i className='add las la-plus' onClick={() => {

            props.onNew(props.date, props.date);

        }} ></i>
        {
            !props.disabled && <>

                <div className='events'>
                    {
                        eventsSelecteds && eventsSelecteds.map((event: any, index: number) => {

                            return <div className='event' key={index} onClick={(e) => {

                                e.stopPropagation();
                                props.onEdit(event)

                            }}>

                                <div className='details'>
                                    <div className='title'>
                                        <div className='hour'>{('0' + event.start.getHours()).substr(-2)}:{
                                            ('0' + event.start.getMinutes()).substr(-2)}</div>
                                        <div className='subject'>{event.subject}</div>
                                        <div className='type' style={{
                                            background: event.color
                                        }}>{event.bubble}</div>
                                    </div>
                                </div>
                            </div>

                        })
                    }
                </div></>}
    </div>

}