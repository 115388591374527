
// Function login props redux connect
import RequestAction from '../../../../../actions/request/request.action';
import { Replace } from '../../../../../utilities/replace/replace.utility';
import { getProperty } from 'dot-prop';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Compressor from 'compressorjs';

import './index.scss';

interface DispatchProps {
    name: string,
    type: 'google-storage'
    placeholder: string,
    store: any,
    endpoint?: string,
    bucket: string,
    mode?: string,
    icon?: string,
    setData: (s: any) => void
    defaultValue?: string,
    compress?: {
        width?: number,
        height?: number,
        quality?: number
    }
}

function FormGoogleStorage(props: DispatchProps) {

    const inputRef = useRef<HTMLInputElement>(null);
    const [defaultValue, setDefaultValue] = useState<string>('');
    const [url, setUrl] = useState<string>('');
    const [path, setPath] = useState<string>('');
    const [cache, setCache] = useState<number>(0);
    const [percentage, setPercentage] = useState<number>(0);
    const paramsUlr = useParams()
    useEffect(() => {

        if (props.endpoint) {

            const currentUserToken = getProperty(props.store, 'user.access_token');
            const path = props.endpoint
            // Check if have ?
            RequestAction({
                endpoint: `${path}`,
                method: 'get',
                headers: {
                    'authorization': 'Bearer ' + currentUserToken
                }
            }).then((response) => {

                setUrl(response.data.items[0].url);
                setPath(response.data.items[0].path);

            }
            ).catch((error) => {
                //
            });

        }

    }, [props.endpoint])
    useEffect(() => {

        if (props.defaultValue) {

            const defaultValue = Replace({ param: paramsUlr }, props.defaultValue);
            setDefaultValue(defaultValue);
            props.setData(defaultValue);

        }

    }, [])
    // Accept={input.accept} 
    // Name={input.name}
    // Data-compress={input.compress} 
    // Remove all ?
    const routeImage = url.split('?')[0];

    return (
        <div className='LayoutFormInputComponent'>
            {
                defaultValue ? <div className='image'>
                    {
                        props.mode === 'avatar' ? <div className='avatar' style={{ backgroundImage: `url(${props.bucket}${defaultValue})` }}>
                            <i className='la la-times' onClick={() => {

                                setDefaultValue('');

                            }} />
                        </div> : <>
                            <i className='la la-times' onClick={() => {

                                setDefaultValue('');

                            }} />
                            <img src={`${props.bucket}${defaultValue}`} />
                        </>
                    }

                </div> : <>

                    {
                        percentage === 100 && routeImage ? <div className='image'>
                            {
                                props.mode === 'avatar' ? <div className='avatar' style={{ backgroundImage: `url(${routeImage}?cache=${cache})` }}>
                                    <i className='la la-times' onClick={() => {

                                        setPercentage(0);

                                    }} />
                                </div> : <>
                                    <i className='la la-times' onClick={() => {

                                        setPercentage(0);

                                    }} />
                                    <img src={`${routeImage}?cache=${cache}`} />
                                </>
                            }
                        </div> : null
                    }
                    {
                        percentage === 100 ? null : <>

                            <div className='input' onClick={() => {

                                inputRef?.current?.click();

                            }}  >
                                <i className='icon las la-cloud-upload-alt'></i>

                                {
                                    percentage ? <div className='percentage'>{percentage}%</div> : 'Selecciona una imagen'
                                }
                            </div>
                            {url && <div className='finder'>

                                <input type="file"
                                    accept='image/*'
                                    ref={inputRef}
                                    onChange={(e: any) => {

                                        const that: any = e.target;
                                        that.parentNode.classList.add('finder-loading');

                                        const image = that.files[0];
                                        new Compressor(image, {
                                            quality: props?.compress?.quality || 0.8,
                                            maxWidth: props?.compress?.width || 1500,
                                            maxHeight: props?.compress?.height || 1500,
                                            mimeType: 'image/jpeg',
                                            success: (compressedResult) => {

                                                const formData = new FormData();
                                                formData.append('key', 'HOLA');
                                                formData.append('file', compressedResult);

                                                const xhr = new XMLHttpRequest();

                                                xhr.open('PUT', url, true);
                                                xhr.setRequestHeader('Content-Type', compressedResult.type);
                                                // Access-Control-Allow-Origin': '*'
                                                xhr.upload.addEventListener("progress", function (event) {

                                                    if (event.lengthComputable) {

                                                        const complete = (event.loaded / event.total * 100 | 0);
                                                        setPercentage(complete)

                                                    }

                                                });
                                                xhr.onload = function () {

                                                    setTimeout(() => {

                                                        that.parentNode.classList.remove('finder-loading');
                                                        props.setData(path);
                                                        setCache(Math.random());

                                                    }, 1000);

                                                }
                                                xhr.send(compressedResult);

                                            },
                                        });

                                    }

                                    } />

                            </div>} </>}</>}
        </div>

    );

}

const mapStateToProps = (state: any, ownProps: any) => (state)

export default connect(mapStateToProps,)(FormGoogleStorage)
