import { DishBackgroundVideo } from "../components/background";
import { segmentBackground, applyBlur, applyImageBackground } from 'virtual-bg';
import { ConnectDeviceOptions } from "../dish.types";

interface SocketInstanceInterface {

}
type DishPermissions = {
    audio: boolean;
    video: boolean;
    media: boolean;
}

export type DeviceFilterType = {
    audio?: boolean;
    video?: boolean | {
        pan: true, tilt: true, zoom: true
    };
}

export class DeviceInstance {

    constructor() {

    }

    async replaceBackground(stream: MediaStream, image: string) {

        return new Promise<MediaStream>((resolve, reject) => {

            const background = new Image();

            background.src = image;
            console.log('image:')
            console.log(background)
            console.log(image)
            console.log
            background.onload = async () => {

                alert(background.width)
                const segmentation = new DishBackgroundVideo({ stream: stream, background });
                segmentation.start();
                const streamResult = await segmentation.getStream();

                return resolve(streamResult);

            }

        });

    }

    async getDefaultDevice() {

        // 60 fps
        const localStream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: {
                frameRate: 30,
                echoCancellation: true,
                noiseSuppression: true,

            }
        });

        return localStream;

    }

    async getDevices(filter?: DeviceFilterType) {

        const devices = await navigator.mediaDevices.enumerateDevices();
        console.log('Get devices', devices)
        const filteredDevices = devices.filter((device) => {

            if (filter) {

                console.log('tipe: ', device.kind)
                if (filter.audio && device.kind === 'audioinput') {

                    return true;

                }
                if (filter.video && device.kind === 'videoinput') {

                    console.log('bien!')
                    return true;

                }

            }
            return false;

        });
        return filteredDevices;

    }

    async getDeviceMedia(devices: { videoId: string } | { audioId: string } | { videoId: string, audioId: string }, options?: ConnectDeviceOptions) {

        let deviceAudio: MediaDeviceInfo | undefined = undefined
        let deviceVideo: MediaDeviceInfo | undefined = undefined

        if ('videoId' in devices) {

            // find device of video or audio
            const videoDevices = await this.getDevices({ video: true });
            deviceVideo = videoDevices.find((device) => device.deviceId === devices.videoId);
            if (!deviceVideo) {

                throw new Error('Device not found');

            }

        }
        if ('audioId' in devices) {

            // find device of video or audio
            const audioDevices = await this.getDevices({ audio: true });
            deviceAudio = audioDevices.find((device) => device.deviceId === devices.audioId);
            if (!deviceAudio) {

                throw new Error('Device not found');

            }

        }

        // if is audio
        if ('audioId' in devices && 'videoId' in devices) {

            const localStream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    deviceId: devices.audioId,
                    noiseSuppression: options?.noise,
                    echoCancellation: options?.echo,
                },
                video: {
                    deviceId: devices.videoId,
                    frameRate: 30,
                    noiseSuppression: options?.noise,
                    echoCancellation: options?.echo,
                    // @ts-ignore
                    ...options.ptz && {
                        pan: true,
                        tilt: true,
                        zoom: true
                    }
                },
            });

            if (options?.background) {

                const streamBackground = await this.replaceBackground(localStream, options?.background);
                return streamBackground;

            }
            else return localStream;

        }
        else if (deviceAudio && 'audioId' in devices) {

            const localStream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    deviceId: devices.audioId,
                    noiseSuppression: options?.noise,
                    echoCancellation: options?.echo,
                },
                video: false,
            });

            return localStream;

        } if (deviceVideo && deviceVideo.kind === 'videoinput' && 'videoId' in devices && deviceAudio === undefined) {

            const localStream = await navigator.mediaDevices.getUserMedia({
                audio: false,
                video: {
                    deviceId: devices.videoId,
                    frameRate: 30,
                    echoCancellation: options?.echo,
                    noiseSuppression: options?.noise,
                    // @ts-ignore
                    ...options.ptz && {
                        pan: true,
                        tilt: true,
                        zoom: true
                    }
                },
            });

            if (options?.background) {

                return await this.replaceBackground(localStream, options?.background);

            }
            else return localStream;

        }

    }

    async getScreenMedias() {

        const stream = await navigator.mediaDevices.getDisplayMedia({
            video: true,
            audio: true,
        });

        return stream;

    }

    // permissions video, audio and screen share
    async getPermissions() {

        const permissions = {
            audio: false,
            video: false,
            media: false,
        };
        // @ts-ignore
        await navigator.getUserMedia({ video: true }, function (stream) {

            stream.getTracks().forEach((x: any) => x.stop());

        }, (err: any) => console.log(err));
        // permission video
        // @ts-ignore
        await navigator.getUserMedia({ audio: true }, function (stream) {

            stream.getTracks().forEach((x: any) => x.stop());

        }, (err: any) => console.log(err));

        return permissions;

    }

    log(message: any, data?: any) {

        console.log(`[devices] ${message}`);

    }

    async getAudioOutput() {

        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioOutput = devices.filter((device) => device.kind === 'audiooutput');
        return audioOutput;

    }
}