import { ConstructorInterfaceMethod, ConstructorInterfaceMethodValidation } from '../../interface.type'
import ActionInterface from '../action/action.interface'
import './method.styles.scss'
export default function MethodInterface({ componentName, method }: {
    componentName: string,
    method: ConstructorInterfaceMethod
}) {

    return <div className='MethodInterface'>
        <div className='name'>{componentName}</div>
        {
            method.validations && <div className='validations'>
                {

                    Object.keys(method.validations).map((validationSpace: string, validationIndex: number) => {

                        if (validationSpace === 'header' || validationSpace === 'body' || validationSpace === 'query') {

                            const validations = method?.validations?.[validationSpace];
                            return <div className='space'>
                                <div className='name' key={validationIndex}>{validationSpace}</div>
                                {
                                    validations && Object.keys(validations).map((validationVariable: string, validationVariableIndex: number) => {

                                        return <div className='variable'>    <div className='name'>{validationVariable}</div>
                                            {validations[validationVariable]?.map((validationValue: ConstructorInterfaceMethodValidation, validationValueIndex: number) => {

                                                return <div className='validation' key={validationValueIndex}>

                                                    <div className='value'>{validationValue.type}</div>
                                                </div>

                                            })}
                                        </div>

                                    })}
                            </div>

                        }

                    })
                }
            </div>
        }
        {
            method.action && <div className='actions'>
                <ActionInterface action={method.action} />
            </div>
        }
    </div>

}