import React, { Component } from 'react';

class AudioVisualiser extends Component {
  constructor(props) {

    super(props);
    this.canvas = React.createRef();
  
}

  componentDidUpdate() {

    this.draw();
  
}

  draw() {

    const { audioData } = this.props;
    const canvas = this.canvas.current;
    const height = canvas.height;
    const width = canvas.width;
    const context = canvas.getContext('2d');
    let x = -30;
    const sliceWidth = (width * 1.4) / audioData.length;

    context.lineWidth = 5;
    context.strokeStyle = 'white';
    context.clearRect(0, 0, width, height);

    context.beginPath();
    context.moveTo(0, height / 1);
    for (const item of audioData) {

      const y = (item / 255.0) * height;
      context.lineTo(x, y);
      x += sliceWidth;
    
}
    context.lineTo(x, height *4);
    context.stroke();
  
}

  render() {

    return <canvas width="500" style={{zIndex: 10000000,}} height="600" ref={this.canvas} />;
  
}
}

export default AudioVisualiser;