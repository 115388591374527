import './index.scss';
import Map, { Marker } from 'react-map-gl';

interface DivisorComponentProps {
    title: string,
    description: string,
    icon?: string,
    styles?: any
}
function LayoutMapComponent(props: DivisorComponentProps) {

    return (

        <div className='LayoutMapComponent' style={props.styles}>
            <Map
                mapboxAccessToken={'pk.eyJ1Ijoic2FsYmF0b3JlIiwiYSI6ImNsMDRnaDRvcDBsbTMzanAzOGswMTh4djkifQ.tm66elt-S85TMukeryPD2Q'}
                initialViewState={{
                    longitude: -100,
                    latitude: 40,
                    zoom: 3.5
                }}
                style={{ width: '100%', height: '100%' }}
                mapStyle="mapbox://styles/salbatore/ckn5tvols00mx18nx60s2dsst"
            >
                <Marker longitude={-3.703339} latitude={40.416729} anchor="center" >
                    <div className='marker'>
                        <div className='dot'>

                        </div>
                    </div>
                </Marker>
                <Marker longitude={-3.723339} latitude={40.436729} anchor="center" >
                    <div className='marker'>
                        <div className='dot'>

                        </div>
                    </div>
                </Marker>
                <Marker longitude={-3.423339} latitude={40.456729} anchor="center" >
                    <div className='marker'>
                        <div className='dot'>

                        </div>
                    </div>
                </Marker>
                <Marker longitude={-3.86339} latitude={40.437729} anchor="center" >
                    <div className='marker'>
                        <div className='dot'>

                        </div>
                    </div>
                </Marker>
                <Marker longitude={-3.743339} latitude={40.438729} anchor="center" >
                    <div className='marker'>
                        <div className='dot'>

                        </div>
                    </div>
                </Marker>
            </Map>
        </div>

    );

}
export default LayoutMapComponent
