import { useNavigate } from "react-router-dom";
import './answer.styles.scss'

export default function AnswerComponent(properties: { callName: string, callEmail: string, openCall: () => void, cancelCall: () => void, call: string }) {

    const navigate = useNavigate();

    return <>
        <div className="AnswerBackground"></div>
        <div className="AnswerComponent">
            <div className="title">
                Llamada entrante
            </div>
            <p>
                <b>{properties.callName}</b> con email <b>{properties.callEmail}</b> te está llamando.
            </p>

            <div className="buttons">
                <div className="accept" onClick={() => {

                    properties.openCall()

                }}>
                    Aceptar
                </div>
                <div className="cancel" onClick={() => {

                    properties.cancelCall();

                }}>
                    Rechazar
                </div>
            </div>
        </div>
    </>

}