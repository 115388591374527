import { BrickterScreen } from "../../scheme.types";

export default function BrickterApplication() {

    const scheme: BrickterScreen = {
        path: "manager/application/*",
        type: "screen",
        styles: {
            width: "100%",
            maxWidth: 800,
            marginLeft: "auto",
            marginRight: "auto"
        },
        components: [
            {
                type: 'constructor'
            },
            {
                styles: {
                    animations: [
                        {
                            type: "slideUp"
                        }
                    ]
                },
                type: "screen",
                components: [
                    {
                        type: "header",
                        icon: "las la-layer-group",
                        title: "Applications",
                        description: "Reuniones agendadas",
                        buttons: [
                            {
                                type: "button",
                                text: "Nueva",
                                icon: "la la-plus",
                                actions: [
                                    {
                                        type: "redirect",
                                        path: "insert"
                                    }
                                ]
                            }
                        ],
                        bottom: {
                            icon: "la la-lock",
                            text: "Todos los datos del paciente son almacenados con claves foráneas"
                        }
                    },
                    {
                        type: "space"
                    },
                    {
                        type: "widget",
                        components: [
                            {
                                type: "table",
                                method: "GET",
                                endpoint: "/api/application",
                                header: {
                                    permanent: true,
                                },
                                data: [
                                    {
                                        name: 'Frontend',
                                        type: 'frontend',
                                        compilation: {
                                            name: 'Library',
                                        },
                                        status: true
                                    },
                                    {
                                        type: 'interface',
                                        name: 'Interface',
                                        compilation: {
                                            name: 'Library',
                                        },
                                        status: false
                                    },
                                    {
                                        type: 'landing',
                                        name: 'Landing',
                                        compilation: {
                                            name: 'Library',
                                        },
                                        status: false
                                    },
                                    {
                                        type: 'model',
                                        name: 'Model',
                                        compilation: {
                                            name: 'Library',
                                        },
                                        status: false
                                    }
                                ],
                                columns: [
                                    {
                                        icon: "las la-pen",
                                        components: [
                                            {
                                                type: "icon",
                                                icon: "las la-pen"
                                            }
                                        ]
                                    },
                                    {
                                        icon: "las la-cog",
                                        components: [
                                            {
                                                type: "icon",
                                                icon: "las la-cog"
                                            }
                                        ]
                                    },
                                    {
                                        components: [
                                            {
                                                type: "text",
                                                text: "{item.compilation.name} {item.name}"
                                            }
                                        ],
                                        title: "name",
                                        styles: {
                                            width: 220,
                                        }
                                    },
                                    {
                                        icon: "las la-turn",
                                        components: [
                                            {
                                                type: "icon",
                                                icon: "las la-check",
                                                conditions: [
                                                    {
                                                        operator: '===',
                                                        value: true,
                                                        id: 'status'
                                                    }
                                                ]
                                            },
                                            {
                                                type: "icon",
                                                icon: "las la-times",
                                                conditions: [
                                                    {
                                                        operator: '===',
                                                        value: false,
                                                        id: 'status'
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        components: [
                                            {
                                                type: "text",
                                                text: "Tables",
                                                conditions: [
                                                    {
                                                        operator: '===',
                                                        value: 'frontend',
                                                        id: 'type'
                                                    }
                                                ]
                                            },
                                            {
                                                type: "text",
                                                text: "Routes",
                                                conditions: [
                                                    {
                                                        operator: '===',
                                                        value: 'interface',
                                                        id: 'type'
                                                    }
                                                ]
                                            },
                                            {
                                                type: "text",
                                                text: "Screens",
                                                conditions: [
                                                    {
                                                        operator: '===',
                                                        value: 'manager',
                                                        id: 'type'
                                                    }
                                                ]
                                            },
                                            {
                                                type: "text",
                                                text: "Pages",
                                                conditions: [
                                                    {
                                                        operator: '===',
                                                        value: 'landing',
                                                        id: 'type'
                                                    }
                                                ]
                                            },
                                            {
                                                type: "text",
                                                text: "Entities",
                                                conditions: [
                                                    {
                                                        operator: '===',
                                                        value: 'model',
                                                        id: 'type'
                                                    }
                                                ]
                                            }
                                        ],
                                        title: "Constructor",
                                        styles: {
                                            width: 120,
                                        }
                                    },
                                    {
                                        components: [
                                            {
                                                type: "space"
                                            }
                                        ],

                                    },
                                    {
                                        components: [
                                            {
                                                type: "date",
                                                id: "created_at"
                                            }
                                        ],

                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                path: "insert",
                type: "screen",
                mode: "modal",
                outside: {
                    path: "/manager/meets"
                },
                components: [
                    {
                        title: "New Application",
                        description: "Complete the form",
                        icon: "las la-pen",
                        type: "bar",
                        close: {
                            path: "/manager/application"
                        }
                    },
                    {
                        type: "box",
                        components: [
                            {
                                type: "form",
                                actions: [
                                    {
                                        type: "redirect",
                                        cache: true,
                                        path: "/manager/application"
                                    }
                                ],
                                endpoint: "/api/application",
                                method: "post",
                                inputs: [
                                    {
                                        title: "Compilation",
                                        type: "select",
                                        path: "/api/compilation",
                                        icon: "las la-user",
                                        name: "compilation_id",
                                        placeholder: "",
                                        label: "{item.name}"
                                    },
                                    {
                                        title: "Code",
                                        type: "text",
                                        icon: "las la-sms",
                                        name: "name",
                                        defaultValue: "",
                                        placeholder: ""
                                    },
                                    {
                                        title: "Repository",
                                        type: "text",
                                        icon: "lab la-git-alt",
                                        name: "name",
                                        defaultValue: "",
                                        placeholder: ""
                                    },
                                    {
                                        title: "Status",
                                        name: "status",
                                        icon: "la la-male",
                                        type: "select",
                                        placeholder: "Select one status",
                                        label: "{item.label}",
                                        options: [
                                            {
                                                "id": "production",
                                                "label": "Production"
                                            },
                                            {
                                                "id": "pending",
                                                "label": "Pending"
                                            },
                                            {
                                                "id": "testing",
                                                "label": "Testing"
                                            },
                                        ]
                                    },
                                    {
                                        type: "submit",
                                        value: "Save"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                path: "update/:meet_id",
                type: "screen",
                mode: "modal",
                outside: {
                    path: "/manager/meets"
                },
                components: [
                    {
                        type: "retrieve",
                        endpoint: "/api/compilation/{params.meet_id}",
                        method: "get",
                        components: [
                            {
                                title: "Editar Reunión",
                                description: "Completa el formulario",
                                icon: "las la-pen",
                                type: "bar",
                                close: {
                                    path: "/manager/meets"
                                }
                            },
                            {
                                type: "box",
                                components: [
                                    {
                                        type: "form",
                                        endpoint: "/api/compilation/{params.meet_id}",
                                        method: "put",
                                        recovery: {
                                            endpoint: "/api/compilation/{params.meet_id}",
                                            method: "get"
                                        },
                                        actions: [
                                            {
                                                type: "redirect",
                                                cache: true,
                                                path: "/manager/meets"
                                            }
                                        ],
                                        inputs: [
                                            {
                                                title: "Paciente",
                                                type: "select",
                                                path: "/api/patient",
                                                icon: "las la-user",
                                                name: "patient_id",
                                                placeholder: "",
                                                label: "{item.name} {item.surname}",
                                                bubble: "{item.group.name}"
                                            },
                                            {
                                                title: "Asunto",
                                                type: "text",
                                                icon: "las la-sms",
                                                name: "subject",
                                                placeholder: ""
                                            },
                                            {
                                                title: "Fecha de Inicio",
                                                icon: "las la-calendar",
                                                type: "datetime",
                                                name: "started_at",
                                                placeholder: ""
                                            },
                                            {
                                                title: "Fecha de Finalización",
                                                icon: "las la-calendar",
                                                type: "datetime",
                                                name: "finished_at",
                                                placeholder: ""
                                            },
                                            {
                                                title: "Observaciones",
                                                icon: "la la-address-card",
                                                name: "description",
                                                type: "textarea"
                                            },
                                            {
                                                type: "submit",
                                                value: "Guardar"
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                path: "delete/:meet_id",
                type: "screen",
                mode: "modal",
                outside: {
                    path: "/manager/meets"
                },
                components: [
                    {
                        title: "Eliminar Cita",
                        description: "Acción irreversible",
                        icon: "las la-trash-alt",
                        type: "bar",
                        close: {
                            path: "/manager/meets"
                        }
                    },
                    {
                        type: "box",
                        components: [
                            {
                                type: "form",
                                actions: [
                                    {
                                        type: "redirect",
                                        cache: true,
                                        path: "/manager/meets"
                                    }
                                ],
                                endpoint: "/api/compilation/{params.meet_id}",
                                method: "delete",
                                inputs: [
                                    {
                                        type: "submit",
                                        value: "Eliminar"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    }
    return scheme

}