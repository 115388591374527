import Moment from 'moment';
import { CalendarSettings } from './../index';
import { Replace } from "../../../../utilities/replace/replace.utility";

export function GetCollisionEvents(events: any[], settings: CalendarSettings) {

    // create array of 24 elements
    const eventsByDay: any = [];
    const hourHeight = 100;
    // get events by hour
    events.forEach((event: any) => {

        const dateOfEventStart = new Date(Replace({ item: event }, settings.event.startedDateSelector));
        const hourOfEvent = dateOfEventStart.getHours();
        // get day of month
        const hourOfEventDay = Moment(dateOfEventStart).date();

        // get minutes between start and finish

        if (!eventsByDay[hourOfEventDay]) {

            eventsByDay[hourOfEventDay] = [];

        }
        const authorId = Replace({ item: event }, settings.event.idSelector)
        eventsByDay[hourOfEventDay].push({
            start: dateOfEventStart,
            id: event.id,
            authorId: authorId,
            end: new Date(Replace({ item: event }, settings.event.finishedDateSelector)),
            subject: Replace({ item: event }, settings.event.subjectSelector),
            description: Replace({ item: event }, settings.event.descriptionSelector),
            bubble: Replace({ item: event }, settings.event.bubbleSelector),
            color: settings.event.colorSelector ? Replace({ item: event }, settings.event.colorSelector) : '#000',
        });

    });

    eventsByDay.forEach((eventDay: any) => {

        // count events by authorId same Day
        const eventsByAuthorId: any = [];
        eventDay.forEach((event: any) => {

            const eventsSameAuthorId = eventDay.filter((e: any) => e.authorId === event.authorId);

            event.count = eventsSameAuthorId.length;

        })

    })

    // calculate positions of events
    eventsByDay.forEach((day: any, indexDay: number) => {

        day.forEach((event: any, indexEvent: number) => {

            const startHour = event.start.getHours();
            const startMinute = event.start.getMinutes();
            const minutesToStart = (startHour * 60) + startMinute;
            const minutes = (event.end.getTime() - event.start.getTime()) / 1000 / 60;
            const top = hourHeight / 60 * minutesToStart;
            const height = (hourHeight / 60 * minutes);
            event.top = top;

            // height maximum 2424 (day)
            if (2424 < height + top) {

                event.height = 2424 - top;

            }
            else {

                event.height = height;

            }
            event.left = 5;
            event.right = 5;

        })

    });
    // order events by start time
    eventsByDay.forEach((day: any, indexDay: number) => {

        day.sort((a: any, b: any) => {

            return a.start.getTime() - b.start.getTime();

        })

    });

    const collisionCount = (event: any, day: any, indexEvent: number) => {

        let count = 0;
        for (let i = indexEvent - 1; i >= 0; i--) {

            const previousEvent = day[i];
            // si el evento anterior tiene fecha de inicio a la misma
            if (previousEvent.start.getTime() <= event.start.getTime() + 1 && previousEvent.end.getTime() >= event.start.getTime() + 1) {

                count = count + 1 + previousEvent?.collision;

            }

        }
        return count;

    }

    // check collisions
    eventsByDay.forEach((day: any, indexDay: number) => {

        day.forEach((event: any, indexEvent: number) => {

            event.collision = collisionCount(event, day, indexEvent);

        })

    });
    console.log('Collision:', eventsByDay)
    return eventsByDay;

}