
// Function login props redux connect
import Moment from 'moment';
import { useEffect, useState } from 'react';
import './index.scss';
import Datetime from './input/DateTime';

interface DispatchProps {
    name: string,
    type: 'datetime' | 'date'
    placeholder: string,
    icon?: string,
    setData: (s: any) => void
    defaultValue?: string,
}

export default function LayoutFormDatetimeComponent(props: DispatchProps) {

    const [value, setValue] = useState<any>();
    useEffect(
        () => {

            if (props.defaultValue) {

                let defaultDate = new Date(props.defaultValue)
                if (props.defaultValue === 'now') {

                    defaultDate = new Date()

                }
                if (props.defaultValue === 'now+1h') {

                    defaultDate = new Date(new Date().getTime() + 3600000)

                }
                setValue(defaultDate)
                const formatDateTimeZone = Moment(defaultDate).utc().toISOString();
                props.setData(formatDateTimeZone)

            }

        }, [props.defaultValue])

    return (
        <div className='LayoutFormInputComponent'>

            {props.icon && <i className={`icon las ${props.icon}`} />}

            <Datetime
                initialValue={value}
                value={value}
                type={props.type}
                closeOnSelect={true}
                timeFormat={props.type === 'date' ? false : undefined}
                dateFormat={props.type === 'date' ? 'YYYY-MM-DD' : 'YYYY-MM-DD'}
                onChange={(momentSelected: any) => {

                    console.log(momentSelected)
                    setValue(momentSelected)
                    props.setData(momentSelected)

                }} />

        </div>

    );

}

