import HeaderConstructorComponent from '../components/header/header.constructor.component'
import LayoutConstructorComponent from '../components/layout/layout.constructor.component'
import MethodInterface from './components/method/method.interface'
import './interface.styles.scss'
import { ConstructorInterfaceType } from './interface.type'
export default function InterfaceConstructor({ application }: { application: ConstructorInterfaceType }) {

    return <LayoutConstructorComponent>        <HeaderConstructorComponent title={`Interface ${application.code}`} />
        <div className='InterfaceConstructor'>
            <HeaderConstructorComponent title={`Interface Middlewares ${application.code}`} />

            <div className='middlewares'>

                {
                    application.scheme.middleares && Object.keys(application.scheme.middleares).map((methodName: string, methodIndex: number) => {

                        return application.scheme?.middleares?.[methodName] && <MethodInterface componentName={methodName} key={methodIndex} method={
                            application.scheme.middleares[methodName]
                        } />

                    })
                }
            </div>
            <HeaderConstructorComponent title={`Interface Methods ${application.code}`} />
            <div className='methods'>
                {
                    application.scheme.methods && Object.keys(application.scheme.methods).map((methodName: string, methodIndex: number) => {

                        return application.scheme?.methods?.[methodName] && <MethodInterface componentName={methodName} key={methodIndex} method={
                            application.scheme.methods[methodName]
                        } />

                    })
                }
            </div>
        </div>
    </LayoutConstructorComponent >

}