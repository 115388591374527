import { useEffect, useRef, useState } from 'react';
import LoaderComponent from '../../../loader/loader.component';
import AudioAnalyser from './components/audio/AudioAnalyser';
import { DishVideoSettings } from './components/settings/dish.video.settings';
import './dish.video.styles.scss';
import Joystick from './components/joystick/joystick';
import JoystickDishComponent from './components/joystick/joystick.dish.component';
import ZoomDishComponent from './components/zoom/zoom.dish.component';
import MutedDishComponent from './components/muted/muted.dish.component';
import SelfieDishComponent from './components/selfie/selfie.dish.component';
import { DishControlsInterface } from '../../dish.types';

interface Props {
    controls?: DishControlsInterface
    type: 'local' | 'remote';
    stream: MediaStream;
    muted?: boolean;
    name?: string,
    removeStream: () => void;
    videoBigSelected: boolean,
    setVideoBigSelected: (label: string) => void;
    getAudioOutputDevices?: () => Promise<MediaDeviceInfo[] | undefined>;
}

const Video = ({ controls, getAudioOutputDevices, removeStream, videoBigSelected, setVideoBigSelected, stream, muted, name, type }: Props) => {

    const ref = useRef<HTMLVideoElement>(null);
    const surfaceRef = useRef<HTMLDivElement>(null);
    const [isMuted, setIsMuted] = useState<boolean>(muted || false);
    const [showSettings, setShowSettings] = useState<boolean>(false);
    const [invert, setInvert] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [zoomControls, setZoomControls] = useState<boolean>(false);
    const [ptzControls, setPtzControls] = useState<boolean>(false);
    const [size, setSize] = useState<string>('cover');
    const [flip, setFlip] = useState<boolean>(true);
    const [audioOutputDevice, setAudioOutputDevice] = useState<string>();
    const [audioOutputDeviceLabel, setAudioOutputDeviceLabel] = useState<string>();
    useEffect(() => {

        console.log('Damian Video: ', stream);

        if (ref.current) {

            console.log('22222:', stream)
            ref.current.srcObject = stream;

        }
        if (muted) setIsMuted(muted);
        // controls zoom?
        const [videoTrack] = stream.getVideoTracks();
        const capabilities = videoTrack?.getCapabilities?.();
        const settings = videoTrack?.getSettings?.();
        console.log('Damian capabilities: ', capabilities);

        console.log('Damian settings: ', settings);

        // @ts-ignore
        if (capabilities?.zoom) {

            setZoomControls(true);

        } else {

            setZoomControls(false);

        }

        // check if ptz
        const supports = navigator.mediaDevices.getSupportedConstraints();

        // controls ptz?
        // @ts-ignore
        if (capabilities?.pan && capabilities?.tilt) {

            setPtzControls(true);

        }

    }, [stream, muted]);

    useEffect(() => {

        if (ref.current) {

            // @ts-ignore
            ref.current.setSinkId(audioOutputDevice);

        }

    }, [audioOutputDevice]);

    // only audio of stream?
    const isOnlyAudio = stream.getVideoTracks().length === 0;

    // only video of stream
    const isOnlyVideo = stream.getAudioTracks().length === 0;

    // check is stream is media screen
    const isScreen = stream.getVideoTracks().length > 0 && stream.getVideoTracks()[0].label.includes('screen');

    // get label
    const label = stream.getVideoTracks().length > 0 ? stream.getVideoTracks()[0].label : stream.getAudioTracks()[0].label;

    // kind of stream streamSettings?.kind
    const audioStreamSettings: MediaStreamTrack | undefined = stream.getAudioTracks().length > 0 ? stream.getAudioTracks()[0] : undefined;
    const videoStreamSettings: MediaStreamTrack | undefined = stream.getVideoTracks().length > 0 ? stream.getVideoTracks()[0] : undefined;

    // detect echo cancellation
    const echoCancellation = stream.getAudioTracks().length > 0 && stream.getAudioTracks()[0].getSettings().echoCancellation;
    // class name of video
    const classNames = [

        isOnlyAudio ? 'audio' : '',
        isMuted ? 'muted' : '',
        invert ? 'invert' : '',
        size
    ].join(' ');

    return (
        <div className={`DishVideoComponent ${videoBigSelected ? 'selected' : 'unselected'}`}>
            {showSettings && <DishVideoSettings
                getAudioOutputDevices={getAudioOutputDevices}
                setAudioOutputDevice={(deviceId: string, label: string) => {

                    setAudioOutputDevice(deviceId);
                    setAudioOutputDeviceLabel(label);

                }
                }
                audioOutputDevice={audioOutputDevice}
                audioOutputDeviceLabel={audioOutputDeviceLabel}
                flip={flip}
                flipVideo={() => videoStreamSettings && setFlip(!flip)}
                stream={stream} setShowSettings={() => setShowSettings(!showSettings)} />}

            <div className='joystick-container'>

            </div>
            {
                !isOnlyAudio && loading && <LoaderComponent invert status={true} />
            }
            <div ref={surfaceRef} className={`render ${!isOnlyAudio && loading ? 'loading' : ''} ${!flip ? 'flip' : ''}`}>
                {isOnlyAudio ? <>
                    <AudioAnalyser audio={stream} />
                    {stream && <audio ref={ref} autoPlay muted={isMuted} />}
                </> : <>
                    <video
                        playsInline
                        //loading
                        onLoadedData={() => {

                            setTimeout(() => {

                                setLoading(false)

                            }, 1000);

                        }}
                        onError={(e) => {

                            alert('Error video tag')
                            console.log('ERROR VIDEO ERROR VIDEOERROR VIDEO ERROR VIDEOERROR VIDEO ERROR VIDEO')
                            console.log(e);

                        }}
                        controls={false}
                        className={classNames}
                        ref={ref} autoPlay muted={isMuted} />    </>}
            </div>
            <div className=''>{audioStreamSettings?.kind}</div>
            <SelfieDishComponent
                label={name}
                type={isScreen ? 'screen' : audioStreamSettings?.kind && videoStreamSettings?.kind ? 'camera' : videoStreamSettings?.kind ? 'Video' : 'Audio'}
            />
            <div className={`controls ${!isOnlyAudio && loading ? 'loading' : ''}`}>

                {!isOnlyVideo && <MutedDishComponent
                    isMuted={isMuted}
                    setIsMuted={setIsMuted}
                />}
                {
                    controls?.invert !== false &&
                    videoStreamSettings && <button onClick={() => setInvert(!invert)}><i className="las la-adjust"></i></button>}
                {videoStreamSettings && <button onClick={() => {

                    if (size === 'cover') setSize('contain');
                    else if (size === 'contain') setSize('cover');

                }}><i className="las la-arrows-alt-h"></i></button>}
                {videoStreamSettings && <button onClick={() => {

                    if (ref.current) {

                        // width of stream
                        const streamWidth = stream.getVideoTracks()[0].getSettings().width;

                        // Create canvas:
                        let canvas = document.createElement('canvas');
                        canvas.setAttribute('width', `${ref.current.videoWidth}`);
                        canvas.setAttribute('height', `${ref.current.videoHeight}`);
                        // Generate thumbnail URL data
                        let context = canvas.getContext('2d');
                        if (context) {

                            if (flip) {

                                context.translate(ref.current.videoWidth, 0);
                                context.scale(-1, 1);

                            }
                            context.drawImage(ref.current, 0, 0, canvas.width, canvas.height);

                            let dataURL = canvas.toDataURL();

                            // Download image:
                            let a = document.createElement('a');
                            a.href = dataURL;
                            a.download = 'captureShot.png';
                            a.click();

                        }

                    }

                }}><i className="las la-camera"></i></button>}
                {ptzControls && <JoystickDishComponent stream={stream} />}
                {zoomControls && <ZoomDishComponent stream={stream} />}

                <button onClick={() => setShowSettings(!showSettings)}><i className="las la-ellipsis-h"></i></button>

            </div>
            <button className='selection' onClick={() => setVideoBigSelected(label)}><i className="las la-thumbtack"></i></button>
            {
                type === 'local' && <button onClick={removeStream} className="remove"><i className="las la-times"></i></button>
            }
        </div>
    );

};

export default Video;