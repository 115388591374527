import CalendarWeekBody from './body/body.week'
import './week.styles.scss'
import { useEffect, useRef, useState } from 'react'
import { CalendarSettings } from '../..'
export default function CalendarDay(props: {
    onEdit: (item: any) => void,
    onDelete: (item: any) => void,
    onNew: (start: Date, end: Date, column: any) => void,
    daySelected: Date, widthCalendar: number, settings: CalendarSettings, events: any[]
}) {

    const [height, setHeight] = useState<number>()
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {

        if (ref.current && ref.current.offsetHeight) {

            setHeight(ref.current.offsetHeight)

        }

    }, [ref.current])
    return <div className="CalendarWeek" ref={ref}
        // event height layout
        style={{ maxHeight: height || 'auto', height: height || 'auto', width: props.widthCalendar }}
    >

        {height && <CalendarWeekBody
            daySelected={props.daySelected}
            onDelete={props.onDelete}
            onNew={props.onNew}
            onEdit={props.onEdit}
            events={props.events} settings={props.settings} />}
    </div>

}