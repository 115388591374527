import { getProperty } from 'dot-prop';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import RequestAction from '../../../../../actions/request/request.action';
import './index.scss';

interface PosProperties {
    store: any,
    items: {
        endpoint: string,
        method: string
    },
    create: {
        endpoint: string,
        method: string
    }
}
function FormPosComponent(props: PosProperties) {

    const [items, setItems] = useState<any[]>([]);
    const [options, setOptions] = useState<any[]>([]);
    const [search, setSearch] = useState<any[]>([]);
    const [count, setCount] = useState<number>(0);

    const loadData = (search: string) => {

        const currentUserToken = getProperty(props.store, 'user.access_token');
        let path = props.items.endpoint;
        // Have ?
        if (path.indexOf('?') > -1) {

            path = `${path}`

        }
        else {

            path = `${path}?`

        }
        if (search) {

            path = `${path}&search=${search}`

        }
        RequestAction({
            endpoint: path,
            method: props.items.method,
            headers: {
                'authorization': 'Bearer ' + currentUserToken
            }
        }
        ).then((response) => {

            setOptions(response.data.items);

        }
        ).catch((error) => {
            //
        });

    }
    return (

        <div className='LayoutPosComponentForm'>
            <div className='header'>
                x  <div className='total'>
                    0,00
                </div>
                <div className='method'>
                    <i className='icon la la-credit-card' />
                    Tarjeta de Crédito
                    <i className='angle la la-angle-down' />
                </div>
            </div>
            <div className='head'>
                <div className='name'>Descripción</div>
                <div className='price'>Precio</div>
                <div className='units'>Unidades</div>
            </div>
            <input type={'text'} className='search' placeholder='Buscar'
                onChange={(e) => {

                    loadData(e.target.value);

                }}

            />
            <div className='items'>
                {options.map((option, index) => (
                    <div className='item' key={index}>
                        <div className='name'>
                            {option.name}
                        </div>
                        <div className='price'>
                            {option.price}
                        </div>

                        <i className='icon la la-plus' onClick={() => {

                            setItems([...items, option]);

                        }} />
                    </div>
                ))}

            </div>
            <div className='items'>
                {items.map((item, index) => (
                    <div className='item' key={index}>
                        <div className='name'>
                            {item.name}
                        </div>
                        <div className='price'>
                            {item.price}
                        </div> <i className='icon la la-minus' />
                        <div className='units'>

                            {item.units}

                        </div>
                        <i className='icon la la-plus' />
                    </div>
                ))}

            </div>
            <div className='add'>
                <i className='las la-plus' />
                Añadir producto
            </div>

        </div >

    );

}

const mapStateToProps = (state: any, ownProps: any) => (state)

export default connect(mapStateToProps,)(FormPosComponent)
