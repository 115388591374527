import { getProperty } from 'dot-prop';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ExecuteActions from '../../../actions';
import RequestAction from '../../../actions/request/request.action';
import { ActionsType } from '../../../actions/types';
import { setStore } from '../../../store/actions';
import { Replace } from '../../../utilities/replace/replace.utility';
import LoaderComponent from '../loader/loader.component';
import HeaderTicketComponent from './components/header/header.ticket.component';
import ProductsTicketComponent from './components/products/products.ticket.component';
import TotalTicketComponent from './components/total/total.ticket.component';
import './ticket.styles.scss';
import ReactToPrint from 'react-to-print';

const mapDispatchToProps = {

    setStore
}

interface PosProperties {

    store: any,
    setStore: (s: any) => void,
    barcode: string,
    item: any,
    total: string,
    company: {
        name: string,
        email: string,
        phone: string,
        address: string,
        footer: string,
        coin: string
    },
    date: string,
    products: TicketProductType[]
}

type TicketProductType = {
    root: string,
    name: string,
    price: string
}
export type ProductSelected = {
    name: string,
    price: number,
}
function TicketComponent(props: PosProperties) {

    const pageStyle = `
    @page {
      size: 50mm 100mm; margin: 0;padding:0;
      font-size: 5px;
      font-size: 5px;
    }
  
    @media all {
      .pagebreak {
        display: none;
      }
    }
  
    @media print {
       *{
        font-size: 5px;
       }
      .pagebreak {
        page-break-before: always;
      }
    }
  `;
    const printRef: any = useRef();

    const barcode = Replace({ item: props.item }, props.barcode);
    const companyName = Replace({ item: props.item }, props.company.name);
    const companyEmail = Replace({ item: props.item }, props.company.email);
    const companyPhone = Replace({ item: props.item }, props.company.phone);
    const companyAddress = Replace({ item: props.item }, props.company.address);
    const date = Replace({ item: props.item }, props.date);
    const total = Replace({ item: props.item }, props.total);
    const footer = Replace({ item: props.item }, props.company.footer);
    const coin = Replace({ item: props.item }, props.company.coin);
    const products: ProductSelected[] = [];

    props.products.map((productConfiguration: TicketProductType) => {

        console.log(props.item)
        console.log(productConfiguration.root)
        const productsSelected: any[] | undefined = getProperty(props.item, productConfiguration.root);
        if (productsSelected) {

            console.log(productsSelected);
            productsSelected.map((product: any) => {

                products.push({
                    name: Replace({ item: product }, productConfiguration.name),
                    price: parseFloat(Replace({ item: product }, productConfiguration.price))
                })

            })

        }

    });
    console.log('prorpr: ', products);
    return (<div className='TicketComponent'>

        <div ref={printRef} className="print" >
            <style>{pageStyle}</style>
            <HeaderTicketComponent
                barcode={barcode}
                companyName={companyName}
                companyEmail={companyEmail}
                companyPhone={companyPhone}
                companyAddress={companyAddress}
                date={date}
            />
            <ProductsTicketComponent products={products} coin={coin} />
            <TotalTicketComponent total={total} footer={footer} coin={coin} />
        </div>
        <ReactToPrint
            pageStyle={pageStyle}
            trigger={() => {

                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                // to the root node of the returned component as it will be overwritten.
                return <div className='printer'>IMPRIMIR</div>

            }}
            content={() => printRef.current}
        />
    </div>);

}

const mapStateToProps = (state: any, ownProps: any) => (state)

export default connect(mapStateToProps, mapDispatchToProps)(TicketComponent)
