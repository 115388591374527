import { ChatMessageType } from './../components/sections/chat/chat.component';
import { DishLogType } from './../dish.types';
import { io, Socket } from "socket.io-client";
import SocketCreate from "./socket.create";
enum SocketStatus {
    CONNECTED = 'connected',
    DISCONNECTED = 'disconnected',
    ERROR_CONNECTING = 'error connecting',

}
interface SocketInstanceInterface {
    debug?: boolean;
    roomId: string;
    userId: string;
    userName: string;
    onLog?: (log: DishLogType) => void;
    onConnect?: () => void;
    onSocketId?: (socketId: string) => void;
    onDisconnect?: () => void;
    onErrorConnecting?: () => void;
    onNewLocalStream?: (stream: MediaStream) => void;
    onRemoveLocalStream?: (streamIndex: number) => void;
    onUpdateParticipants?: (participants: SocketParticipants) => void;
    onMessage?: (message: ChatMessageType) => void;
}
export type SocketParticipants = {
    [userId: string]: {
        peer: RTCPeerConnection,
        streams: MediaStream[]
    }
}
export class SocketInstance {
    private localMediaStreams: MediaStream[] = [];
    private socket?: Socket;
    private debug: boolean;
    private roomId: string;
    private userId: string;
    private userName: string;
    private participants: SocketParticipants = {}
    public status: SocketStatus = SocketStatus.DISCONNECTED;
    private events: {
        onLog?: (log: DishLogType) => void;
        onConnect?: () => void;
        onSocketId?: (socketId: string) => void;
        onDisconnect?: () => void;
        onErrorConnecting?: () => void;
        onNewLocalStream?: (stream: MediaStream) => void;
        onUpdateParticipants?: (data: SocketParticipants) => void;
        onMessage?: (message: ChatMessageType) => void;
        onRemoveLocalStream?: (streamIndex: number) => void;
    } = {}

    constructor({ debug, roomId, userId, userName, onLog, onRemoveLocalStream, onConnect, onSocketId, onDisconnect, onErrorConnecting, onNewLocalStream, onUpdateParticipants, onMessage }: SocketInstanceInterface) {

        this.debug = debug || false;
        this.roomId = roomId;
        this.userId = userId;
        this.userName = userName;

        this.events = {
            onLog,
            onConnect,
            onSocketId,
            onDisconnect,
            onErrorConnecting,
            onNewLocalStream,
            onUpdateParticipants,
            onMessage,
            onRemoveLocalStream
        }
        this.log('Constructor of Socket');
        this.log(`User ID [${userId}]`);
        this.log(`Room ID [${roomId}]`);
        this.socket = this.connect();

        if (this.socket?.id) {

            this.log('Socket Id', this.socket?.id, this.socket?.id ? true : false);

        }
        else {

            this.log('No se ha podido generar un Socket Id', this.socket?.id, this.socket?.id ? true : false);

        }

        // Socket

        this.joinRoom();

        // statuses
        this.onConnectFailed()
        this.onConnect()
        this.onDisconnect()
        this.onAllUsers()
        this.getOffer()
        this.onMessage()

        // handle errors
        this.onError()
        this.onConnectError()

    }

    async shareLocalStreamsToRemoteUser(peer: RTCPeerConnection, stream?: MediaStream) {

        console.log('share local streams to remote user', this.localMediaStreams.length);
        if (stream) {

            console.log('Stream directo');

            stream.getTracks().forEach((track) => {

                const r = peer.addTrack(track, stream);
                console.log('response:', r)

            });

        }
        else {

            this.localMediaStreams.forEach((stream) => {

                stream.getTracks().forEach((track) => {

                    console.log('stream a agregar', peer);
                    const r = peer.addTrack(track, stream);
                    console.log('response: x', r)

                });

            })

        }

    }

    disconnect() {

        this.socket?.disconnect();

        // close all peer connections
        Object.keys(this.participants).forEach((userId) => {

            this.participants[userId].peer.close();

        });

    }

    async removeLocalStream(streamIndex: number) {

        this.localMediaStreams[streamIndex].getTracks().forEach((track) => {

            track.stop();

        });
        this.events?.onRemoveLocalStream?.(streamIndex);

        // remove local stream from localMediaStreams array
        this.localMediaStreams.splice(streamIndex, 1);

    }

    async addLocalStream(stream: MediaStream) {

        console.log('STREAM CARGADO CORRECTAMENTE', stream);
        this.localMediaStreams.push(stream);
        const recordingEnabled = false;
        if (recordingEnabled) {

            return false;
            // record:
            const mediaRecorder = new MediaRecorder(stream, {
                audioBitsPerSecond: 128000,
                mimeType: 'video/webm; codecs=vp9',
            });
            // Get first path folder of window
            const path = window.location.pathname.split('/')[2];
            const roomId = path
            console.log(mediaRecorder)
            const randomString = Math.random().toString(36).substring(7);
            mediaRecorder.ondataavailable = (event) => {

                if (event.data && event.data.size > 0) {

                    this.socket && console.log('Send Media!', this.socket.id, randomString)
                    this.socket && this.socket.emit('media', this.socket.id, randomString, event.data)

                }

            };

            // This will trigger a dataavailable event every second.
            mediaRecorder.start(5000);

            // This will trigger a single dataavailable event.
            //    mediaRecorder.requestData();

        }
        // share local streams to all remote users
        this.events.onNewLocalStream && this.events.onNewLocalStream(stream);

        this.participants && Object.keys(this.participants).forEach(async (participantId) => {

            this.participants[participantId] = { peer: this.createPeerConnection(participantId), streams: [] };
            try {

                const localSdp = await this.participants[participantId].peer.createOffer({
                    offerToReceiveAudio: true,
                    offerToReceiveVideo: true,
                });
                console.log('create offer success');
                await this.participants[participantId].peer.setLocalDescription(new RTCSessionDescription(localSdp));
                this.socket?.emit('offer', {
                    sdp: localSdp,
                    offerSendID: this.socket?.id,
                    // random string
                    offerReceiveID: participantId,
                });

            } catch (e) {

                console.error(e);

            }

        })

    }

    handleErrors(error: string, data: any) {

        this.log(error, data)

    }

    connect() {

        try {

            const socket = io('https://socket.comitas.alicunde.dev', {
                secure: true,
                autoConnect: true,
                path: '/socket/socket.io',
            });

            if (socket) {

                return socket;

            }

            else {

                this.log('error connecting', false);

            }

        }
        catch (e) {

            console.error(e);
            this.log('error connecting', e, false);

        }

    }

    joinRoom() {

        try {

            this.socket?.emit('join_room', {
                room: this.roomId,
                email: this.userId,
            });

        }
        catch (e) {

            this.log('error room join', e, false);

        }

    }

    onReconnect() {

        this.socket?.on('reconnect_attempt', (attempt) => {

            this.log(`reconnect attempt ${attempt}`);

        });

    }

    onError() {

        this.socket?.on('error', (error) => {

            this.log('error', error, false);
            console.error(error);

        })

    }

    onConnectFailed() {

        this.socket?.on('connect_failed', (error) => {

            this.log('connect failed', error, false);

        })

    }

    onConnectError() {

        this.socket?.on('connect_error', (error) => {

            this.events.onErrorConnecting && this.events.onErrorConnecting();

        })

    }

    onConnect() {

        this.socket?.on('connect', () => {

            // change socket status
            this.status = SocketStatus.CONNECTED;

            // outside event
            this.events.onConnect && this.events.onConnect();

            this.log(`Socket ID [${this.socket?.id}]`);
            this.socket?.id && this.events.onSocketId && this.events.onSocketId(this.socket?.id);

        });

    }

    sendMessage(message: string) {

        this.socket?.emit('message', {
            room: this.roomId,
            message: message,
            name: this.userName,
            date: new Date().getTime(),
            socketId: this.socket?.id,
        });

    }

    onMessage() {

        this.socket?.on('receive-message', (data) => {

            const message: ChatMessageType = {
                room: data.room,
                message: data.message,
                name: data.name,
                date: data.date,
                socketId: data.socketId,
                type: data.socketId === this.socket?.id ? 'me' : 'other'
            }

            this.events.onMessage && this.events.onMessage(message);

        })

    }

    onDisconnect() {

        this.socket?.on('disconnect', () => {

            console.log('Socket Desconectado desde Instancia')
            // change socket status
            this.status = SocketStatus.DISCONNECTED;

            // outside event
            this.events.onDisconnect && this.events.onDisconnect();

        })

    }

    onAllUsers() {

        this.socket?.on('all_users', (allUsers: Array<{ id: string; email: string }>) => {

            this.log(`All Users [${allUsers.length} count]`, allUsers);
            allUsers.forEach(async (user) => {

                if (user.id === this.socket?.id) return;
                this.participants[user.id] = { peer: this.createPeerConnection(user.id), streams: [] };
                try {

                    const localSdp = await this.participants[user.id].peer.createOffer({
                        offerToReceiveAudio: true,
                        offerToReceiveVideo: true,
                    });
                    console.log('create offer success');
                    await this.participants[user.id].peer.setLocalDescription(new RTCSessionDescription(localSdp));
                    this.socket?.emit('offer', {
                        sdp: localSdp,
                        offerSendID: this.socket.id,
                        offerSendEmail: 'offerSendSample@sample.com',
                        offerReceiveID: user.id,
                    });

                } catch (e) {

                    console.error(e);

                }

            });

        });

    }

    getOffer() {

        this.socket?.on(
            'getOffer',
            async (data: {
                sdp: RTCSessionDescription;
                offerSendID: string;
                offerSendEmail: string;
            }) => {

                const { sdp, offerSendID, offerSendEmail } = data;
                console.log('get offer');
                this.participants[offerSendID] = { peer: this.createPeerConnection(offerSendID), streams: [] };

                try {

                    await this.participants[offerSendID].peer.setRemoteDescription(new RTCSessionDescription(sdp));
                    const localSdp = await this.participants[offerSendID].peer.createAnswer({
                        offerToReceiveVideo: true,
                        offerToReceiveAudio: true,
                    });

                    await this.participants[offerSendID].peer.setLocalDescription(new RTCSessionDescription(localSdp));
                    console.log('Send answer')
                    this.socket?.emit('answer', {
                        sdp: localSdp,
                        answerSendID: this.socket.id,
                        answerReceiveID: offerSendID,
                    });

                } catch (e) {

                    console.error(e);

                }

            },
        );

        this.socket?.on(
            'getAnswer',
            (data: { sdp: RTCSessionDescription; answerSendID: string }) => {

                const { sdp, answerSendID } = data;
                const pc: RTCPeerConnection = this.participants[answerSendID].peer;
                if (!pc) return;
                pc.setRemoteDescription(new RTCSessionDescription(sdp));

            },
        );

        this.socket?.on(
            'getCandidate',
            async (data: { candidate: RTCIceCandidateInit; candidateSendID: string }) => {

                console.log('recibo candidato:', data)
                const pc: RTCPeerConnection = this.participants[data.candidateSendID]?.peer;
                console.log('jeje:', pc)
                if (!pc) return;
                await pc.addIceCandidate(new RTCIceCandidate(data.candidate));

            },
        );

        this.socket?.on('user_exit', (data: { id: string }) => {

            if (this.participants[data.id]?.peer) {

                this.participants[data.id].peer.close();
                delete this.participants[data.id];
                this.events.onUpdateParticipants && this.events.onUpdateParticipants(this.participants);

            }
            return

        });

    }

    createRTC() {

        //         stun.l.google.com:19302
        // stun1.l.google.com:19302
        // stun2.l.google.com:19302
        // stun3.l.google.com:19302
        // stun4.l.google.com:19302
        // stun.ekiga.net:3478
        // stun.cheapvoip.com:3478
        // stun.gmx.de:3478
        // stun.gmx.net:3478
        // stun.ipfire.org:3478
        // stun.linphone.org:3478
        // stun.services.mozilla.com:3478
        // stun.stunprotocol.org:3478
        // stunserver.org:3478

        //twilio api:core:tokens:create

        const rtc: RTCPeerConnection = new RTCPeerConnection(
            {

                iceServers: [

                    // { urls: "stun:sippar4.ddns.net:3478?transport=udp", },
                    // { urls: "turn:sippar4.ddns.net:5349?transport=udp", username: 'alicunde', credential: 'mola', credentialType: 'password' },

                ],
            }
        );

        return rtc;

    }

    createPeerConnection(userId: string) {

        const pc = this.createRTC();
        console.log(' =========== create peer connection =========== ');
        pc.onicecandidate = (e) => {

            if (!e.candidate) return;
            console.log('userId userId:', userId)
            console.log('envio candidato:', this?.socket?.id)
            this.socket?.emit('candidate', {
                candidate: e.candidate,
                candidateSendID: this.socket.id,
                candidateReceiveID: userId,
            });

        };

        pc.oniceconnectionstatechange = (e) => {

            console.log('ice connection state change:', e);

            if (pc.iceConnectionState == 'disconnected') {

            }

        };

        pc.oniceconnectionstatechange = (e) => {

            console.log('ice connection state change:', e);

        };

        pc.ontrack = (e) => {

            const stream: MediaStream = e.streams[0];
            console.log('=========== ontrack success =========', userId, stream.active);
            // stream disconnect

            // detect track is stream exist
            if (this.participants[userId].streams.find((s) => s.id === stream.id)) return;
            // add stream to participant
            this.participants[userId].streams?.push(stream);

            this.events.onUpdateParticipants && this.events.onUpdateParticipants(this.participants);

        };
        console.log(' =========== ENVIAMOS VIDEOS A ' + userId + ' =========== ');

        this.shareLocalStreamsToRemoteUser(pc)
        // } else {

        //     console.log('no local stream');

        // }

        return pc;

    }

    log(message: any, data?: any, status = true) {

        console.log(`[socket] ${message}`);
        this.events.onLog && this.events.onLog({ status: status, log: message, date: new Date() });
        if (this.debug && data) {

            console.log(data);

        }

    }
}