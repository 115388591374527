import { useEffect, useState } from 'react';
import './background.dish.component.scss'
interface ConfigurationSettings {

}
interface HTMLInputEvent extends Event {
    target: HTMLInputElement & EventTarget;
}

import { connect as reduxConnect } from 'react-redux';
import { setStore } from '../../../../../../../../../store/actions';

const mapStateToProps = (state: any, ownProps: any) => (state)
const mapDispatchToProps = {

    setStore
}
interface DispatchProps {
    setStore: (s: any) => void
    color?: string,
    store: any,
    setBackgroundSelected: (background?: string) => void

}

function BackgroundDishComponent(props: DispatchProps) {

    const [backgroundToggle, setBackgroundToggle] = useState<boolean>(false)
    const [backgroundImage, setBackgroundImage] = useState<string | undefined>(undefined)
    const [backgroundCookieImage, setBackgroundCookieImage] = useState<string | undefined>(undefined)
    console.log(props.store)
    return <>
        <div className='BackgroundDishComponent'>
            <div className='selector'>
                <div className='placeholder' onClick={() => {

                    setBackgroundToggle(!backgroundToggle)

                }}>
                    <i className='las la-image'></i>
                    Fondo de pantalla {backgroundImage ? 'activado' : 'desactivado'}
                    <i className='las la-angle-down'></i>
                </div>
                {
                    backgroundToggle && <div className='backgrounds'>
                        <div className='no background' onClick={() => {

                            setBackgroundToggle(!backgroundToggle)
                            setBackgroundImage(undefined)
                            props.setBackgroundSelected(undefined)

                        }}>
                            <i className='center las la-ban' />
                        </div>

                        <div className='empty background'
                            onClick={() => {

                                // get file local from input file
                                const input = document.createElement('input')
                                input.type = 'file'
                                input.accept = 'image/*'
                                input.onchange = (event) => {

                                    const eventTarget = event as HTMLInputEvent
                                    let files: any = eventTarget?.target?.files?.[0];
                                    if (files) {

                                        const reader = new FileReader();
                                        reader.onload = (event) => {

                                            const result = event?.target?.result
                                            setBackgroundImage(result as string)
                                            props.setBackgroundSelected(result as string)
                                            let data: any = {};
                                            data.dish = {}
                                            data.dish.background = []
                                            if (props.store?.['dish']) {

                                                data.dish = props.store['dish']

                                            }
                                            if (data?.dish?.backgrounds) {

                                                data.dish.backgrounds.push(result as string)
                                                props.setStore(data)

                                            }
                                            else {

                                                data.dish.backgrounds = [result as string]
                                                props.setStore(data)

                                            }

                                        }
                                        reader.readAsDataURL(files);

                                    }

                                }
                                input.click()

                            }}
                        >
                            <i className='center las la-plus' />

                        </div>
                        {
                            props.store?.dish?.backgrounds?.map((background: any, index: number) => {

                                return <div className='background' key={index} onClick={() => {

                                    setBackgroundImage(background)
                                    setBackgroundCookieImage(background)
                                    props.setBackgroundSelected(background)

                                }}
                                    style={{
                                        backgroundImage: `url(${background})`
                                    }}
                                >
                                    <i className='remove las la-times' onClick={() => {

                                        props.setStore({
                                            dish: {
                                                backgrounds: props.store?.dish?.backgrounds?.filter((b: any) => b !== background)
                                            }
                                        })

                                    }}></i>
                                    {
                                        backgroundImage === background && <i className='selected las la-check'></i>
                                    }

                                </div>

                            })
                        }
                    </div>
                }
            </div></div>
    </>

}

export default reduxConnect(mapStateToProps, mapDispatchToProps)(BackgroundDishComponent)
