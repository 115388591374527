import { useEffect, useRef, useState } from 'react';
import './zoom.dish.styles.scss';

interface Props {

    stream: MediaStream;

}

const ZoomDishComponent = ({ stream }: Props) => {

    let ptzZoomInInterval: any

    const setZoom = (mode: 'in' | 'out') => {

        console.log('zoom!')
        // get capabilities
        const capabilities = stream.getVideoTracks()[0].getCapabilities();
        // get zoom current
        const settingsVideo = stream.getVideoTracks()[0].getSettings();
        console.log(capabilities)
        // @ts-ignore
        const zoom = settingsVideo.zoom || 0;
        // @ts-ignore
        const maxZoom = capabilities.zoom?.max || 0;
        // @ts-ignore
        const minZoom = capabilities.zoom?.min || 0;
        // get settings
        console.log(zoom)
        // calculate zoom:
        // @ts-ignore
        let newZoom = zoom + (capabilities?.zoom?.step * 4000)
        if (mode === 'out') {

            // @ts-ignore
            newZoom = zoom - (capabilities?.zoom?.step * 4000)

        }
        console.log(newZoom)
        // move stream:
        try {

            stream.getVideoTracks()[0].applyConstraints({
                advanced: [
                    {
                        // @ts-ignore
                        zoom: newZoom > maxZoom ? maxZoom : newZoom < minZoom ? minZoom : newZoom,
                    }
                ]
            })

        }
        catch (e) {

            console.log(e)

        }

    }
    console.log('render VIDEO')

    return (
        <>
            <button onMouseDown={() => {

                console.log(2)
                // zoom ptz camera stream
                setZoom('in')
                ptzZoomInInterval = setInterval(() => {

                    setZoom('in')

                }, 100)

            }}
                onMouseUp={() => {

                    clearInterval(ptzZoomInInterval)

                }}
            ><i className="las la-plus-circle"></i></button>
            <button
                onMouseDown={() => {

                    // zoom ptz camera stream
                    setZoom('out')
                    ptzZoomInInterval = setInterval(() => {

                        setZoom('out')

                    }, 100)

                }}
                onMouseUp={() => {

                    clearInterval(ptzZoomInInterval)

                }}
            ><i className="las la-minus-circle"></i></button>

        </ >
    );

};

export default ZoomDishComponent;