import './App.scss';
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { persistor, store } from './store'
import Screens from './screens';

function Application() {

  return (
    <div className="App Debug">

      <Provider store={store}>
        <PersistGate persistor={persistor} loading={<>cargando</>} >
          <Screens />
        </PersistGate>
      </Provider>
    </div>
  );

}

export default Application;
