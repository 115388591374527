import { useState, useRef, useEffect } from 'react';
import Video from './components/video/dish.video.component';
import { ConnectDeviceOptions, DishControlsInterface, DishLogType, WebRTCUser } from './dish.types';
import './dish.styles.scss'
import './layout.styles.scss'

import { SocketInstance, SocketParticipants } from './functions/socket.instance';
import { useParams, useSearchParams } from 'react-router-dom';
import { DeviceFilterType, DeviceInstance } from './functions/devices.instance';
import SectionComponent from './components/sections/sections.component';
import DishResize from './functions/dish.layout';
import LoaderComponent from '../loader/loader.component';
import { clear } from 'console';
import { ChatMessageType } from './components/sections/chat/chat.component';
import { connect } from 'react-redux';

type DishParticipants = { [id: string]: { id: string, name?: string, streams: MediaStream[] } }
const App = (props: {
  store: any,
  controls?: DishControlsInterface
}) => {

  // 50 random names:
  // get params query navigator
  const [searchParams,] = useSearchParams();
  const [audioOutput, setAudioOutput] = useState<string | undefined>(undefined)
  const [videoBigSelected, setVideoBigSelected] = useState<string | undefined>(undefined);
  // socket Status:
  const [socketStatus, setSocketStatus] = useState('disconnected');
  const [logs, setLogs] = useState<DishLogType[]>([]);
  const [localScreams, setLocalScreams] = useState<MediaStream[]>([]);
  const [messages, setMessages] = useState<ChatMessageType[]>([]);
  let defaultVideo = false
  const [remoteParticipants, setRemoteParticipants] = useState<DishParticipants>({});
  const [layout, setLayout] = useState<string>('gallery');
  const [videoSelectedBig, setVideoSelectedBig] = useState<string | undefined>(undefined);
  // socket
  const socketRef = useRef<SocketInstance>();
  const deviceRef = useRef<DeviceInstance>();
  const userEmail = props.store.user.user.email;
  const userName = `${props.store.user.user.name} ${props.store.user.user.surnames}`;
  const userId = props.store.user.user.id;
  const paramsUlr = useParams()

  const roomId = paramsUlr.roomId;
  // RTC
  // Usuarios
  const [users, setUsers] = useState<WebRTCUser[]>([]);

  useEffect(() => {

    // permissions
    new DeviceInstance().getPermissions()
    // reset values
    setLogs([])
    setSocketStatus('disconnected')
    setLocalScreams([])
    setMessages([])
    setRemoteParticipants({})
    setUsers([])
    socketRef.current = undefined
    deviceRef.current = undefined

    // Validate if UserId and RoomId are defined
    if (userId && roomId) {

      // Create Instance of Devices
      const devices = new DeviceInstance();
      deviceRef.current = devices;

      // Create Instance of Socket
      const instance = new SocketInstance({
        debug: true,
        userId: userId,
        roomId: roomId,
        userName: userName,
        onLog: (log: DishLogType) => setLogs((logs) => [...logs, log]),
        onConnect: async () => setSocketStatus('connected'),
        onDisconnect: () => setSocketStatus('disconnected'),
        onErrorConnecting: () => setSocketStatus('error'),
        onNewLocalStream: (stream) => setLocalScreams((prev) => [...prev, stream]),
        onRemoveLocalStream: (streamIndex: number) => {

          setLocalScreams((prev) => {

            prev.splice(streamIndex, 1)
            console.log('nuevos streams:', prev)
            return [...prev]

          })

        },
        onMessage: (message: ChatMessageType) => setMessages((prev) => [...prev, message]),
        onUpdateParticipants: (newParticipants: SocketParticipants): void => {

          const cloneParticipants: DishParticipants = {};
          newParticipants && Object.keys(newParticipants) && Object.keys(newParticipants)?.map((id) => {

            cloneParticipants[id] = {
              id: id,
              streams: newParticipants[id]?.streams
            };

          });
          setRemoteParticipants(cloneParticipants);

        }

      })

      socketRef.current = instance;

    }
    return () => {

      socketRef.current?.disconnect();

    }
    // save instance for status

  }, []);

  useEffect(() => {

    // If Socket is Connected check if Default Video is enabled
    if (socketStatus === 'connected' && defaultVideo === false) {

      const defaultLoad = true;
      if (defaultLoad) {

        // Add default video to socket
        deviceRef.current?.getDefaultDevice().then((stream) => {

          stream && socketRef.current?.addLocalStream(stream);
          defaultVideo = true;

        })

      }

    }

  }, [socketStatus]);

  const dishResize = () => {

    new DishResize('DishScenary', 'DishScenary', 'DishVideoComponent').resize();

  }
  // event resize window execute DishResize()
  useEffect(() => {

    const interval = setInterval(() => dishResize(), 1000);
    window.addEventListener('resize', dishResize);

    return () => {

      clearInterval(interval);
      window.removeEventListener('resize', dishResize);

    }

  }, [])
  return (
    <div className="DishComponent">
      <div className="body">
        <SectionComponent
          setAudioOutputDevice={(deviceId) => setAudioOutput(deviceId)}
          audioOutputDevice={audioOutput}
          messages={messages}
          setLayout={setLayout}
          socketStatus={socketStatus}
          logs={logs}
          localScreams={localScreams}
          getAudioOutputDevices={async () => await deviceRef.current?.getAudioOutput()}
          getDevices={async (filter?: DeviceFilterType) => await deviceRef.current?.getDevices(filter)}
          connectDevice={async (devices: { videoId: string } | { audioId: string } | { videoId: string, audioId: string }, options: ConnectDeviceOptions) => {

            console.log('options', options)
            const stream = await deviceRef.current?.getDeviceMedia(devices, {
              ...options
            });
            stream && socketRef.current?.addLocalStream(stream);
            dishResize()
            return stream;

          }}
          sendMessage={(message: string) => socketRef?.current?.sendMessage(message)}
          getStreenMedia={async () => {

            const screenMedia = await deviceRef.current?.getScreenMedias();
            screenMedia && socketRef.current?.addLocalStream(screenMedia);
            dishResize()

          }}
          finishCall={() => {

            // redirect to home
            window.location.href = '/manager/calendar';

          }}
        />
        <div id='DishScenary' className={`ScenaryComponent  layout-${layout}`}>
          <div className='canvas'>
            <div className={`cameras`}>
              {
                socketStatus !== 'connected' && <LoaderComponent status={true} invert />
              }
              {
                localScreams && localScreams.map((stream, index) => (
                  <Video
                    type='local'
                    setVideoBigSelected={() => {

                      setVideoBigSelected('local-' + index)

                    }}
                    controls={props.controls}
                    muted={true}
                    videoBigSelected={videoBigSelected === 'local-' + index}
                    key={stream.id}
                    name={userName}
                    stream={stream}
                    removeStream={() => socketRef.current?.removeLocalStream(index)}
                  />
                ))
              }
              {
                remoteParticipants && Object.keys(remoteParticipants).length > 0 && Object.keys(remoteParticipants).map((key, index) => (
                  remoteParticipants[key]?.streams?.map((stream, index) => (
                    <Video
                      controls={props.controls}
                      getAudioOutputDevices={async () => await deviceRef.current?.getAudioOutput()}
                      videoBigSelected={videoBigSelected === 'user-' + key}
                      setVideoBigSelected={() => {

                        setVideoBigSelected('user-' + index)

                      }}
                      name={remoteParticipants[key].name}
                      type='remote' removeStream={() => undefined} key={index} stream={stream} />
                  ))

                ))
              }
            </div>
          </div>
        </div>
      </div>

    </div >
  );

};

const mapStateToProps = (state: any, ownProps: any) => (state)

export default connect(mapStateToProps,)(App)

