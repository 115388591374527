import { ConstructorInterfaceAction, ConstructorInterfaceMethod, ConstructorInterfaceMethodValidation } from '../../interface.type'
import './action.styles.scss'
import EncryptActionInterface from './components/encrypt/encrypt.action'
import InsertActionInterface from './components/insert/insert.action'
import DecodeJwtActionInterface from './components/jwt/decode/encode.jwt.action'
import EncodeJwtActionInterface from './components/jwt/encode/encode.jwt.action'
import SelectActionInterface from './components/select/select.action'
export default function ActionInterface({ action }: {
    action: ConstructorInterfaceAction
}) {

    return <><div className='MethodInterface'>
        {
            action.type === 'encrypt' && <EncryptActionInterface action={action} />
        }
        {
            action.type === 'select' && <SelectActionInterface action={action} />
        }
        {
            action.type === 'insert' && <InsertActionInterface action={action} />
        }
        {
            action.type === 'jwt.encode' && <EncodeJwtActionInterface action={action} />
        }
        {
            action.type === 'jwt.decode' && <DecodeJwtActionInterface action={action} />
        }
    </div>
        {
            action?.action && <ActionInterface action={action.action} />
        }
    </>

}