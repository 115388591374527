
import './devices.component.scss';

interface ConfigurationSettings {
  setSection: () => void;
  setLayout: (layout: string) => void

}

export default function LayoutsDishComponent({ setSection, setLayout, }: ConfigurationSettings) {

  return <div className="DishSectionsLayoutsComponent bar">

    <div className='title'>
      Cambia el diseño  <b>a la  llamada</b>
      <div className='closer' onClick={() => {

        setSection()

      }}>
        <i className="las la-times"></i>
      </div>
    </div>
    <div className='layout'>
      <div className='systems'>
        <div className='infor'>
          Selecciona un tipo de canal para añadirlo a la llamada, puedes añadir hasta un máximo de <b>3 canales</b>.
        </div>
        <div className='system' onClick={() => {

          setLayout('gallery')

        }}>
          <i className='las la-th'></i>
          <span>GALERÍA</span>
        </div>
        <div className='system' onClick={() => {

          setLayout('focus')

        }}>
          <i className='las la-th-large'></i>
          <span>FOCUS</span>
        </div>
        <div className='system' onClick={() => {

          setLayout('class')

        }}>
          <i className="las la-columns"></i>
          <span>CLASS</span>
        </div>
        <div className='system' onClick={() => {

          // toggle fullscreen web
          if (!document.fullscreenElement) {

            // abre la aplicación
            document.documentElement.requestFullscreen();

          } else {

            if (document.exitFullscreen) {

              document.exitFullscreen();

            }

          }

        }}>
          <i className='las la-expand'></i>
          <span>FULL</span>
        </div>

      </div>

    </div>
  </div >

}