import { getProperty } from "dot-prop";
export type ConditionalType =
    {
        operator: 'null' | 'undefined' | 'exist' | '===' | '!==' | '==' | '!=' | '>' | '<' | '>=' | '<=' | 'in' | 'not in' | 'contains' | 'not contains' | 'starts with' | 'ends with',
        id: string,
        value: string | number | boolean
    }
export default function Conditional(conditionals: ConditionalType[], item: any): boolean {

    let render = true;
    conditionals.forEach((condition) => {

        const value: string | boolean | number | undefined = getProperty(item, condition.id);
        if (condition.operator === '===') {

            render = render && value === condition.value

        } else if (condition.operator === 'exist') {

            render = render && value !== undefined

        } else if (condition.operator === 'undefined') {

            render = render && value === undefined

        } else if (condition.operator === 'null') {

            render = render && value === null

        } else if (condition.operator === '!==') {

            render = render && value !== condition.value

        } else if (condition.operator === '==') {

            // eslint-disable-next-line eqeqeq
            render = render && value == condition.value

        } else if (condition.operator === '!=') {

            // eslint-disable-next-line eqeqeq
            render = render && value != condition.value

        } else if (condition.operator === '>') {

            const valueValidation = value || 0;
            render = render && condition.value > (valueValidation)

        } else if (condition.operator === '<' && value) {

            const valueValidation = value || 0;
            render = render && condition.value < (valueValidation)

        } else if (condition.operator === '>=') {

            const valueValidation = value || 0;
            render = render && condition.value >= (valueValidation)

        } else if (condition.operator === '<=') {

            const valueValidation = value || 0;
            render = render && condition.value <= (valueValidation)

        } else if (condition.operator === 'in') {

            if (value === undefined) render = false;
            else if (typeof value !== 'string') render = false;
            else if (typeof condition.value === 'string') render = render && condition.value.includes(value)
            else render = false;

        } else if (condition.operator === 'not in') {

            if (value === undefined) render = false;
            else if (typeof value !== 'string') render = false;
            else if (typeof condition.value === 'string') render = render && !condition.value.includes(value)
            else render = false;

        } else if (condition.operator === 'starts with') {

            if (value === undefined) render = false;
            else if (typeof value !== 'string') render = false;
            else if (typeof condition.value === 'string') render = render && condition.value.startsWith(value)
            else render = false;

        } else if (condition.operator === 'ends with') {

            if (value === undefined) render = false;
            else if (typeof value !== 'string') render = false;
            else if (typeof condition.value === 'string') render = render && condition.value.endsWith(value)
            else render = false;

        }

    })
    return render;

}