import { useEffect, useState } from 'react';
import { ConnectDeviceOptions, DishPermissions } from '../../../../dish.types';
import DetectRTC from '../../../../functions/computer.detect';
import { ReactInternetSpeedMeter } from '../../internet';
import './devices.component.scss';
import DishDevicesVideoSelector from './video.selector';
import DishDevicesAudioSelector from './audio.selector';
import DishDevicesVideoAudioSelector from './video.audio.selector';

interface ConfigurationSettings {
  setSection: () => void;
  getStreenMedia: () => void
  getAudioOutputDevices: () => Promise<MediaDeviceInfo[] | undefined>
  setAudioOutputDevice: (deviceId: string) => void
  audioOutputDevice?: string,
  localScreams?: MediaStream[];
  getDevices: () => Promise<MediaDeviceInfo[] | undefined>
  connectDevice: (devices: { videoId: string } | { audioId: string } | { videoId: string, audioId: string }, options: ConnectDeviceOptions) => Promise<MediaStream | undefined>
}

export default function DishSectionDevicesComponent({ audioOutputDevice, setAudioOutputDevice, getAudioOutputDevices, setSection, getStreenMedia, localScreams, getDevices, connectDevice }: ConfigurationSettings) {

  const [type, setType] = useState<string | undefined>(undefined)
  const [audioOutputStatus, setAudioOutputStatus] = useState<boolean>(false)
  const [audioOutputDevices, setAudioOutputDevices] = useState<MediaDeviceInfo[]>([])
  return <div className="DishSectionsDevicesComponent bar">

    <div className='title'>
      Añade otro dispositivo <b>a la  llamada</b>
      <div className='closer' onClick={() => {

        setSection()

      }}>
        <i className="las la-times"></i>
      </div>
    </div>
    <div className='layout'>
      {!type && <div className='systems'>
        <div className='infor'>
          <i className='las la-info-circle'></i>
          Selecciona un tipo de canal para añadirlo a la llamada, puedes añadir hasta un máximo de <b>3 canales</b>.
        </div>
        <div className='system' onClick={() => {

          setType('video_and_audio')

        }}>
          <div className='multiple'>
            <i className='las la-video'></i>
            <i className='las la-microphone'></i>
          </div>

          <span>VIDEO Y AUDIO</span>
        </div>
        <div className='system' onClick={() => {

          setType('video')

        }}>
          <i className='las la-video'></i>
          <span>VIDEO</span>
        </div>
        <div className='system' onClick={() => {

          setType('audio')

        }}>
          <i className='las la-microphone'></i>
          <span>AUDIO</span>
        </div>
        <div className='system' onClick={getStreenMedia}>
          <i className='las la-desktop'></i>
          <span>MEDIA</span>
        </div>
        {
          // @ts-ignore
          window.electron && <>
            <div className='infor' style={{ marginTop: 20 }}>
              <i className='las la-info-circle'></i>
              Seleccione una aplicación y luego comparta su pantalla. x
            </div>
            <div className='system' onClick={(e) => {

              // plataform electron
              // @ts-ignore
              if (window.electron) {

                const appName = 'V-RAY'
                // open native app
                // @ts-ignore
                // shell.openPath(path)
                // @ts-ignore
                // path of calculator in windows 10
                // shell.openPath('C:\\Windows\\System32\\calc.exe')

                // @ts-ignore
                //const result = window.electron.abrirPrograma()

                // @ts-ignore
                window.electron.captureDesktop()

              }

            }}>
              <i className='las la-x-ray'></i>
              <span>X-RAY</span>
            </div></>}
        {/* <div className='infor' style={{ marginTop: 20 }}>
          <i className='las la-volume-up'></i>
          Puedes seleccionar el canal de salida de audio de tu dispositivo, para que puedas escuchar la llamada en el dispositivo que desees.
        </div>
        <div className='selector'>
          <div className='placeholder' onClick={async () => {

            setAudioOutputStatus(!audioOutputStatus)
            const outputDevices = await getAudioOutputDevices()
            setAudioOutputDevices(outputDevices || [])

          }}>
            <i className='las la-compact-disc'></i>
            Cambiar canal de salida de audio
            <i className='las la-angle-down'></i>
          </div>
          {
            audioOutputStatus && <div className='chooses'>
              {
                audioOutputDevices.map((device, index) => {

                  return <div className='choose' key={index} onClick={() => {

                    setAudioOutputDevice(device.deviceId)

                  }}>
                    {device.label}
                  </div>

                })
              }
            </div>
          }
        </div> */}
      </div>}
      {
        type && <>
          <div className='back' onClick={() => {

            setType(undefined)

          }}>
            <i className='las la-arrow-left' ></i> Volver
          </div>
          {type === 'video' && <DishDevicesVideoSelector localScreams={localScreams} getDevices={getDevices} connectDevice={connectDevice} />}
          {type === 'audio' && <DishDevicesAudioSelector localScreams={localScreams} getDevices={getDevices} connectDevice={connectDevice} />}
          {type === 'video_and_audio' && <DishDevicesVideoAudioSelector localScreams={localScreams} getDevices={getDevices} connectDevice={connectDevice} />}
        </>
      }

    </div>
  </div >

}