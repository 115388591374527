// General
import Help from './comitas/help.json';
import Menu from './comitas/menu.json';

// Dish System
import Patients from './comitas/dish/patients.json';
import Meets from './comitas/dish/meets.json';
import Dish from './comitas/dish/dish.json';
import Calendar from './comitas/dish/calendar.json';
import Invitations from './comitas/dish/invitations.json';

// Admin
import Groups from './comitas/admin/groups.json';
import Relations from './comitas/admin/relations.json';
import Users from './comitas/admin/users.json';
import Anlytics from './comitas/admin/analytics.json';

// Account
import Account from './comitas/account/account.json';

// Access
import Login from './comitas/access/login.json';
import Register from './comitas/access/register.json';
import RegisterSuccess from './comitas/access/registerSuccess.json';

export default function ComitasConstructor() {

    return {
        "type": "frontend",
        "name": "professional",
        "path": "login",
        "styles": { "flex": 1 },
        "screens": [
            {
                "type": "screen",
                title: "Comitas",
                favicon: "/assets/comitas/images/logotypes/favicon.png",
                "components": [

                    {

                        type: 'screen',
                        components: [
                            {
                                type: 'retrieve',
                                endpoint: '/api/user/session',
                                method: 'get',
                                actions: {
                                    failed: [
                                        {
                                            type: 'redirect',
                                            path: '/',
                                        },
                                    ],
                                },
                                components: [
                                    Dish,
                                    {
                                        "type": "screen",
                                        "name": "calendar",
                                        styles: {
                                            zIndex: 10,
                                            display: 'flex',
                                            flex: 1,
                                            position: 'fixed',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0, backgroundColor: '#eee',
                                            flexDirection: 'row',
                                        },
                                        "components": [

                                            Menu,

                                            {
                                                "type": "screen",
                                                styles: {
                                                    display: 'flex',
                                                    flex: 1,
                                                    overflowY: 'scroll',
                                                    padding: 20
                                                },
                                                "components": [

                                                    {
                                                        type: 'screen',
                                                        styles: {
                                                            display: 'flex',
                                                            flex: 1,
                                                            flexDirection: 'column',

                                                        },
                                                        components: [
                                                            Calendar,
                                                            Meets,
                                                            Patients,
                                                            Anlytics,
                                                            Help,
                                                            Groups,
                                                            Account,
                                                            Users,
                                                            Invitations,
                                                            Relations,
                                                        ]
                                                    }]
                                            }
                                        ]
                                    }],
                                false: [
                                    Login,
                                    Register,
                                    RegisterSuccess,
                                    {
                                        "path": [
                                            "/manager/*",
                                            "/admin/*"
                                        ],
                                        type: "screen",
                                        components: [
                                            {

                                                "type": "redirect",
                                                "path": "/login",
                                            },
                                        ]
                                    },

                                ]
                            }
                        ]
                    }]
            }
        ]
    }

}