import axios from 'axios';
import { environment } from './../../../environments/environment'

export default function RequestAction(props: any) {

    console.log(`Request: ${environment.api.host}${props.endpoint}`)
    return axios({
        method: props.method,
        url: `${environment.api.host}${props.endpoint}`,
        data: props.data,
        headers: props.headers
    });

}
