
// Function login props redux connect
import { useEffect, useState } from 'react';
import './index.scss';

interface DispatchProps {
    name: string,
    type: 'number'
    placeholder?: string,
    icon?: string,
    setData: (s: any) => void
    defaultValue?: string,
    onSubmit: () => void
    decimals?: number
}

export default function LayoutFormNumberComponent(props: DispatchProps) {

    const [value, setValue] = useState<string>('');
    useEffect(
        () => {

            if (props.defaultValue) {

                if (props.decimals) {

                    // Add 2 decimals to defaultValue
                    if (props.defaultValue) {

                        setValue(parseFloat(props.defaultValue).toFixed(props.decimals))

                    }
                    else {

                        setValue(props.defaultValue)

                    }

                }
                else setValue(props.defaultValue)

            }
            else {

                if (props.decimals) {

                    setValue('0.00')

                }
                else setValue('0')

            }

        }, [])

    return (
        <div className='LayoutFormInputComponent'>
            <div className={props.icon ? 'have-icon' : 'no-icon'}>
                {props.icon && <i className={`icon ${props.icon}`} />}
                <input
                    min="1"
                    step="1"
                    autoComplete={'new-password'}
                    type={'number'}
                    placeholder={props.placeholder}
                    onChange={(event) => {

                        if (props.decimals && props.decimals > 0) {

                            const value = parseFloat(event.target.value)
                            props.setData(value)

                        }
                        else {

                            const value = parseInt(event.target.value)
                            props.setData(value)

                        }

                    }}
                    onKeyDown={(event) => {

                        // No decimals and float
                        if (props.decimals && props.decimals > 0) {

                        }
                        else if ((event.key === '.' || event.key === ',')) {

                            event.preventDefault();

                        }

                    }}
                    onKeyUp={(event) => {

                        if (event.key === 'Enter') {

                            props.onSubmit()

                        }

                    }}
                    defaultValue={value}
                />
            </div>
        </div>

    );

}

