import { useEffect, useState } from 'react';
import './timeline.styles.scss'
export default function CalendarWeekTimeline() {

    const [date,] = useState(new Date());
    const currentTop = date.getHours() * 60 + date.getMinutes();
    const currentMaxTop = 24 * 60;
    const pxPerMinute = 100 / 60;
    const linePosition = currentTop * pxPerMinute + date.getHours();
    useEffect(() => {

        // Get element with className CalendarLine
        const element = document.getElementsByClassName('CalendarLine')[0];
        if (element) {

            // Window scroll smoth
            setTimeout(() => {

                element.scrollIntoView?.({ block: "center", behavior: "smooth", });

            }, 200);

        }

    }, [])
    return <><div className="CalendarDayTimeline">

        {
            [...Array(24)].map((_, indexHour) => (
                <div className="hour" key={indexHour} >
                    <div className='box'>{
                        indexHour > 0 && indexHour < 24 && <>
                            <div className='number'>
                                {
                                    indexHour
                                }  </div>
                            <div className='sector'>
                                {
                                    // Pm am
                                    indexHour > 12 ? 'pm' : 'am'
                                }
                            </div>
                        </>
                    }</div>
                </div>
            ))

        }    </div >

    </ >

}