
import { environment } from '../environments/environment';
import ComitasConstructor from './comitas.constructor';
import BecallingConstructor from './becalling.constructor';
import InkonskyConstructor from './inkonsky.constructor';
import WiflyConstructor from './wifly.constructor';
import BrickterConstructor from './brickter.constructor';
export default function SchemeConstructor() {

    if (environment.brickter.name === 'wifly') {

        return WiflyConstructor()

    }
    if (environment.brickter.name === 'comitas') {

        return ComitasConstructor()

    }
    if (environment.brickter.name === 'becalling') {

        return BecallingConstructor()

    }
    if (environment.brickter.name === 'inkonsky') {

        return InkonskyConstructor()

    }
    if (environment.brickter.name === 'brickter') {

        return BrickterConstructor()

    }
    return null

}