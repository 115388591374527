/* eslint-disable camelcase */
import { getProperty } from "dot-prop";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import ExecuteActions from "../../../actions";
import RequestAction from "../../../actions/request/request.action";
import { CalendarHeaderComponent } from "./components/header/calendar.header.component";
import "./index.scss";
import LayoutCalendarMonthView from "./components/month/monthView";
import LayoutCalendarYearView from "./components/year/year.component";
import { Replace } from "../../../utilities/replace/replace.utility";
import Moment from 'moment';
import CalendarWeek from "./components/week/week.component";
import CalendarDay from "./components/day/week.component";

export interface CalendarSettings {
    data?: any[],
    store: any,
    header: boolean,
    endpoint: string,
    method: string
    onNew: any,
    onNewColumn?: any,
    onDelete: any,
    onEdit: any,
    startedDateSelector: string,
    finishedDateSelector: string,
    event: {
        idSelector: string,
        subjectSelector: string,
        bubbleSelector: string,
        descriptionSelector: string,
        startedDateSelector: string,
        finishedDateSelector: string,
        colorSelector?: string,
    }
    day?: {
        columns?: {
            colorSelector: string,
            descriptionSelector: string,
            idSelector: string,
            titleSelector: string
            imageSelector: string
            path: string
        }
    }
}
function LayoutCalendarComponent(props: CalendarSettings) {

    const navigation = useNavigate()
    const [widthCalendar, setWidthCalendar] = useState<number>()
    const layoutRef = useRef<HTMLDivElement>(null)
    const [mode, setMode] = useState("week");
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dateSelected, setDate] = useState<Date>();
    const [cache, setCache] = useState<string>('');
    const [searchParams] = useSearchParams();

    useEffect(() => {

        // if mode is week
        if (mode === "week") {

            const today = new Date();
            // get first day of week
            const firstDay = Moment(new Date()).startOf('week').toDate()
            setDate(firstDay)

        }

    }, [])
    const getData = (page: number) => {

        if (!dateSelected) return false;
        setItems([]);

        setLoading(true)

        const currentUserToken = getProperty(props.store, 'user.access_token');
        // Replace to props.endpoint with {id}
        let endpoint = props.endpoint

        console.log('calendar store:', props.store)
        endpoint = Replace({
            store: props.store
        }, endpoint);
        // Add ? if dont have
        endpoint = endpoint.indexOf('?') === -1 ? endpoint + '?' : endpoint;
        // Datetime yyyy-mm-dd utc
        // Get date of 00:00 today
        dateSelected.setHours(0, 0, 0, 0);

        let datetimeStart = Moment(dateSelected).utc().toISOString()

        // Mode:
        let datetimeEnd = Moment(datetimeStart).add(1, 'days');
        if (mode === 'week') {

            // get first day of week of dateSelected
            datetimeStart = Moment(dateSelected).startOf('week').utc().toISOString()

            datetimeEnd = Moment(datetimeStart).add(7, 'days');

        }
        if (mode === 'month') {

            const year = dateSelected.getFullYear();
            const month = dateSelected.getMonth();
            const firstDay = new Date(year, month, 1);
            const lastDay = new Date(year, month + 1, 0);
            datetimeEnd = Moment(lastDay).add(1, 'days');

            datetimeStart = Moment(firstDay).utc().toISOString()

        }

        let datetimeEndString = Moment(datetimeEnd).utc().toISOString()

        let path = `${endpoint}&page=${page}&start=${datetimeStart}&end=${datetimeEndString}`;

        RequestAction({
            endpoint: path,
            method: 'get',
            headers: {
                'authorization': 'Bearer ' + currentUserToken
            }
        }).then((response) => {

            setTimeout(() => {

                setLoading(false)
                setItems(response.data.items)

            }, 300);

        }).catch((error) => {

            setLoading(false)

        })

    }

    useEffect(() => {

        getData(0);

    }, [props.store, mode, dateSelected])

    const onNew = (newDate: Date, finishDate?: Date, column?: any) => {

        // Date with 1 hour more
        const newDateWithHour = new Date(newDate.getTime() + 3600000)

        let finishDateSend = newDateWithHour
        if (finishDate) {

            finishDateSend = finishDate

        }
        let actions = props.onNew;
        if (column) {

            actions = props.onNewColumn

        }
        console.log('colum:', column)
        ExecuteActions({
            item: {
                start: Moment(newDate).toISOString(),
                end: Moment(finishDateSend).toISOString(),
                column: column
            },
            commands: {
                navigation: navigation
            }, actions: actions
        })

    }
    const onEdit = (item: any) => {

        ExecuteActions({
            item: item,
            commands: {
                navigation: navigation
            }, actions: props.onEdit
        })

    }

    const onDelete = (item: any) => {

        ExecuteActions({
            item: item,
            commands: {
                navigation: navigation
            }, actions: props.onDelete
        })

    }

    useEffect(() => {

        const queryCache = searchParams.get('cache');
        if (queryCache && queryCache !== cache) {

            setCache(queryCache);
            getData(0)

        }

    }, [searchParams])
    useEffect(() => {

        if (layoutRef.current && layoutRef.current.offsetWidth) {

            const custom = (250 * 7) + 60 + 60;
            if (layoutRef.current.offsetWidth > custom) setWidthCalendar(custom)
            else setWidthCalendar(layoutRef.current.offsetWidth)

        }
        // resize window
        window.addEventListener('resize', () => {

            if (layoutRef.current && layoutRef.current.offsetWidth) {

                const custom = (250 * 7) + 60 + 60;
                if (layoutRef.current.offsetWidth > custom) setWidthCalendar(custom)
                else setWidthCalendar(layoutRef.current.offsetWidth)

            }

        }
        )
        return () => {

            window.removeEventListener('resize', () => {

                if (layoutRef.current && layoutRef.current.offsetWidth) {

                    const custom = (250 * 7) + 60 + 60;
                    if (layoutRef.current.offsetWidth > custom) setWidthCalendar(custom)
                    else setWidthCalendar(layoutRef.current.offsetWidth)

                }

            })

        }

    }, [layoutRef.current])

    // change mode:
    const changeMode = (mode: string, date: Date) => {

        // change date to today
        if (mode === 'week') {

            // get first day week of today:
            const firstDay = Moment(date).startOf('week').toDate()
            setDate(firstDay)

        }
        if (mode === 'day') {

            // get first day week of today:
            setDate(date)

        }
        if (mode === 'month') {

            // get first day week of today:
            setDate(date)

        }

        if (mode === 'year') {

            // get first day week of today:
            setDate(date)

        }
        setMode(mode)

    }
    if (!dateSelected) return null;
    return (
        <div className="LayoutCalendarComponent" ref={layoutRef}>
            {
                widthCalendar && <div className="centerSystem" style={{ width: widthCalendar, maxWidth: widthCalendar }}>

                    <CalendarHeaderComponent loading={loading}
                        modeDayAvailable={props.day ? true : false}

                        count={items.length} setMode={(mode: string) => {

                            let dateToStartMode = dateSelected;
                            if (mode === 'day') dateToStartMode = new Date();
                            changeMode(mode, dateToStartMode)

                        }} mode={mode} date={dateSelected} setDate={(date) => {

                            changeMode(mode, date)

                        }} />

                    {mode === "year" && <LayoutCalendarYearView date={dateSelected} onNew={
                        (dateSelected: Date) => {

                            onNew(dateSelected)

                        }
                    } />}
                    {mode === "month" && <LayoutCalendarMonthView
                        setDateSelected={(date: Date) => {

                            changeMode('week', date)

                        }}
                        settings={props} events={items} widthCalendar={widthCalendar} date={dateSelected} items={items} onNew={
                            (start: Date, end: Date) => {

                                onNew(start, end)

                            }
                        }
                        onEdit={
                            (itemSelected: any) => {

                                onEdit(itemSelected)

                            }
                        }

                    />}
                    {
                        mode === 'week' && <CalendarWeek
                            onEdit={onEdit}
                            onDelete={onDelete}
                            events={items}
                            daySelected={dateSelected}
                            settings={props}
                            widthCalendar={widthCalendar}
                            onNew={
                                (start: Date, end: Date) => {

                                    onNew(start, end)

                                }
                            }
                        />
                    }
                    {
                        mode === 'day' && <CalendarDay
                            onNew={
                                (start: Date, end: Date, column: any) => {

                                    onNew(start, end, column)

                                }
                            }
                            onEdit={onEdit}
                            onDelete={onDelete}
                            daySelected={dateSelected} widthCalendar={widthCalendar} events={items} settings={props} />
                    }
                </div>
            }
        </div >
    );

}

const mapStateToProps = (state: any, ownProps: any) => (state)

export default connect(mapStateToProps,)(LayoutCalendarComponent)
