import { ConstructorManagerComponentTypes } from '../../manager.type'
import './screen.component.screen.styles.scss'
export default function TableModel({ component, }: {
    component: ConstructorManagerComponentTypes,
}) {

    const direction = 'direction' in component && typeof component.direction === 'string' ? component.direction : component.type === 'screen' ? 'row' : 'column'
    return <div className='ComponentManager'>
        <div className='type'>{
            component.type
        }
            <i className='las la-cog' />
        </div>
        {component.documentation?.title && <div className='name'>{
            component.documentation?.title
        }</div>}
        {'components' in component &&
            <div className='components' style={{ flexDirection: direction }}>
                {
                    component?.components?.map((child: ConstructorManagerComponentTypes, index: number) => {

                        return <TableModel component={child} key={index} />

                    })
                }
            </div>
        }
    </div>

}