import './joystick.dish.styles.scss';
import Joystick from './joystick';

interface Props {
    stream: MediaStream;
}

const JoystickDishComponent = ({ stream }: Props) => {

    return <><Joystick
        color='rgba(255, 255, 255, 0)'
        knobColor='linear-gradient(to top, #111, #555)'
        borderColor='#000'
        borderWidth={0}
        knobWidth={20}
        noBorder={true}
        width={50}
        height={50}
        knobBorderWidth={1.5}
        onActivity={(v: any) => {

            // execute action with 1 s of interval

            // get capabilities
            const capabilities = stream.getVideoTracks()[0].getCapabilities();
            // get pan current

            const settingsVideo = stream.getVideoTracks()[0].getSettings();
            // @ts-ignore
            const pan = settingsVideo.pan || 0;
            // @ts-ignore
            const tilt = settingsVideo.tilt || 0;

            // @ts-ignore 
            const maxTilt = capabilities.tilt?.max || 0;
            // @ts-ignore
            const minTilt = capabilities.tilt?.min || 0;

            // @ts-ignore
            const maxPan = capabilities.pan?.max || 0;
            // @ts-ignore
            const minPan = capabilities.pan?.min || 0;

            // calculate pan:
            // @ts-ignore
            const newPan = pan + (capabilities?.pan?.step * 100 * (v.intensity.x))
            // @ts-ignore
            const newTilt = tilt + (capabilities?.tilt?.step * 100 * v.intensity.y)
            // move stream:
            try {

                stream.getVideoTracks()[0].applyConstraints({
                    advanced: [
                        {
                            // @ts-ignore
                            pan: newPan > maxPan ? maxPan : newPan < minPan ? minPan : newPan,
                            // @ts-ignore
                            tilt: newTilt > maxTilt ? maxTilt : newTilt < minTilt ? minTilt : newTilt,
                        }
                    ]
                })

            }
            catch (e) {

                console.log('error')

            }

        }}
    />
        <button onClick={() => {

            // reset pan and tilt and zoom
            try {

                stream.getVideoTracks()[0].applyConstraints({
                    advanced: [
                        {
                            // @ts-ignore
                            pan: 0,
                            // @ts-ignore
                            tilt: 0,
                            // @ts-ignore
                            zoom: 0,
                        }
                    ]
                })

            }
            catch (e) {

                console.log('error')

            }

        }}><i className="las la-crosshairs"></i></button>
    </>

};

export default JoystickDishComponent;