import './contact.styles.scss'
interface ContactProperties {
    name: string,
    call: () => void
}

export default function ContactComponent(properties: ContactProperties) {

    return <div className="ContactComponent">
        <div className='title'>
            <div className='circle'></div> {properties.name} <i className="las la-phone" onClick={properties.call} />
        </div>

    </div>

}